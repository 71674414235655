import React from 'react';
import styles from './generalModal.module.css';

function GeneralModal({
  children, visible, toggle = function () {}, modalStyle,
}) {
  if (visible) {
    return (
      <div className={styles.modal} onClick={() => { toggle(false); }}>
        <div
          className={`${styles.modalContent} ${modalStyle}`}
          onClick={(event) => { event.stopPropagation(); }}
        >
          {children}
        </div>
      </div>
    );
  }
  return (<div />);
}

export { GeneralModal };
