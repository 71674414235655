import React, { useEffect, useState } from 'react';
import styles from './uploadOptions.module.css';
import { AlternateButton, PrimaryButton } from '../../../views/buttons/buttons';
import icUpload from '../../../../assets/images/upload/solutions.svg';
import icArrow1 from '../../../../assets/images/collapsibleArrow1.svg';
import icArrow2 from '../../../../assets/images/collapsibleArrow2.svg';
import icClose from '../../../../assets/images/x-dark.svg';
import { CheckBox, RadioButton, SimpleInput } from '../../../views/inputs/inputs';
import { getPrices, sliceOptions } from '../../../../utils/optionFuncs';
import Status from '../../../views/status/status';
import { ServiceRequest } from '../../../../helpers/connectionManager';
import { apiEndpoints, optionTypeEnums } from '../../../../helpers/constant';
import { priceFormat } from '../../../../utils/formatter';
import { localStr } from '../../../../helpers/language';
import { currencyText, showAmount } from '../../../../core/config';

function UploadOptions({ navigateNext = function () {}, navigatePrevious = function () {}, data }) {
  if (data === null) {
    return <div />;
  }
  const [freeOptionsOpen, setFreeOptionsOpen] = useState(false);
  const [paidOptionsOpen, setPaidOptionsOpen] = useState(false);
  const [solutions, setSolutions] = useState([]);
  const [couponText, setCouponText] = useState('');
  const [appliedCoupon, setAppliedCoupon] = useState(null);
  const [couponMessage, setCouponMessage] = useState(null);

  function removeCoupon() {
    setCouponText('');
    setAppliedCoupon(null);
    setCouponMessage(null);
  }

  useEffect(() => {
    if (data !== null) {
      getVehicleSolutions();
    }
  }, [data]);
  useEffect(() => {
    if (couponText.length === 9) {
      checkCouponCode();
    }
  }, [couponText]);
  function checkCouponCode() {
    ServiceRequest({
      endpoint: `${apiEndpoints.customer.checkCoupon}/${couponText}`,
      loading: true,
      errorMessage: false,
    })
      .then((res) => {
        setAppliedCoupon(res.data);
        setCouponMessage({
          type: 'success',
          label: res.message,
        });
      })
      .catch((error) => {
        setCouponMessage({
          type: 'canceled',
          label: error.message,
        });
      });
  }
  function getVehicleSolutions() {
    const body = {
      engineId: data?.vehicleData,
    };
    ServiceRequest({
      endpoint: apiEndpoints.customer.getVehicleSolutions,
      loading: true,
      method: 'POST',
      body,
    })
      .then((res) => {
        const allData = res?.data.map((solution) => {
          const paidOptions = solution?.tuningOptions?.filter((e) => e.type === optionTypeEnums.paid && e[String(data.fuel).toLowerCase()]);
          const freeOptions = solution?.tuningOptions?.filter((e) => e.type === optionTypeEnums.free && e[String(data.fuel).toLowerCase()]);
          return { ...solution, paidOptions, freeOptions };
        });
        setSolutions(allData);
      })
      .catch((error) => { });
  }
  function handleSolutionSelect(id) {
    setFreeOptionsOpen(false);
    setPaidOptionsOpen(false);
    const arr = solutions.map((e) => ({ ...e, selected: e.id === id }));
    setSolutions(arr);
  }
  function handleOptionSelect(option, solutionId) {
    const allData = [...solutions];
    switch (option?.type) {
      case optionTypeEnums.paid: {
        const index = allData.findIndex((e) => e.id === solutionId);
        if (index > -1) {
          const solution = allData[index];
          solution.paidOptions = solution.paidOptions.map((e) => ({ ...e, selected: e.id === option.id ? !e.selected : e.selected }));
          allData[index] = solution;
          setSolutions(allData);
        }
      }
        break;
      case optionTypeEnums.free: {
        const index = allData.findIndex((e) => e.id === solutionId);
        if (index > -1) {
          const solution = allData[index];
          solution.freeOptions = solution.freeOptions.map((e) => ({ ...e, selected: e.id === option.id ? !e.selected : e.selected }));
          allData[index] = solution;
          setSolutions(allData);
        }
      }
        break;
      default:
        break;
    }
  }
  function nextStep() {
    const newData = { ...data };
    const i = solutions.findIndex((e) => e.selected);
    if (i > -1) {
      const selectedSolution = solutions[i];
      const selectedPaidOptions = selectedSolution.paidOptions.filter((e) => e.selected);
      const selectedFreeOptions = selectedSolution.freeOptions.filter((e) => e.selected);
      const selectedOptions = [...selectedPaidOptions, ...selectedFreeOptions];
      newData.solutionId = selectedSolution.id;
      newData.options = selectedOptions.map((e) => e.id);
      newData.selectedSolution = selectedSolution;
      if (appliedCoupon) {
        newData.coupon = appliedCoupon.code;
        newData.appliedCoupon = appliedCoupon;
      }
      navigateNext({ data: newData, currentPage: 2 });
    }
  }

  return (
    <div className={styles.main}>
      <div className="d-flex">
        <div className={styles.c1}>
          <div className={styles.titleNumber1}>1</div>
          <div className={styles.title1}>{localStr()?.uploadOptions?.t1}</div>
        </div>
        <div className={styles.c2}>
          <div className={styles.titleNumber2}>2</div>
          <div className={styles.title2}>{localStr()?.uploadOptions?.t2}</div>
        </div>
        <div className={styles.c11}>
          <div className={styles.titleNumber1}>3</div>
          <div className={styles.title1}>{localStr()?.uploadOptions?.t3}</div>
        </div>
      </div>
      <div className={styles.c3}>
        <div className="d-flex align-center">
          <img alt="upload" src={icUpload} />
          <div className={styles.t1}>{localStr()?.uploadOptions?.t4}</div>
        </div>
        <div className="d-flex m-t-24">
          <div className={styles.cont1}>
            {solutions.map((item, index) => (
              <div key={index} className={`${`${styles.cont11} ${item.selected && styles.selected}`} ${index % 2 === 0 && styles.dark}`}>
                <div className="d-flex align-center">
                  <RadioButton checked={item.selected} toggle={() => handleSolutionSelect(item.id)} />
                  <div className={`${styles.cont11Title} ${!item.selected && styles.passive} ${item.selected && styles.selected}`}>{item.displayName}</div>
                  <div className={`${styles.cont11Price} ${!item.selected && styles.passive} ${item.selected && styles.selected}`}>
                    {priceFormat(item.cost)}
                    {' '}
                    {currencyText}
                  </div>
                </div>
                {item.selected && (
                <div className="d-flex m-t-18 f-column">
                  {item.freeOptions.length > 0 && (
                  <div className={`${styles.optionsCont} ${index % 2 === 0 && styles.light}`}>
                    <div className={styles.optionsContTitle} onClick={() => setFreeOptionsOpen(!freeOptionsOpen)}>
                      <div>
                        {localStr()?.uploadOptions?.t5}
                        {' '}
                        <span>
                          {' '}
                          (
                          {localStr()?.uploadOptions?.t6}
                          )
                        </span>
                      </div>
                      <img alt="options" src={freeOptionsOpen ? icArrow1 : icArrow2} />
                    </div>
                    {freeOptionsOpen && (
                    <div className={styles.optionsBody}>
                      <div className={styles.optionsBody1}>
                        {sliceOptions(item.freeOptions, 1).map((e, i) => (
                          <div key={i} className={styles.optionsRow}>
                            <CheckBox checked={e.selected} toggle={() => handleOptionSelect(e, item.id)} />
                            <div
                              className={`${styles.optionsTitle}`}
                            >
                              {e.optionName}
                            </div>
                            <div
                              className={`${styles.optionsPrice}`}
                            >
                              {priceFormat(e.cost)}
                              {' '}
                              {currencyText}
                            </div>
                          </div>
                        ))}
                      </div>
                      <div className="d-flex f-column f1">
                        {sliceOptions(item.freeOptions, 2).map((e, i) => (
                          <div key={i} className={styles.optionsRow}>
                            <CheckBox checked={e.selected} toggle={() => handleOptionSelect(e, item.id)} />
                            <div
                              className={`${styles.optionsTitle}`}
                            >
                              {e.optionName}
                            </div>
                            <div
                              className={`${styles.optionsPrice}`}
                            >
                              {priceFormat(e.cost)}
                              {' '}
                              {currencyText}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    )}
                  </div>
                  )}
                  {item.paidOptions.length > 0 && (
                  <div className={`${styles.optionsCont} m-t-18 ${index % 2 === 0 && styles.light}`}>
                    <div className={styles.optionsContTitle} onClick={() => setPaidOptionsOpen(!paidOptionsOpen)}>
                      <div>
                        {localStr()?.uploadOptions?.t7}
                        {' '}
                        <span>
                          {' '}
                          (
                          {localStr()?.uploadOptions?.t6}
                          )
                        </span>
                      </div>
                      <img alt="options" src={paidOptionsOpen ? icArrow1 : icArrow2} />
                    </div>
                    {paidOptionsOpen && (
                    <div className={styles.optionsBody}>
                      <div className={styles.optionsBody1}>
                        {sliceOptions(item.paidOptions, 1).map((e, i) => (
                          <div key={i} className={styles.optionsRow}>
                            <CheckBox checked={e.selected} toggle={() => handleOptionSelect(e, item.id)} />
                            <div
                              className={`${styles.optionsTitle}`}
                            >
                              {e.optionName}
                            </div>
                            <div
                              className={`${styles.optionsPrice}`}
                            >
                              {priceFormat(e.cost)}
                              {' '}
                              {currencyText}
                            </div>
                          </div>
                        ))}
                      </div>
                      <div className="d-flex f-column f1">
                        {sliceOptions(item.paidOptions, 2).map((e, i) => (
                          <div key={i} className={styles.optionsRow}>
                            <CheckBox checked={e.selected} toggle={() => handleOptionSelect(e, item.id)} />
                            <div
                              className={`${styles.optionsTitle}`}
                            >
                              {e.optionName}
                            </div>
                            <div
                              className={`${styles.optionsPrice}`}
                            >
                              {priceFormat(e.cost)}
                              {' '}
                              {currencyText}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    )}
                  </div>
                  )}
                </div>
                )}
              </div>
            ))}
          </div>
          {showAmount
              && (
              <div className="d-flex f-column f1">
                <div className={styles.priceCont}>
                  <div className={styles.priceCont1}>
                    {localStr()?.uploadOptions?.t8}
                  </div>
                  {getPrices(solutions, appliedCoupon) ? (
                    <div className={styles.priceCont3}>
                      {getPrices(solutions, appliedCoupon).items.map((item, index) => (
                        <div key={index} className={styles.priceRow}>
                          {item.name}
                          <span>
                            {item.cost}
                          </span>
                        </div>
                      ))}
                      <div className={styles.priceTotal}>
                        {localStr()?.uploadOptions?.t9}
                        <span>{getPrices(solutions, appliedCoupon).totalPrice}</span>
                      </div>
                    </div>
                  ) : (
                    <div className={styles.priceCont2}>
                      {localStr()?.uploadOptions?.t10}
                    </div>
                  )}
                </div>
                <div className={`${styles.priceCont} m-t-32`}>
                  <div className={styles.priceCont1}>
                    {localStr()?.uploadOptions?.t11}
                  </div>
                  {appliedCoupon ? (
                    <div className={styles.c4}>
                      <div className={styles.c42}>
                        {appliedCoupon.code}
                        <div className="c-pointer p-8" onClick={() => removeCoupon()}>
                          <img alt="close" src={icClose} />
                        </div>
                      </div>
                      {couponMessage?.type === 'success' && (
                      <div className="d-flex justify-center m-t-24">
                        <Status type={couponMessage.type} label={couponMessage.label} />
                      </div>
                      )}
                    </div>
                  ) : (
                    <div className={styles.c4}>
                      <SimpleInput
                        value={couponText}
                        onChange={(e) => setCouponText(e)}
                        maxLength={9}
                        placeHolder={localStr()?.uploadOptions?.t14}
                        formStyle={styles.c41}
                      />
                      {couponMessage?.type === 'canceled' && (
                      <div className="d-flex justify-center m-t-24">
                        <Status type={couponMessage.type} label={couponMessage.label} />
                      </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
              )}
        </div>
      </div>
      <div className="d-flex justify-space-between m-r-32 m-l-32 m-b-24">
        <AlternateButton
          buttonTitle={localStr()?.uploadOptions?.t12}
          buttonStyle="w-132"
          buttonClick={() => navigatePrevious({ currentPage: 2 })}
        />
        <PrimaryButton
          isGhost={solutions.findIndex((e) => e.selected) < 0}
          buttonTitle={localStr()?.uploadOptions?.t13}
          buttonStyle="w-132"
          buttonClick={() => nextStep()}
        />
      </div>
    </div>
  );
}

export default UploadOptions;
