import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './paymentFail.module.css';
import { MainContext } from '../../../context/store';
import { showError } from '../../../context/actions/popUpActions';

function PaymentFail() {
  const { dispatch } = useContext(MainContext);
  const navigate = useNavigate();
  useEffect(() => {
    dispatch.popUpDispatch(showError({ title: 'Payment Failed', text: 'A problem occurred during the payment phase. Please try again.' }));
    navigate('/');
  }, []);

  return (
    <div className={styles.back}>
      <div className={styles.loader} />
    </div>
  );
}

export default PaymentFail;
