import React from 'react';
import './App.css';
import './assets/fonts/fonts.css';
import { ApplicationStore } from './context/store';
import Router from './router/router';
import Loading from './components/views/modals/loading';
import {
  ErrorModal, InfoModal, QuestionModal, SuccessModal,
} from './components/views/modals/popUpModals';
import {
  ErrorToast, InfoToast, QuestionToast, SuccessToast,
} from './components/views/modals/toasts';

function App() {
  return (
    <ApplicationStore>
      <Router />
      <BaseModals />
    </ApplicationStore>
  );
}
export function BaseModals() {
  return (
    <>
      <Loading />
      <SuccessModal />
      <QuestionModal />
      <InfoModal />
      <ErrorModal />
      <InfoToast />
      <ErrorToast />
      <QuestionToast />
      <SuccessToast />
    </>
  );
}
export default App;
