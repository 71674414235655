import React, { useState } from 'react';
import styles from './upload.module.css';
import BaseView from '../../views/baseView/baseView';
import UploadDetail from './uploadDetail/uploadDetail';
import UploadOptions from './uploadOptions/uploadOptions';
import BillingInformation from './billingInformation/billingInformation';

function Upload() {
  const [pageState, setPageState] = useState(1);
  const [optionsData, setOptionsData] = useState(null);
  const [billingData, setBillingData] = useState(null);
  function handleForward({ data, currentPage }) {
    if (data) {
      switch (currentPage) {
        case 1:
          setOptionsData(data);
          setPageState(2);
          break;
        case 2:
          setBillingData(data);
          setPageState(3);
          break;
        default:
          break;
      }
    }
  }
  function handlePrevious({ currentPage }) {
    switch (currentPage) {
      case 2:
        setOptionsData(null);
        break;
      case 3:
        setBillingData(null);
        break;
      default:
        break;
    }
    setPageState(currentPage - 1);
  }
  function getBreadCrumb() {
    switch (pageState) {
      case 1:
        return '/ Upload / Vehicle Detail & File';
      case 2:
        return '/ Upload / Select Stage & Options';
      case 3:
        return '/ Upload / Billing Information';
      default:
        return '/ Upload';
    }
  }
  return (
    <BaseView path={getBreadCrumb()}>
      <div className={`${styles.main} ${pageState === 1 && styles.show}`}>
        <UploadDetail navigateNext={({ data, currentPage }) => handleForward({ data, currentPage })} />
      </div>
      {optionsData
      && (
      <div className={`${styles.main} ${pageState === 2 && styles.show}`}>
        <UploadOptions
          data={optionsData}
          navigateNext={({ data, currentPage }) => handleForward({ data, currentPage })}
          navigatePrevious={({ currentPage }) => handlePrevious({ currentPage })}
        />
      </div>
      )}
      {billingData && (
      <div className={`${styles.main} ${pageState === 3 && styles.show}`}>
        <BillingInformation
          data={billingData}
          navigatePrevious={({ currentPage }) => handlePrevious({ currentPage })}
        />
      </div>
      )}
    </BaseView>
  );
}

export default Upload;
