import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './boschSearch.module.css';
import BaseView from '../../views/baseView/baseView';
import icBosch from '../../../assets/images/boschLogo.svg';
import imgBoschHelp from '../../../assets/images/boschHelp.png';
import icUpload from '../../../assets/images/upload-blue.svg';
import { SimpleInput } from '../../views/inputs/inputs';
import search from '../../../assets/images/search.svg';
import Pagination from '../../views/pagination/pagination';
import { SecondaryButton } from '../../views/buttons/buttons';
import { MainContext } from '../../../context/store';
import { showQuestion } from '../../../context/actions/popUpActions';
import { VehicleServiceRequest } from '../../../helpers/connectionManager';
import { apiEndpoints } from '../../../helpers/constant';
import { localStr } from '../../../helpers/language';

function BoschSearch() {
  const { state, dispatch } = useContext(MainContext);
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedPage, setSelectedPage] = useState(1);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const pageItemCount = 1500;
  function showEcuHelper() {
    const modalComponent = (
      <div className={styles.boschModal}>
        <img alt="bosch" src={imgBoschHelp} />
      </div>
    );
    dispatch.popUpDispatch(showQuestion({
      title: localStr()?.bosch?.t1,
      text: modalComponent,
      buttons: [{
        buttonTitle: localStr()?.bosch?.t2,
        buttonClick: () => {},
      }],
    }));
  }
  useEffect(() => {
    getData();
  }, []);
  function getData() {
    VehicleServiceRequest({
      endpoint: apiEndpoints.ecuData.getAllBosch,
      errorMessage: false,
      loading: true,
    })
      .then((res) => {
        setData(res.data);
        setFilteredData(res.data);
      })
      .catch((err) => {
      });
  }
  useEffect(() => {
    setSelectedPage(1);
    if (searchTerm.length < 3) {
      setFilteredData(data);
    } else {
      let allData = [...data];
      allData = allData.filter((e) => String(e.carmanufacturer).toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
          || String(e.name).toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
          || String(e.number).toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1);
      setFilteredData(allData);
    }
  }, [searchTerm]);
  return (
    <BaseView path="/ DTC Search">
      <div className={styles.main}>
        <div className={styles.TitleCont}>
          <div className="d-flex f-column f1">
            <div className={styles.t1}>{localStr()?.bosch?.t3}</div>
            <div className={styles.t2}>{localStr()?.bosch?.t4}</div>
          </div>
          <SecondaryButton buttonTitle={localStr()?.bosch?.t5} buttonClick={() => showEcuHelper()} />
        </div>
        <SimpleInput
          formStyle={styles.search}
          onChange={(e) => setSearchTerm(e)}
          value={searchTerm}
          leftIcon={search}
          placeHolder={localStr()?.bosch?.t6}
        />
        <table className={styles.userTable}>
          <thead>
            <tr>
              <th className="w-150">{localStr()?.bosch?.t7}</th>
              <th>{localStr()?.bosch?.t8}</th>
              <th>{localStr()?.bosch?.t9}</th>
              <th className="w-180">{localStr()?.bosch?.t10}</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.length === 0 && (
            <tr>
              <td colSpan={7} className={styles.tableEmpty}>
                {localStr()?.bosch?.t11}
              </td>
            </tr>
            )}
            {filteredData.slice((selectedPage - 1) * pageItemCount, selectedPage * pageItemCount).map((item, index) => (
              <tr key={index} className={index % 2 === 1 ? styles.dark : ''}>
                <td className={`${styles.tType}  w-150`}>{item.name}</td>
                <td>{item.number}</td>
                <td>{item.carmanufacturer}</td>
                <td className={`${styles.tUpload} w-180`}>
                  <div className="d-flex align-center justify-center c-pointer" onClick={() => navigate(('/upload'))}>
                    <img alt="pdf" src={icUpload} className="m-r-4" />
                    {localStr()?.bosch?.t12}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {filteredData.length !== 0 && (
        <div className="d-flex m-t-24 align-center">
          <Pagination
            formStyle={styles.pagination}
            selectedPage={selectedPage}
            totalPage={parseInt(filteredData.length / pageItemCount, 10) + 1}
            onChange={(e) => setSelectedPage(e)}
          />
          {searchTerm === '' && (
          <div className={styles.t3}>
            {localStr()?.bosch?.t13}
            {' '}
            {(selectedPage - 1) * pageItemCount}
            {' '}
            -
            {(parseInt(filteredData.length / pageItemCount, 10) + 1) === selectedPage ? filteredData.length : selectedPage * pageItemCount}
            {' '}
            {localStr()?.bosch?.t14}
            {' '}
            {filteredData.length}
          </div>
          )}
        </div>
        )}
        <div className={styles.boschCont}>
          <img alt="bosch" src={icBosch} />
        </div>
      </div>
    </BaseView>
  );
}

export default BoschSearch;
