import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import styles from './register.module.css';
import poster from '../../../assets/images/loginBackPoster.png';
import back from '../../../assets/login.mp4';
import { CheckBox, GeneralInput } from '../../views/inputs/inputs';
import { PrimaryButton, SecondaryButton } from '../../views/buttons/buttons';
import { apiEndpoints, storageKeys } from '../../../helpers/constant';
import { getSessionValue } from '../../../helpers/sessionManager';
import { onlyNumberFormat } from '../../../utils/formatter';
import { MainContext } from '../../../context/store';
import { showToastError } from '../../../context/actions/toastActions';
import { ServiceRequest } from '../../../helpers/connectionManager';
import { localStr } from '../../../helpers/language';

function Register() {
  const { state, dispatch } = useContext(MainContext);
  const navigate = useNavigate();
  const [merchantCredentials, setMerchantCredentials] = useState(null);
  const [fullName, setFullName] = useState('');
  const [userName, setUserName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [rePassword, setRePassword] = useState('');
  const [agreement, setAgreement] = useState(false);
  const [reCaptcha, setReCaptcha] = useState(null);
  const captchaRef = useRef(null);
  useEffect(() => {
    getCredentials();
  }, []);
  function getCredentials() {
    const credentials = getSessionValue({ key: storageKeys.credentials });
    setMerchantCredentials(credentials);
  }
  function navigateLink(link) {
    window.open(link);
  }
  function sendRegisterOtp() {
    if (password !== rePassword) {
      dispatch.toastDispatch(showToastError({ text: 'The passwords you enter do not match.' }));
      return;
    }
    const bodyRegister = {
      logo: null,
      fullName,
      password,
      companyName,
      userName,
      phone,
      winols: '',
    };
    const body = { email: userName };
    ServiceRequest({
      endpoint: apiEndpoints.auth.sendOtpForRegister,
      loading: true,
      method: 'POST',
      body,
    })
      .then((res) => {
        navigate('/registersubmit', { state: { bodyRegister } });
      })
      .catch(() => {
        captchaRef.current.reset();
        setReCaptcha(null);
      });
  }
  function checkSignUpAvailable() {
    return fullName.length < 3 || userName.length < 3 || companyName.length < 3 || phone.length < 3 || password.length < 6 || rePassword.length < 6 || !agreement || reCaptcha === null;
  }
  return (
    <div className={styles.main}>
      <div className={styles.cont1}>
        <video autoPlay loop muted className={styles.back} poster={poster}>
          <source src={back} type="video/mp4" />
        </video>
        {merchantCredentials && (
        <div className={styles.videoLayer}>
          <img alt="ecufile" src={`data:image/png;base64,${merchantCredentials?.logoBlack}`} className={styles.logo} />
          <div className="d-flex align-center m-b-72 justify-center">
            {merchantCredentials?.infoMail && (
            <div onClick={() => navigateLink(`mailto:${merchantCredentials?.infoMail}?subject=Information`)} className={styles.footerItem}>{merchantCredentials?.infoMail}</div>
            )}
            {merchantCredentials?.facebook && (
            <div onClick={() => navigateLink(merchantCredentials?.facebook)} className={styles.footerItem}>facebook</div>
            )}
            {merchantCredentials?.instagram && (
            <div onClick={() => navigateLink(merchantCredentials?.instagram)} className={styles.footerItem}>instagram</div>
            )}
            {merchantCredentials?.linkedin && (
            <div onClick={() => navigateLink(merchantCredentials?.linkedin)} className={styles.footerItem}>linkedin</div>
            )}
            {merchantCredentials?.youtube && (
            <div onClick={() => navigateLink(merchantCredentials?.youtube)} className={styles.footerItem}>youtube</div>
            )}
            {merchantCredentials?.whatsapp && (
            <div onClick={() => navigateLink(merchantCredentials?.whatsapp)} className={styles.footerItem}>whatsapp</div>
            )}
            {merchantCredentials?.website && (
            <div onClick={() => navigateLink(merchantCredentials?.website)} className={styles.footerItem}>{merchantCredentials?.website}</div>
            )}
          </div>
        </div>
        )}
      </div>
      <div className={styles.sideCont}>
        <div className={styles.cont2}>
          <div className="d-flex f-column align-end">
            <SecondaryButton buttonTitle={localStr()?.register?.t1} buttonStyle="w-100" buttonClick={() => navigate('/login')} />
          </div>
          <div className="m-t-10-p">
            <div className={styles.title}>{localStr()?.register?.t2}</div>
            <div className={`${styles.t3} m-t-16`}>{localStr()?.register?.t3}</div>
            <GeneralInput
              title={localStr()?.register?.t4}
              formStyle="m-t-48"
              required
              value={fullName}
              onChange={(e) => setFullName(e)}
            />
            <GeneralInput
              title={localStr()?.register?.t5}
              inputType="email"
              formStyle="m-t-24"
              required
              value={userName}
              onChange={(e) => setUserName(e)}
            />
            <GeneralInput
              title={localStr()?.register?.t6}
              formStyle="m-t-24"
              required
              value={companyName}
              onChange={(e) => setCompanyName(e)}
            />
            <GeneralInput
              title={localStr()?.register?.t7}
              formStyle="m-t-24"
              required
              value={phone}
              onChange={(e) => setPhone(onlyNumberFormat(e))}
            />
            <GeneralInput
              maxLength={6}
              title={localStr()?.register?.t8}
              formStyle="m-t-24"
              required
              inputType="password"
              value={password}
              onChange={(e) => setPassword(e)}
            />
            <GeneralInput
              maxLength={6}
              inputType="password"
              title={localStr()?.register?.t9}
              formStyle="m-t-24"
              required
              value={rePassword}
              onChange={(e) => setRePassword(e)}
            />
            <div className="d-flex align-center m-t-32">
              <CheckBox checked={agreement} toggle={() => setAgreement(!agreement)} />
              <div className={styles.t1}>
                {localStr()?.register?.t10}
                {' '}
                <span onClick={() => { window.open('/agreement'); }}>{localStr()?.register?.t11}</span>
                {' '}
                {localStr()?.register?.t12}
              </div>
            </div>
            <div className={styles.cont3}>
              <ReCAPTCHA ref={captchaRef} sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} onChange={(e) => setReCaptcha(e)} />
            </div>
            <PrimaryButton
              buttonTitle={localStr()?.register?.t13}
              buttonStyle="m-t-48"
              buttonClick={() => sendRegisterOtp()}
              isGhost={checkSignUpAvailable()}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Register;
