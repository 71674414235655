import React, { useContext, useEffect } from 'react';
import { MainContext } from '../../../context/store';
import {
  ERROR_TOAST_OFF, INFO_TOAST_OFF, QUESTION_TOAST_OFF, SUCCESS_TOAST_OFF,
} from '../../../context/types';
import styles from './toasts.module.css';

function ErrorToast() {
  const { state, dispatch } = useContext(MainContext);
  const { errorToastStatus, text } = state.toastResponse;
  function toggle() {
    dispatch.toastDispatch({ type: ERROR_TOAST_OFF });
  }
  useEffect(() => {
    if (errorToastStatus) {
      setTimeout(() => {
        toggle();
      }, 3000);
    }
  }, [errorToastStatus]);
  if (errorToastStatus) {
    return (
      <div className={styles.main}>
        <div className={`${styles.snackbar} ${styles.error}`}>{text}</div>
      </div>
    );
  }
  return (
    <div />
  );
}

function InfoToast() {
  const { state, dispatch } = useContext(MainContext);
  const { infoToastStatus, text } = state.toastResponse;
  function toggle() {
    dispatch.toastDispatch({ type: INFO_TOAST_OFF });
  }
  useEffect(() => {
    if (infoToastStatus) {
      setTimeout(() => {
        toggle();
      }, 3000);
    }
  }, [infoToastStatus]);
  if (infoToastStatus) {
    return (
      <div className={styles.main}>
        <div className={`${styles.snackbar} ${styles.info}`}>{text}</div>
      </div>
    );
  }
  return (
    <div />
  );
}

function QuestionToast() {
  const { state, dispatch } = useContext(MainContext);
  const { questionToastStatus, text } = state.toastResponse;
  function toggle() {
    dispatch.toastDispatch({ type: QUESTION_TOAST_OFF });
  }
  useEffect(() => {
    if (questionToastStatus) {
      setTimeout(() => {
        toggle();
      }, 3000);
    }
  }, [questionToastStatus]);
  if (questionToastStatus) {
    return (
      <div className={styles.main}>
        <div className={`${styles.snackbar} ${styles.question}`}>{text}</div>
      </div>
    );
  }
  return (
    <div />
  );
}
function SuccessToast() {
  const { state, dispatch } = useContext(MainContext);
  const { successToastStatus, text } = state.toastResponse;
  function toggle() {
    dispatch.toastDispatch({ type: SUCCESS_TOAST_OFF });
  }
  useEffect(() => {
    if (successToastStatus) {
      setTimeout(() => {
        toggle();
      }, 3000);
    }
  }, [successToastStatus]);
  if (successToastStatus) {
    return (
      <div className={styles.main}>
        <div className={`${styles.snackbar} ${styles.success}`}>{text}</div>
      </div>
    );
  }
  return (
    <div />
  );
}
export {
  ErrorToast,
  InfoToast,
  SuccessToast,
  QuestionToast,
};
