import { showAmount } from '../core/config';

const phoneFormat = (input) => {
  // eslint-disable-next-line no-param-reassign
  input = input.replace(/\D/g, '');
  // eslint-disable-next-line no-param-reassign
  input = input.substring(0, 11);
  if (input[0] !== '0') {
    return '';
  }
  const num = `(${
    input.substring(0, 4)
  }${input.length > 4 ? ') ' : ''
  }${input.substring(4, 7)
  }${input.length > 7 ? ' ' : ''
  }${input.substring(7, 9)
  }${input.length > 9 ? ' ' : ''
  }${input.substring(9, 11)}`;
  return num;
};

function clearPhoneFormat(val) {
  return val.replace(/\D/g, '');
}

function onlyNumberFormat(val) {
  return String(val).replace(/[^\d]/, '');
}
function getTimeObject(time) {
  const [h, m] = time.split(':');
  const date = new Date();
  date.setHours(h, m, 0);
  date.toString();
  return date;
}
function getTimeFromDateObject(date) {
  const now = new Date(date);
  return [
    String(now.getHours()).split('').length === 1 ? `0${now.getHours()}` : String(now.getHours()),
    ':',
    String(now.getMinutes()).split('').length === 1 ? `0${now.getMinutes()}` : String(now.getMinutes()),
  ].join('');
}
function decodeString(str) {
  return new DOMParser().parseFromString(str, 'text/html').body.innerText;
}
function convertLocalToUTCDateTime(d) {
  let date = d;
  if (!date) {
    return date;
  }
  date = new Date(date);
  date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()));
  return date;
}
function priceFormat(v) {
  if (!showAmount) {
    return '';
  }
  let valArr = String(v);
  if (!valArr.includes('.')) {
    valArr += ',00';
  }
  if (valArr.split('.')[1]?.length === 1) {
    valArr += '0';
  }
  valArr = valArr.replace('.', ',');
  valArr = valArr.split('');
  let res = '';
  const c = valArr.length - 1;
  for (const i in valArr) {
    let t = '';
    if (c - i === 6 || c - i === 9 || c - i === 12) {
      t = '.';
    }
    res += valArr[i] + t;
  }
  return res;
}
export {
  phoneFormat, clearPhoneFormat, onlyNumberFormat, getTimeObject, getTimeFromDateObject, decodeString, convertLocalToUTCDateTime, priceFormat,
};
