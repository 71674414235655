import {
  ERROR_TOAST_ON,
  INFO_TOAST_ON,
  QUESTION_TOAST_ON,
  SUCCESS_TOAST_ON,
  ERROR_TOAST_OFF,
  INFO_TOAST_OFF,
  QUESTION_TOAST_OFF,
  SUCCESS_TOAST_OFF,
} from '../types';
import { debugModeOn } from '../../core/config';
import toastState from '../initialStates/toastState';

export default (state = toastState, action) => {
  if (debugModeOn) {
    console.group('ContextReducer: ', state, action);
    console.groupEnd();
  }
  switch (action.type) {
    case ERROR_TOAST_ON:
      return {
        ...state,
        errorToastStatus: true,
        infoToastStatus: false,
        successToastStatus: false,
        questionToastStatus: false,
        text: action.text,
      };
    case INFO_TOAST_ON:
      return {
        ...state,
        errorToastStatus: false,
        infoToastStatus: true,
        successToastStatus: false,
        questionToastStatus: false,
        text: action.text,
      };
    case SUCCESS_TOAST_ON:
      return {
        ...state,
        errorToastStatus: false,
        infoToastStatus: false,
        successToastStatus: true,
        questionToastStatus: false,
        text: action.text,
      };
    case QUESTION_TOAST_ON:
      return {
        ...state,
        errorToastStatus: false,
        infoToastStatus: false,
        successToastStatus: false,
        questionToastStatus: true,
        text: action.text,
      };
    case ERROR_TOAST_OFF:
    case SUCCESS_TOAST_OFF:
    case QUESTION_TOAST_OFF:
    case INFO_TOAST_OFF:
      return {
        ...state,
        errorToastStatus: false,
        infoToastStatus: false,
        successToastStatus: false,
        questionToastStatus: false,
        text: '',
      };
    default:
      return state;
  }
};
