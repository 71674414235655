import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import styles from './fileDetail.module.css';
import BaseView from '../../views/baseView/baseView';
import icUploadFile from '../../../assets/images/upload-simple.svg';
import { GeneralTextArea } from '../../views/inputs/inputs';
import { AlternateButton, PrimaryButton, SecondaryButton } from '../../views/buttons/buttons';
import icArrow from '../../../assets/images/downArrow1.svg';
import icDownload from '../../../assets/images/download-simple.svg';
import icDownloadBlue from '../../../assets/images/download-blue.svg';
import icClockBlue from '../../../assets/images/clock-blue.svg';
import icDownload1 from '../../../assets/images/download-simple-1.svg';
import Status from '../../views/status/status';
import person from '../../../assets/images/defaultProfileImage.png';
import { decodeString, priceFormat } from '../../../utils/formatter';
import {
  apiEndpoints, messageSenderEnums, orderStatusEnums, storageKeys,
} from '../../../helpers/constant';
import { ServiceRequest } from '../../../helpers/connectionManager';
import icBin from '../../../assets/images/ic-file-upload.png';
import { zipFile } from '../../../utils/helperFunctions';
import { MainContext } from '../../../context/store';
import { showToastError } from '../../../context/actions/toastActions';
import { getSessionValue } from '../../../helpers/sessionManager';
import { localStr } from '../../../helpers/language';
import { currencyText, showAmount } from '../../../core/config';

function FileDetail() {
  const { dispatch } = useContext(MainContext);
  const { state } = useLocation();
  const selectedFile = state?.file;
  if (!selectedFile) {
    return <Navigate to="/" />;
  }
  const [file, setFile] = useState(selectedFile);
  const [supportMessage, setSupportMessage] = useState('');
  const [supportFile, setSupportFile] = useState(null);
  const [chatData, setChatData] = useState(null);
  let interval;
  function downloadOriginalFile() {
    ServiceRequest({
      endpoint: `${apiEndpoints.customer.downloadOriginalFile}/${file?.id}`,
      loading: true,
      downloadFile: 'original-file',
    })
      .then((res) => {})
      .catch((error) => { });
  }

  useEffect(() => {
    getOrderDetail();
    checkOrderChatMessage();
    getOrderChatMessages();
    return () => {
      clearInterval(interval);
    };
  }, []);
  function checkOrderChatMessage() {
    interval = setInterval(() => {
      getOrderChatMessages();
    }, 15000);
  }
  const currentRef = useRef(true);
  useEffect(() => {
    const dropZone = document.getElementById('uploadDragArea');
    if (currentRef.current) {
      document.addEventListener('dragover', (e) => {
        e.preventDefault();
      });
      dropZone.addEventListener('drop', (e) => {
        handleSupportFile(e.dataTransfer.files[0]);
        e.preventDefault();
      });
    }
    currentRef.current = false;
    return () => {
      document.removeEventListener('dragover', (e) => {});
      dropZone.removeEventListener('drop', (e) => {});
    };
  }, []);
  function getOrderChatMessages() {
    ServiceRequest({
      endpoint: `${apiEndpoints.customer.getOrderChatMessages}/${file?.id}`,
      loading: false,
    })
      .then((res) => {
        setChatData(res.data);
      })
      .catch((error) => { });
  }
  function getOrderDetail() {
    ServiceRequest({
      endpoint: `${apiEndpoints.customer.getOrderDetailForCustomer}/${file?.id}`,
      loading: true,
    })
      .then((res) => {
        setFile(res.data);
      })
      .catch((error) => { });
  }
  function downloadEcuFile() {
    ServiceRequest({
      endpoint: `${apiEndpoints.customer.downloadEcuFile}/${file?.id}`,
      loading: true,
      downloadFile: 'mod-file',
    })
      .then((res) => {})
      .catch((error) => { });
  }
  function downloadChatAttachment(fileName) {
    ServiceRequest({
      endpoint: `${apiEndpoints.customer.downloadOrderChatFile}/${fileName}/${file.id}`,
      loading: true,
      downloadFile: 'chat',
    })
      .then((res) => {})
      .catch((error) => { });
  }
  function downloadInvoice() {
    ServiceRequest({
      endpoint: `${apiEndpoints.customer.downloadInvoice}/${file.id}`,
      loading: true,
      downloadFile: 'invoice',
    })
      .then((res) => {})
      .catch((error) => { });
  }
  function handleBrowseFile(inputId) {
    const input = document.getElementById(inputId);
    if (input) {
      input.click();
    }
  }
  const handleSupportFile = (invoiceFile) => {
    zipFile(invoiceFile)
      .then((zipContent) => {
        const fileName = new Date().getTime();
        const formData = new FormData();
        formData.append('file', zipContent, String(`${fileName}.zip`));
        ServiceRequest({
          endpoint: `${apiEndpoints.customer.uploadChatFile}/${file.id}`,
          loading: true,
          method: 'POST',
          body: formData,
          formData: true,
        })
          .then((res) => {
            setSupportFile(res.data);
            const input = document.getElementById('uploadSupportFile');
            if (input) {
              input.value = null;
            }
          })
          .catch((error) => {
            const input = document.getElementById('uploadSupportFile');
            if (input) {
              input.value = null;
            }
          });
      })
      .catch(() => {
        dispatch.toastDispatch(showToastError({ text: 'File could not be uploaded!' }));
      });
  };
  function sendOrderChatMessage() {
    const body = {
      file: supportFile,
      text: supportMessage,
    };
    ServiceRequest({
      endpoint: `${apiEndpoints.customer.sendOrderChatMessage}/${file?.id}`,
      loading: true,
      method: 'POST',
      body,
    })
      .then((res) => {
        setChatData(res.data);
        setSupportFile(null);
        setSupportMessage('');
      })
      .catch((error) => { });
  }
  function getMessagePhoto(message) {
    switch (message.messageSender) {
      case messageSenderEnums.customer: {
        return chatData.user?.logo ? `data:image/png;base64,${chatData.user?.logo}` : person;
      }
      case messageSenderEnums.admin: {
        const credentials = getSessionValue({ key: storageKeys.credentials });
        return credentials?.logoWhite ? `data:image/png;base64,${credentials?.logoWhite}` : person;
      }
      default:
        return person;
    }
  }
  return (
    <BaseView path="/ Files / File Detail">
      <input
        type="file"
        id="uploadSupportFile"
        autoComplete="off"
        style={{ display: 'none' }}
        onChange={(event) => handleSupportFile(event.target.files[0])}
      />
      <div className={styles.main}>
        <div className="d-flex align-start">
          <div className={styles.cont1}>
            <div className={styles.titleCont}>
              <div className={styles.t1}>{localStr()?.fileDetail?.t1}</div>
              <div className={styles.t2}>
                {localStr()?.fileDetail?.t2}
              </div>
            </div>
            <div id="uploadDragArea" className={styles.c1}>
              {supportFile ? (
                <div className="d-flex f-column align-center">
                  <img alt="file" src={icBin} />
                  <SecondaryButton
                    buttonTitle={localStr()?.fileDetail?.t3}
                    buttonStyle="w-100 h-12 m-t-12"
                    buttonClick={() => setSupportFile(null)}
                  />
                </div>
              ) : (
                <>
                  <img alt="upload" src={icUploadFile} className={styles.icUpload} />
                  <div className="m-t-16">
                    {localStr()?.fileDetail?.t4}
                    {' '}
                    <span onClick={() => handleBrowseFile('uploadSupportFile')}>{localStr()?.fileDetail?.t5}</span>
                    {' '}
                    {localStr()?.fileDetail?.t6}
                  </div>
                </>
              )}
            </div>
            <GeneralTextArea
              value={supportMessage}
              onChange={(e) => setSupportMessage(e)}
              formStyle="m-t-24"
              placeHolder={localStr()?.fileDetail?.t8}
            />
            <PrimaryButton buttonClick={() => sendOrderChatMessage()} buttonTitle={localStr()?.fileDetail?.t29} buttonStyle="m-t-24 w-132 align-self-end" />
          </div>
          <div className={styles.cont2}>
            <div className={styles.titleCont1}>
              <div className="d-flex f-column f1">
                <div
                  className={styles.t1}
                >
                  {decodeString(`${file?.brandName} / ${file?.modelName} / ${file?.generationName} / ${file?.engineName}`)}
                </div>
                <div className={styles.t3}>{file?.solution?.displayName}</div>
              </div>
            </div>
            <div className="m-t-12">
              <div className={styles.c2}>
                <div>{localStr()?.fileDetail?.t9}</div>
                <Status type={orderStatusEnums[file.orderStatus]} />
              </div>
              <div className={styles.c2}>
                <div>{localStr()?.fileDetail?.t10}</div>
                {file?.invoice ? (
                  <div className={`${styles.t41} c-pointer`} onClick={() => downloadInvoice()}>
                    {file.invoice}
                    <img alt="pdf" src={icDownloadBlue} className="m-l-8" />
                  </div>
                ) : (
                  <div className={styles.t41}>
                    <div className={styles.t42}>{localStr()?.fileDetail?.t30}</div>
                    <img alt="pdf" src={icClockBlue} className="m-l-8" />
                  </div>
                )}
              </div>
              <div className={styles.c2}>
                <div>{localStr()?.fileDetail?.t11}</div>
                <div className={styles.t4}>
                  {decodeString(file?.brandName)}
                </div>
              </div>
              <div className={styles.c2}>
                <div>{localStr()?.fileDetail?.t12}</div>
                <div className={styles.t4}>
                  {decodeString(file?.modelName)}
                </div>
              </div>
              <div className={styles.c2}>
                <div>{localStr()?.fileDetail?.t13}</div>
                <div className={styles.t4}>
                  {decodeString(file?.generationName)}
                </div>
              </div>
              <div className={styles.c2}>
                <div>{localStr()?.fileDetail?.t14}</div>
                <div className={styles.t4}>
                  {decodeString(file?.engineName)}
                </div>
              </div>
              <div className={styles.c2}>
                <div>{localStr()?.fileDetail?.t15}</div>
                <div className={styles.t4}>
                  {file?.plate}
                </div>
              </div>
              <div className={styles.c2}>
                <div>{localStr()?.fileDetail?.t16}</div>
                <div className={styles.t4}>
                  {file?.transmission}
                </div>
              </div>
              <div className={styles.c2}>
                <div>{localStr()?.fileDetail?.t17}</div>
                <div className={styles.t4}>
                  {file?.toolType}
                </div>
              </div>
              <div className={styles.c2}>
                <div>{localStr()?.fileDetail?.t18}</div>
                <div className={styles.t4}>
                  {file?.fileType}
                </div>
              </div>
              <div className={styles.c2}>
                <div>{localStr()?.fileDetail?.t19}</div>
                <div className={styles.t4}>
                  {file?.tool}
                </div>
              </div>
              <div className={styles.c2}>
                <div>{localStr()?.fileDetail?.t20}</div>
                <div className={styles.t4}>
                  {file?.ecuType}
                </div>
              </div>
              {(file?.orderNote !== null && file?.orderNote !== '') && (
              <div className={styles.c2}>
                <div>{localStr()?.fileDetail?.t21}</div>
                <div className={styles.t4}>
                  {file?.orderNote}
                </div>
              </div>
              )}
              {file?.tuningOptions?.length > 0 && (
              <div className={styles.c2}>
                <div>{localStr()?.fileDetail?.t22}</div>
                <div className={styles.t4}>
                  {file?.tuningOptions.map((e) => e.optionName).join(',')}
                </div>
              </div>
              )}
            </div>
            {showAmount && (
            <div className={styles.c3}>
              <div className={styles.t5}>{localStr()?.fileDetail?.t23}</div>
              <div className={styles.t6}>
                {priceFormat(file?.amount)}
                {' '}
                {currencyText}
              </div>
            </div>
            )}
            <div className="d-flex justify-space-between m-t-24">
              <AlternateButton
                buttonClick={() => downloadOriginalFile()}
                buttonTitle={localStr()?.fileDetail?.t24}
                leftIcon={icDownload}
                leftIconStyle={styles.icDownload}
              />
              {file?.modifiedFile
                    && (
                    <PrimaryButton
                      buttonTitle={localStr()?.fileDetail?.t25}
                      leftIcon={icDownload1}
                      leftIconStyle={styles.icDownload}
                      buttonClick={() => downloadEcuFile()}
                    />
                    )}
            </div>
          </div>
        </div>
        {chatData?.messages && (
          <div className={styles.cont3}>
            <div className={styles.titleCont}>
              <div className={styles.t1}>{localStr()?.fileDetail?.t26}</div>
              <div className={styles.t2}>
                {localStr()?.fileDetail?.t27}
              </div>
            </div>
            <div className={styles.chatArea}>
              {[...chatData.messages].reverse().map((item, index) => (
                <div key={index} className={styles.c4}>
                  <div className="d-flex f-column">
                    <img alt="profile" src={getMessagePhoto(item)} className={item.messageSender === messageSenderEnums.customer ? styles.messagePhoto : styles.messagePhotoAdmin} />
                    {chatData.messages.length - 1 !== index && <div className={styles.chatLine} />}
                  </div>
                  <div className={styles.c5}>
                    <div className={styles.c6}>
                      <div className={styles.t7}>{item.senderFullName}</div>
                      <div className={`${styles.t8} m-l-12`}>{new Date(item.messageDate).toLocaleString()}</div>
                    </div>
                    <div className={styles.t9}>
                      <div>{item.text}</div>
                      {item.file && (
                      <SecondaryButton
                        buttonTitle={localStr()?.fileDetail?.t28}
                        buttonStyle="m-t-16 w-150"
                        leftIcon={icArrow}
                        leftIconStyle={styles.icDownload}
                        buttonClick={() => downloadChatAttachment(item.file)}
                      />
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </BaseView>
  );
}

export default FileDetail;
