import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import styles from './uploadDetail.module.css';
import icUpload from '../../../../assets/images/upload/upload.svg';
import icFiles from '../../../../assets/images/upload/files.svg';
import icList from '../../../../assets/images/upload/list.svg';
import icUploadFile from '../../../../assets/images/upload-simple.svg';
import icBin from '../../../../assets/images/file-bin.svg';
import {
  CheckBox, GeneralDropdown, GeneralInput, GeneralTextArea, RadioButton, SwitchButton, SwitchButtonOrange,
} from '../../../views/inputs/inputs';
import { PrimaryButton, SecondaryButton } from '../../../views/buttons/buttons';
import icWarning from '../../../../assets/images/warning-circle.svg';
import { zipFile } from '../../../../utils/helperFunctions';
import { MainContext } from '../../../../context/store';
import { showToastError } from '../../../../context/actions/toastActions';
import { ServiceRequest, VehicleServiceRequest } from '../../../../helpers/connectionManager';
import { apiEndpoints } from '../../../../helpers/constant';
import { localStr } from '../../../../helpers/language';

function UploadDetail({ navigateNext = function () {} }) {
  const { state, dispatch } = useContext(MainContext);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [transmission, setTransmission] = useState(1);
  const [fileType, setFileType] = useState(1);
  const [toolType, setToolType] = useState(1);
  const [plate, setPlate] = useState('');
  const [ecuType, setEcuType] = useState('');
  const [tool, setTool] = useState('');
  const [professional, setProfessional] = useState(false);

  const [vehicleTypes, setVehicleTypes] = useState([]);
  const [selectedVehicleType, setSelectedVehicleType] = useState(null);
  const [manufacturerList, setManufacturerList] = useState([]);
  const [selectedManufacturer, setSelectedManufacturer] = useState(null);
  const [modelList, setModelList] = useState([]);
  const [selectedModel, setSelectedModel] = useState(null);
  const [yearList, setYearList] = useState([]);
  const [selectedYear, setSelectedYear] = useState(null);
  const [engineList, setEngineList] = useState([]);
  const [selectedEngine, setSelectedEngine] = useState(null);
  const [selectedVehicleData, setSelectedVehicleData] = useState(null);
  const [orderNote, setOrderNote] = useState('');

  const currentRef = useRef(true);
  useEffect(() => {
    const dropZone = document.getElementById('uploadDragArea');
    if (currentRef.current) {
      document.addEventListener('dragover', (e) => {
        e.preventDefault();
      });
      dropZone.addEventListener('drop', (e) => {
        handleBinFile(e.dataTransfer.files[0]);
        e.preventDefault();
      });
    }
    currentRef.current = false;
    getVehicleTypes();
    return () => {
      document.removeEventListener('dragover', (e) => {});
      dropZone.removeEventListener('drop', (e) => {});
    };
  }, []);

  function getVehicleTypes() {
    VehicleServiceRequest({
      endpoint: apiEndpoints.vehicle.getVehicleTypes,
      loading: true,
    })
      .then((res) => {
        const typeList = res?.data?.map((e) => ({ key: e.type, value: e.label }));
        setVehicleTypes(typeList);
      })
      .catch((err) => {
      });
  }
  function getManufacturerList(type) {
    setSelectedVehicleType(type);
    setSelectedManufacturer(null);
    setSelectedModel(null);
    setSelectedYear(null);
    setSelectedEngine(null);
    setSelectedVehicleData(null);
    setModelList([]);
    setYearList([]);
    setEngineList([]);
    const body = {
      vehicleTypes: [type.key],
    };
    VehicleServiceRequest({
      endpoint: apiEndpoints.vehicle.getBrandsForTypes,
      loading: true,
      body,
      method: 'POST',
    })
      .then((res) => {
        const typeList = res?.data?.map((e) => ({ key: e.id, value: e.name, item: e }));
        setManufacturerList(typeList);
      })
      .catch((err) => {
      });
  }
  function getModelList(manufecturer) {
    setSelectedManufacturer(manufecturer);
    setSelectedModel(null);
    setSelectedYear(null);
    setSelectedEngine(null);
    setSelectedVehicleData(null);
    setYearList([]);
    setEngineList([]);
    const body = {
      brandIds: [String(manufecturer.key)],
    };
    VehicleServiceRequest({
      endpoint: apiEndpoints.vehicle.getModelsForBrands,
      loading: true,
      body,
      method: 'POST',
    })
      .then((res) => {
        const list = res?.data?.map((e) => ({ key: e.id, value: e.name, item: e }));
        setModelList(list);
      })
      .catch((err) => {
      });
  }
  function getYearList(model) {
    setSelectedModel(model);
    setSelectedYear(null);
    setSelectedEngine(null);
    setSelectedVehicleData(null);
    setEngineList([]);
    const body = {
      modelIds: [String(model.key)],
    };
    VehicleServiceRequest({
      endpoint: apiEndpoints.vehicle.getGenerationsForModels,
      loading: true,
      body,
      method: 'POST',
    })
      .then((res) => {
        const list = res?.data?.map((e) => ({ key: e.id, value: e.name, item: e }));
        setYearList(list);
      })
      .catch((err) => {
      });
  }
  function getEngineList(year) {
    setSelectedYear(year);
    setSelectedEngine(null);
    setSelectedVehicleData(null);
    const body = {
      generationIds: [String(year.key)],
    };
    VehicleServiceRequest({
      endpoint: apiEndpoints.vehicle.getEnginesForGenerations,
      loading: true,
      body,
      method: 'POST',
    })
      .then((res) => {
        const list = res?.data?.map((e) => ({ key: e.id, value: e.name, item: e }));
        setEngineList(list);
      })
      .catch((err) => {
      });
  }
  function getVehicleData(engine) {
    setSelectedEngine(engine);
    const body = {
      enginIds: [String(engine.key)],
    };
    VehicleServiceRequest({
      endpoint: apiEndpoints.vehicle.getVehicleDatasForEngines,
      loading: true,
      body,
      method: 'POST',
    })
      .then((res) => {
        setSelectedVehicleData(res.data);
      })
      .catch((err) => {
      });
  }
  const handleBinFile = (file) => {
    zipFile(file)
      .then((zipContent) => {
        const fileName = new Date().getTime();
        const formData = new FormData();
        formData.append('file', zipContent, String(`${fileName}.zip`));
        ServiceRequest({
          endpoint: apiEndpoints.customer.uploadOriginalFile,
          loading: true,
          errorMessage: true,
          successMessage: true,
          method: 'POST',
          body: formData,
          formData: true,
        })
          .then((res) => {
            setUploadedFile(String(res.data));
            const input = document.getElementById('uploadFile');
            if (input) {
              input.value = null;
            }
          })
          .catch((error) => {
            const input = document.getElementById('uploadFile');
            if (input) {
              input.value = null;
            }
          });
      })
      .catch(() => {
        dispatch.toastDispatch(showToastError({ text: 'File could not be uploaded!' }));
      });
  };
  function handleBrowseFile() {
    const input = document.getElementById('uploadFile');
    if (input) {
      input.click();
    }
  }
  function checkNextStepGhost() {
    return !professional || plate === '' || tool === '' || ecuType === '' || selectedEngine === null || uploadedFile === null;
  }
  function nextStep() {
    const data = {
      vehicleData: selectedEngine?.key,
      solutionId: null,
      options: null,
      plate,
      ecuType,
      tool,
      transmission: transmission === 1 ? 'MANUEL' : 'AUTO',
      toolType: toolType === 1 ? 'SLAVE' : 'MASTER',
      fileType: fileType === 1 ? 'ECU' : 'TCU',
      file: uploadedFile,
      coupon: null,
      fuel: selectedVehicleData?.parent?.fuel,
      brandName: selectedManufacturer?.value,
      modelName: selectedModel?.value,
      generationName: selectedYear?.value,
      engineName: selectedEngine?.value,
      orderNote,
    };
    navigateNext({ data, currentPage: 1 });
  }
  return (
    <div className={styles.main}>
      <input
        type="file"
        id="uploadFile"
        autoComplete="off"
        style={{ display: 'none' }}
        onChange={(event) => handleBinFile(event.target.files[0])}
      />
      <div className="d-flex">
        <div className={styles.c1}>
          <div className={styles.titleNumber1}>1</div>
          <div className={styles.title1}>{localStr()?.uploadDetail?.t1}</div>
        </div>
        <div className={styles.c2}>
          <div className={styles.titleNumber2}>2</div>
          <div className={styles.title2}>{localStr()?.uploadDetail?.t2}</div>
        </div>
        <div className={styles.c21}>
          <div className={styles.titleNumber2}>3</div>
          <div className={styles.title2}>{localStr()?.uploadDetail?.t3}</div>
        </div>
      </div>
      <div className={styles.c3}>
        <div className="d-flex f1 f-column">
          <div className="d-flex align-center">
            <img alt="upload" src={icUpload} />
            <div className={styles.t1}>{localStr()?.uploadDetail?.t4}</div>
          </div>
          <div id="uploadDragArea" className={styles.c4}>
            {uploadedFile ? (
              <>
                <div className={styles.uploadFile}>
                  <img alt="file" src={icBin} />
                </div>
                <div className={styles.removeContent}>
                  <SecondaryButton
                    buttonTitle={localStr()?.uploadDetail?.t5}
                    buttonStyle="w-100"
                    buttonClick={() => setUploadedFile(null)}
                  />
                </div>
              </>
            ) : (
              <>
                <img alt="upload" src={icUploadFile} className={styles.icUpload} />
                <div className="m-t-16">
                  {localStr()?.uploadDetail?.t6}
                  {' '}
                  <span onClick={() => handleBrowseFile()}>{localStr()?.uploadDetail?.t7}</span>
                  {' '}
                  {localStr()?.uploadDetail?.t8}
                </div>
              </>
            )}
          </div>
          <div className="d-flex align-center m-t-32">
            <img alt="upload" src={icFiles} />
            <div className={styles.t1}>{localStr()?.uploadDetail?.t9}</div>
          </div>
          <div className="d-flex">
            <div className={`${styles.fileTypeCont} m-r-12 ${styles.selected}`}>
              <RadioButton
                checked={fileType === 1}
                toggle={() => setFileType(fileType === 1 ? 2 : 1)}
              />
              <div className={`${styles.t2} ${styles.selected}`}>{localStr()?.uploadDetail?.t10}</div>
            </div>
            <div className={`${styles.fileTypeCont} m-l-12`}>
              <RadioButton
                checked={fileType === 2}
                toggle={() => setFileType(fileType === 1 ? 2 : 1)}
              />
              <div className={styles.t2}>{localStr()?.uploadDetail?.t11}</div>
            </div>
          </div>
        </div>
        <div className="d-flex f1 f-column m-l-32">
          <div className="d-flex align-center">
            <img alt="upload" src={icList} />
            <div className={styles.t1}>{localStr()?.uploadDetail?.t12}</div>
          </div>
          <div className="m-t-32">
            <GeneralDropdown
              title={localStr()?.uploadDetail?.t13}
              required
              data={vehicleTypes}
              value={selectedVehicleType}
              onChange={(e) => getManufacturerList(e)}
            />
            <GeneralDropdown
              title={localStr()?.uploadDetail?.t14}
              required
              formStyle="m-t-24"
              data={manufacturerList}
              value={selectedManufacturer}
              onChange={(e) => getModelList(e)}
            />
            <GeneralDropdown
              title={localStr()?.uploadDetail?.t15}
              required
              formStyle="m-t-24"
              data={modelList}
              value={selectedModel}
              onChange={(e) => getYearList(e)}
            />
            <GeneralDropdown
              title={localStr()?.uploadDetail?.t16}
              required
              formStyle="m-t-24"
              data={yearList}
              value={selectedYear}
              onChange={(e) => getEngineList(e)}
            />
            <GeneralDropdown
              title={localStr()?.uploadDetail?.t17}
              required
              formStyle="m-t-24"
              data={engineList}
              value={selectedEngine}
              onChange={(e) => getVehicleData(e)}
            />
            <GeneralInput value={plate} onChange={(e) => setPlate(e)} title={localStr()?.uploadDetail?.t18} required formStyle="m-t-24" />
            <div className={styles.t9}>
              <img alt="pdf" src={icWarning} className="m-r-8" />
              {localStr()?.uploadDetail?.t19}
            </div>
            {selectedVehicleData && (
            <div className={styles.c5}>
              <div className={styles.c51}>{localStr()?.uploadDetail?.t20}</div>
              <div className={styles.c52}>
                <div className={styles.t3}>{String(localStr()?.uploadDetail?.t28)}</div>
                <div className={`${styles.t4} m-l-16`}>{selectedVehicleData?.parent?.ecus?.join(', ')}</div>
              </div>
              <div className={styles.c521}>
                <div className={styles.t3}>{localStr()?.uploadDetail?.t29}</div>
                <div className={`${styles.t4} m-l-16`}>{selectedVehicleData?.parent?.tools?.join(', ')}</div>
              </div>
            </div>
            )}
            <div className={styles.c6}>
              <GeneralInput value={ecuType} onChange={(e) => setEcuType(e)} title={localStr()?.uploadDetail?.t21} required />
              <GeneralInput
                value={tool}
                onChange={(e) => setTool(e)}
                title={localStr()?.uploadDetail?.t22}
                required
                formStyle="m-t-24"
              />
            </div>
            <div className={styles.c7}>
              <div className={styles.t5}>{localStr()?.uploadDetail?.t23}</div>
              <div className="d-flex align-center">
                <span className={`${styles.t7} ${transmission === 1 && styles.selected}`}>Manual</span>
                <span className={`${styles.t8} ${transmission === 2 && styles.selected}`}>Auto</span>
                <SwitchButton
                  switchStyle={styles.switch}
                  checked={transmission === 2}
                  toggle={() => setTransmission(transmission === 1 ? 2 : 1)}
                />
              </div>
            </div>
            <div className={styles.c7}>
              <div className={styles.t5}>{localStr()?.uploadDetail?.t24}</div>
              <div className="d-flex align-center">
                <span className={`${styles.t71} ${toolType === 1 && styles.selected}`}>Slave</span>
                <span className={`${styles.t81} ${toolType === 2 && styles.selected}`}>Master</span>
                <SwitchButtonOrange
                  switchStyle={styles.switchOrange}
                  checked={toolType === 2}
                  toggle={() => setToolType(toolType === 1 ? 2 : 1)}
                />
              </div>
            </div>
            <GeneralTextArea maxLength={250} value={orderNote} onChange={(e) => setOrderNote(e)} title={localStr()?.uploadDetail?.t25} formStyle="m-t-24" />
            <div className="d-flex align-center m-t-24">
              <CheckBox checked={professional} toggle={() => setProfessional(!professional)} />
              <div className={`${styles.t3} m-l-8`}>{localStr()?.uploadDetail?.t26}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="m-b-24">
        <PrimaryButton
          isGhost={checkNextStepGhost()}
          buttonTitle={localStr()?.uploadDetail?.t27}
          buttonStyle="f-right m-r-32 w-132"
          buttonClick={() => nextStep()}
        />
      </div>
    </div>
  );
}

export default UploadDetail;
