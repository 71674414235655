import { LOADING_TRUE, LOADING_FALSE } from '../types';
import { debugModeOn } from '../../core/config';
import loadingState from '../initialStates/loadingState';

export default (state = loadingState, action) => {
  if (debugModeOn) {
    console.group('ContextReducer: ', state, action);
    console.groupEnd();
  }
  switch (action.type) {
    case LOADING_TRUE:
      return {
        ...state,
        loadingStatus: true,
      };
    case LOADING_FALSE:
      return {
        ...state,
        loadingStatus: false,
      };
    default:
      return state;
  }
};
