import React, { useContext, useEffect, useState } from 'react';
import styles from './sideMenu.module.css';
import person from '../../../assets/images/defaultProfileImage.png';
import close from '../../../assets/images/menu-close.svg';
import side1 from '../../../assets/images/sideMenu/sideMenuIcon1.svg';
import side2 from '../../../assets/images/sideMenu/sideMenuIcon2.svg';
import side3 from '../../../assets/images/sideMenu/sideMenuIcon3.svg';
import side4 from '../../../assets/images/sideMenu/sideMenuIcon4.svg';
import side5 from '../../../assets/images/sideMenu/sideMenuIcon5.svg';
import photoChange from '../../../assets/images/photoChange.png';
import { PrimaryButton } from '../buttons/buttons';
import {
  apiEndpoints, billingTypeEnums, billingTypes, currencyTypes, languageTypes, storageKeys,
} from '../../../helpers/constant';
import {
  GeneralDropdown, GeneralInput, GeneralTextArea, SwitchButton,
} from '../inputs/inputs';
import Message from '../message/message';
import { MainContext } from '../../../context/store';
import { showQuestion } from '../../../context/actions/popUpActions';
import { resizeImage } from '../../../utils/helperFunctions';
import { ServiceRequest } from '../../../helpers/connectionManager';
import { clear } from '../../../helpers/sessionManager';
import countries from '../../../assets/region/country.json';
import cities from '../../../assets/region/city.json';
import states from '../../../assets/region/state.json';
import { onlyNumberFormat } from '../../../utils/formatter';
import { localStr } from '../../../helpers/language';

function SideMenu({
  show, toggleMenu = function () {}, updateApp, userData,
}) {
  const { state, dispatch } = useContext(MainContext);
  // profile
  const [logo, setLogo] = useState(null);
  const [fullName, setFullName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [evcWinOlsNumber, setEvcWinOlsNumber] = useState('');
  const [profileMessage, setProfileMessage] = useState(null);
  //------------------

  // billing information
  const [billingType, setBillingType] = useState(null);
  //------------------
  const [billingFullName, setBillingFullName] = useState('');
  const [billingId, setBillingId] = useState('');
  //------------------
  const [billingCompanyName, setBillingCompanyName] = useState('');
  const [billingTaxOffice, setBillingTaxOffice] = useState('');
  const [billingTaxId, setBillingTaxId] = useState('');
  //------------------
  const [billingEmail, setBillingEmail] = useState('');
  const [billingPhone, setBillingPhone] = useState('');
  const [billingCountry, setBillingCountry] = useState(null);
  const [billingProvince, setBillingProvince] = useState(null);
  const [billingDistrict, setBillingDistrict] = useState(null);
  const [billingPostCode, setBillingPostCode] = useState('');
  const [billingAddress, setBillingAddress] = useState('');
  const [billingMessage, setBillingMessage] = useState(null);

  //------------------

  // password
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [reNewPassword, setReNewPassword] = useState('');
  const [passwordMessage, setPasswordMessage] = useState(null);
  //------------------

  // settings
  const [emailNotification, setEmailNotification] = useState(true);
  const [preferredCurrency, setPreferredCurrency] = useState('EUR');
  const [settingsMessage, setSettingsMessage] = useState(null);
  const [preferredLanguage, setPreferredLanguage] = useState({
    key: 'en',
    value: 'English',
  });
  //------------------
  const [selectedMenu, setSelectedMenu] = useState(null);
  function toggle() {
    setSelectedMenu(null);
    toggleMenu();
  }

  useEffect(() => {
    setLanguage();
  }, []);
  function setLanguage() {
    const l = localStorage.getItem(storageKeys.langCode);
    if (l === 'tr') {
      setPreferredLanguage({
        key: 'tr',
        value: 'Türkçe',
      });
    }
  }
  function handleLanguageChange(lang) {
    localStorage.setItem(storageKeys.langCode, lang.key);
    window.location.reload();
  }
  useEffect(() => {
    if (userData) {
      setFullName(userData.fullName);
      setCompanyName(userData.companyName);
      setEmail(userData.userName);
      setPhone(userData.phone);
      setEvcWinOlsNumber(userData.winols);
      setLogo(userData.logo);
    }
  }, [userData]);
  useEffect(() => {
    setProfileMessage(null);
    setBillingMessage(null);
    setPasswordMessage(null);
    setSettingsMessage(null);
    setOldPassword('');
    setNewPassword('');
    setReNewPassword('');
  }, [selectedMenu]);
  useEffect(() => {
    switch (selectedMenu) {
      case 2:
        getBillingInformation();
        break;
      case 4:
        getSettings();
        break;
      default:
        break;
    }
  }, [selectedMenu]);

  function getBillingInformation() {
    ServiceRequest({
      endpoint: apiEndpoints.customer.getBillingInformation,
      loading: true,
      errorMessage: false,
    })
      .then((res) => {
        setBillingInformationData(res.data);
      })
      .catch((error) => { setBillingType(billingTypes()[0]); });
  }
  function getSettings() {
    ServiceRequest({
      endpoint: apiEndpoints.customer.getSettings,
      loading: true,
      errorMessage: false,
    })
      .then((res) => {
        setEmailNotification(res?.data?.emailNotification);
        setPreferredCurrency(res?.data?.preferredCurrency);
      })
      .catch((error) => { });
  }
  function updateSettings() {
    const body = {
      emailNotification,
      preferredCurrency,
    };
    ServiceRequest({
      endpoint: apiEndpoints.customer.updateSettings,
      loading: true,
      method: 'POST',
      body,
    })
      .then((res) => {
        setEmailNotification(res?.data?.emailNotification);
        setPreferredCurrency(res?.data?.preferredCurrency);
        setSettingsMessage({
          messageType: 'success', title: localStr()?.sideMenu?.t2, text: res.message,
        });
      })
      .catch((error) => {
        setSettingsMessage({
          messageType: 'error', title: localStr()?.sideMenu?.t8, text: error.message,
        });
      });
  }
  function checkBillingSaveAvailable() {
    if (billingType?.key === billingTypeEnums.individual) {
      if (billingFullName === '' || billingId === '') {
        return true;
      }
    } else if (billingType?.key === billingTypeEnums.company) {
      if (billingCompanyName === '' || billingTaxOffice === '' || billingTaxId === '') {
        return true;
      }
    }
    return billingEmail === '' || billingPhone === '' || billingCountry === null || billingProvince === null || billingDistrict === null || billingPostCode === ''
        || billingAddress === '';
  }
  function updateBillingInformation() {
    const body = {
      billingType: billingType?.key,
      fullName: billingFullName,
      idNumber: billingId,
      companyName: billingCompanyName,
      taxOffice: billingTaxOffice,
      taxId: billingTaxId,
      email: billingEmail,
      phone: billingPhone,
      country: billingCountry?.key,
      province: billingProvince?.key,
      district: billingDistrict?.key,
      postCode: billingPostCode,
      billingAddress,
    };
    ServiceRequest({
      endpoint: apiEndpoints.customer.updateBillingInformation,
      loading: true,
      errorMessage: false,
      method: 'POST',
      body,
    })
      .then((res) => {
        setBillingInformationData(res.data);
        setBillingMessage({
          messageType: 'success', title: localStr()?.sideMenu?.t2, text: res.message,
        });
      })
      .catch((error) => {
        setBillingMessage({
          messageType: 'error', title: localStr()?.sideMenu?.t8, text: error.message,
        });
      });
  }
  function setBillingInformationData(data) {
    setBillingType(billingTypes().filter((e) => e.key === data?.billingType)[0]);
    setBillingFullName(data?.fullName);
    setBillingId(data?.idNumber);
    setBillingCompanyName(data?.companyName);
    setBillingTaxOffice(data?.taxOffice);
    setBillingTaxId(data?.taxId);
    setBillingEmail(data?.email);
    setBillingPhone(data?.phone);
    setBillingCountry(countries.filter((e) => e.key === data?.country)[0]);
    setBillingProvince(states.filter((e) => e.key === data?.province)[0]);
    setBillingDistrict(cities.filter((e) => e.key === data?.district)[0]);
    setBillingPostCode(data?.postCode);
    setBillingAddress(data?.billingAddress);
  }
  function updateProfile(newLogo) {
    const body = {
      logo: newLogo,
      fullName,
      companyName,
      userName: email,
      phone,
      winols: evcWinOlsNumber,
    };
    ServiceRequest({
      endpoint: apiEndpoints.customer.updateCustomer,
      loading: true,
      errorMessage: false,
      method: 'POST',
      body,
    })
      .then((res) => {
        const { data } = res;
        sessionStorage.setItem(storageKeys.user, JSON.stringify(res.data));
        setProfileMessage({
          messageType: 'success', title: localStr()?.sideMenu?.t2, text: res.message,
        });
        updateApp();
      })
      .catch((error) => {
        setProfileMessage({
          messageType: 'error', title: localStr()?.sideMenu?.t8, text: error.message,
        });
      });
  }
  function updatePassword() {
    if (newPassword === reNewPassword) {
      const body = {
        oldPassword,
        newPassword,
      };
      ServiceRequest({
        endpoint: apiEndpoints.customer.updatePassword,
        loading: true,
        errorMessage: false,
        method: 'POST',
        body,
      })
        .then((res) => {
          setPasswordMessage({
            messageType: 'success', title: localStr()?.sideMenu?.t2, text: res.message,
          });
        })
        .catch((error) => {
          setPasswordMessage({
            messageType: 'error', title: localStr()?.sideMenu?.t8, text: error.message,
          });
        });
    } else {
      setPasswordMessage({
        messageType: 'error', title: localStr()?.sideMenu?.t8, text: localStr()?.sideMenu?.t9,
      });
    }
  }
  function renderDetails() {
    switch (selectedMenu) {
      case 1:
        return (
          <>
            <div className={styles.t5}>{localStr()?.sideMenu?.t44}</div>
            <div className={styles.mainArea}>
              <div className={styles.c4}>
                <GeneralInput
                  value={fullName}
                  onChange={(e) => setFullName(e)}
                  required
                  title={localStr()?.sideMenu?.t1}
                  formStyle={styles.form1}
                />
                <GeneralInput
                  value={companyName}
                  onChange={(e) => setCompanyName(e)}
                  required
                  title={localStr()?.sideMenu?.t3}
                  formStyle={styles.form1}
                />
                <GeneralInput
                  value={email}
                  onChange={(e) => setEmail(e)}
                  required
                  title={localStr()?.sideMenu?.t4}
                  formStyle={styles.form1}
                />
                <GeneralInput
                  value={phone}
                  onChange={(e) => setPhone(onlyNumberFormat(e))}
                  required
                  title={localStr()?.sideMenu?.t5}
                  formStyle={styles.form1}
                />
                <GeneralInput
                  value={evcWinOlsNumber}
                  onChange={(e) => setEvcWinOlsNumber(e)}
                  title={localStr()?.sideMenu?.t6}
                  formStyle={styles.form1}
                />
              </div>
              <div className={styles.c5}>
                {profileMessage && (
                  <Message
                    formStyle="m-b-24"
                    toggle={() => setProfileMessage(null)}
                    title={profileMessage.title}
                    text={profileMessage.text}
                    messageType={profileMessage.messageType}
                  />
                )}
                <PrimaryButton
                  isGhost={fullName === '' || companyName === '' || email === '' || phone === ''}
                  buttonTitle={localStr()?.sideMenu?.t7}
                  buttonClick={() => updateProfile(logo)}
                />
              </div>
            </div>
          </>
        );
      case 2:
        return (
          <>
            <div className={styles.t5}>{localStr()?.sideMenu?.t45}</div>
            <div className={styles.mainArea}>
              <div className={styles.c4}>
                <GeneralDropdown
                  title={localStr()?.sideMenu?.t11}
                  formStyle="m-t-32"
                  required
                  data={billingTypes()}
                  value={billingType}
                  onChange={(e) => setBillingType(e)}
                />
                {billingType?.key === billingTypeEnums.individual
                && (
                <>
                  <GeneralInput
                    value={billingFullName}
                    onChange={(e) => setBillingFullName(e)}
                    required
                    title={localStr()?.sideMenu?.t1}
                    formStyle={styles.form1}
                  />
                  <GeneralInput
                    value={billingId}
                    onChange={(e) => setBillingId(e)}
                    required
                    title={localStr()?.sideMenu?.t12}
                    formStyle={styles.form1}
                  />
                </>
                )}
                {billingType?.key === billingTypeEnums.company
                    && (
                    <>
                      <GeneralInput
                        value={billingCompanyName}
                        onChange={(e) => setBillingCompanyName(e)}
                        required
                        title={localStr()?.sideMenu?.t3}
                        formStyle={styles.form1}
                      />
                      <GeneralInput
                        value={billingTaxOffice}
                        onChange={(e) => setBillingTaxOffice(e)}
                        required
                        title={localStr()?.sideMenu?.t13}
                        formStyle={styles.form1}
                      />
                      <GeneralInput
                        value={billingTaxId}
                        onChange={(e) => setBillingTaxId(e)}
                        required
                        title={localStr()?.sideMenu?.t14}
                        formStyle={styles.form1}
                      />
                    </>
                    )}
                <GeneralInput
                  value={billingEmail}
                  onChange={(e) => setBillingEmail(e)}
                  required
                  title={localStr()?.sideMenu?.t4}
                  formStyle={styles.form1}
                />
                <GeneralInput
                  value={billingPhone}
                  onChange={(e) => setBillingPhone(onlyNumberFormat(e))}
                  required
                  title={localStr()?.sideMenu?.t5}
                  formStyle={styles.form1}
                />
                <GeneralDropdown
                  data={countries}
                  title={localStr()?.sideMenu?.t15}
                  formStyle="m-t-24"
                  required
                  value={billingCountry}
                  onChange={(e) => {
                    setBillingProvince(null);
                    setBillingDistrict(null);
                    setBillingCountry(e);
                  }}
                />
                <GeneralDropdown
                  data={states.filter((e) => e.country_id === billingCountry?.key)}
                  title={localStr()?.sideMenu?.t16}
                  formStyle="m-t-24"
                  required
                  value={billingProvince}
                  onChange={(e) => {
                    setBillingDistrict(null);
                    setBillingProvince(e);
                  }}
                />
                <GeneralDropdown
                  data={cities.filter((e) => e.state_id === billingProvince?.key)}
                  title={localStr()?.sideMenu?.t17}
                  formStyle="m-t-24"
                  required
                  value={billingDistrict}
                  onChange={(e) => setBillingDistrict(e)}
                />
                <GeneralInput
                  value={billingPostCode}
                  onChange={(e) => setBillingPostCode(e)}
                  required
                  title={localStr()?.sideMenu?.t18}
                  formStyle={styles.form1}
                />
                <GeneralTextArea
                  value={billingAddress}
                  onChange={(e) => setBillingAddress(e)}
                  required
                  title={localStr()?.sideMenu?.t19}
                  formStyle={styles.form1}
                />
              </div>
              <div className={styles.c5}>
                {billingMessage && (
                  <Message
                    formStyle="m-b-24"
                    toggle={() => setBillingMessage(null)}
                    title={billingMessage.title}
                    text={billingMessage.text}
                    messageType={billingMessage.messageType}
                  />
                )}
                <PrimaryButton
                  isGhost={checkBillingSaveAvailable()}
                  buttonTitle={localStr()?.sideMenu?.t7}
                  buttonClick={() => updateBillingInformation()}
                />
              </div>
            </div>
          </>
        );
      case 3:
        return (
          <>
            <div className={styles.t5}>{localStr()?.sideMenu?.t46}</div>
            <div className={styles.c4}>
              <GeneralInput
                value={oldPassword}
                onChange={(e) => setOldPassword(e)}
                required
                maxLength={6}
                inputType="password"
                title={localStr()?.sideMenu?.t20}
                formStyle={styles.form1}
              />
              <GeneralInput
                value={newPassword}
                onChange={(e) => setNewPassword(e)}
                required
                maxLength={6}
                title={localStr()?.sideMenu?.t21}
                inputType="password"
                formStyle={styles.form1}
              />
              <GeneralInput
                value={reNewPassword}
                onChange={(e) => setReNewPassword(e)}
                required
                maxLength={6}
                inputType="password"
                title={localStr()?.sideMenu?.t22}
                formStyle={styles.form1}
              />
            </div>
            <div className={styles.c5}>
              {passwordMessage && (
                <Message
                  formStyle="m-b-24"
                  toggle={() => setPasswordMessage(null)}
                  title={passwordMessage.title}
                  text={passwordMessage.text}
                  messageType={passwordMessage.messageType}
                />
              )}
              <PrimaryButton
                buttonTitle={localStr()?.sideMenu?.t23}
                isGhost={oldPassword.length < 6 || newPassword.length < 6 || reNewPassword.length < 6}
                buttonClick={() => updatePassword()}
              />
            </div>
          </>
        );
      case 4:
        return (
          <>
            <div className={styles.t5}>{localStr()?.sideMenu?.t47}</div>
            <div className={`${styles.c4} m-t-24`}>
              <div className={styles.c6}>
                <div className={styles.t6}>
                  {localStr()?.sideMenu?.t41}
                  {' '}
                  <span>{localStr()?.sideMenu?.t42}</span>
                </div>
                <SwitchButton checked={emailNotification} toggle={() => setEmailNotification(!emailNotification)} />
              </div>
              <div className={`${styles.t6} m-t-24`}>
                {localStr()?.sideMenu?.t43}
                {' '}
                <div className="f-right">{currencyTypes[preferredCurrency]}</div>
              </div>
              <div className={`${styles.c7} m-t-24`}>
                <GeneralDropdown
                  formStyle="m-t-24"
                  title={localStr()?.sideMenu?.t48}
                  data={languageTypes}
                  value={preferredLanguage}
                  onChange={(e) => handleLanguageChange(e)}
                />
              </div>
            </div>
            <div className={styles.c5}>
              {settingsMessage && (
              <Message
                formStyle="m-b-24"
                toggle={() => setSettingsMessage(null)}
                title={settingsMessage.title}
                text={settingsMessage.text}
                messageType={settingsMessage.messageType}
              />
              )}
              <PrimaryButton
                buttonTitle={localStr()?.sideMenu?.t7}
                buttonClick={() => updateSettings()}
              />
            </div>
          </>
        );
      default:
        return (
          <div className="d-flex f-column justify-end f1">
            <div className={`${styles.t322} m-t-16 m-b-16 m-l-16`}>
              <span onClick={() => {
                window.open('/agreement');
              }}
              >
                {localStr()?.sideMenu?.t24}
              </span>
            </div>
          </div>
        );
    }
  }

  function askForLogOut() {
    dispatch.popUpDispatch(
      showQuestion({
        title: localStr()?.sideMenu?.t26,
        text: localStr()?.sideMenu?.t27,
        buttons: [
          { buttonTitle: localStr()?.sideMenu?.t28, buttonClick: () => clear() },
          {
            buttonTitle: localStr()?.sideMenu?.t29,
            buttonClick: () => {},
          },
        ],
      }),
    );
  }
  const encodeImageFileAsURL = (file) => {
    const reader = new FileReader();
    reader.onloadend = function () {
      resizeImage(reader.result)
        .then((newLogo) => {
          updateProfile(newLogo.split(',')[1]);
          const input = document.getElementById('file');
          if (input) {
            input.value = null;
          }
        })
        .catch(() => {
          const input = document.getElementById('uploadFile');
          if (input) {
            input.value = null;
          }
        });
    };
    if (file !== undefined && file !== null) {
      reader.readAsDataURL(file);
    }
  };
  function handlePhotoClick() {
    const input = document.getElementById('file');
    if (input) {
      input.click();
    }
  }
  return (
    <div className={`${styles.sideBarBack} ${show && styles.show}`} onClick={() => toggle(!show)}>
      <div className={`${styles.sideBar} ${show && styles.show}`} onClick={(event) => event.stopPropagation()}>
        <div className={styles.c1}>
          <div className="d-flex align-center f1">
            <div className="d-flex p-relative c-pointer" onClick={() => handlePhotoClick()}>
              <img
                alt="profile"
                src={logo ? `data:image/png;base64,${logo}` : person}
                className={styles.profileImage}
              />
              <img alt="change" src={photoChange} className={styles.photoChange} />
            </div>
            <input
              type="file"
              id="file"
              autoComplete="off"
              accept="image/*"
              style={{ display: 'none' }}
              onChange={(event) => encodeImageFileAsURL(event.target.files[0])}
            />
            <div className="m-l-12">
              <div className={styles.t1}>{localStr()?.sideMenu?.t30}</div>
              <div className={styles.t2}>
                {userData?.fullName}
              </div>
            </div>
          </div>
          <div className="c-pointer" onClick={() => toggle(!show)}>
            <img alt="close" src={close} />
          </div>
        </div>
        <div className={styles.c2}>
          <div
            className={`${styles.c3} ${selectedMenu === 1 && styles.selected}`}
            onClick={() => setSelectedMenu(selectedMenu === 1 ? null : 1)}
          >
            <img alt="menu" src={side1} />
            <div className="d-flex f-column m-l-12">
              <div className={styles.t3}>{localStr()?.sideMenu?.t31}</div>
              <div className={styles.t4}>{localStr()?.sideMenu?.t32}</div>
            </div>
          </div>
          <div
            className={`${styles.c3} ${selectedMenu === 2 && styles.selected}`}
            onClick={() => setSelectedMenu(selectedMenu === 2 ? null : 2)}
          >
            <img alt="menu" src={side4} />
            <div className="d-flex f-column m-l-12">
              <div className={styles.t3}>{localStr()?.sideMenu?.t33}</div>
              <div className={styles.t4}>{localStr()?.sideMenu?.t34}</div>
            </div>
          </div>
          <div
            className={`${styles.c3} ${selectedMenu === 3 && styles.selected}`}
            onClick={() => setSelectedMenu(selectedMenu === 3 ? null : 3)}
          >
            <img alt="menu" src={side2} />
            <div className="d-flex f-column m-l-12">
              <div className={styles.t3}>{localStr()?.sideMenu?.t35}</div>
              <div className={styles.t4}>{localStr()?.sideMenu?.t36}</div>
            </div>
          </div>
          <div
            className={`${styles.c3} ${selectedMenu === 4 && styles.selected}`}
            onClick={() => setSelectedMenu(selectedMenu === 4 ? null : 4)}
          >
            <img alt="menu" src={side3} />
            <div className="d-flex f-column m-l-12">
              <div className={styles.t3}>{localStr()?.sideMenu?.t37}</div>
              <div className={styles.t4}>{localStr()?.sideMenu?.t38}</div>
            </div>
          </div>
          <div
            className={`${styles.c3}`}
            onClick={() => {
              toggle();
              askForLogOut();
            }}
          >
            <img alt="menu" src={side5} />
            <div className="d-flex f-column m-l-12">
              <div className={styles.t3}>{localStr()?.sideMenu?.t39}</div>
              <div className={styles.t4}>{localStr()?.sideMenu?.t40}</div>
            </div>
          </div>
        </div>
        {renderDetails()}
      </div>
    </div>
  );
}

export default SideMenu;
