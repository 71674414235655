import React, { useEffect, useState } from 'react';
import styles from './tableComponents.module.css';
import arrow from '../../../assets/images/arrowDown.svg';
import search from '../../../assets/images/search.svg';
import close from '../../../assets/images/x.svg';

function TableSorting({
  title, items, onClick = function () {}, selected,
}) {
  const [show, setShow] = useState(false);
  function handleSelection(item) {
    onClick(item);
    setShow(false);
  }
  return (
    <div className={styles.main}>
      <div className={styles.label} onClick={() => setShow(true)}>
        {title}
        <img alt="sorting" src={arrow} />
      </div>
      <div className={`${styles.sorting} ${show && styles.show}`}>
        <div className="d-flex align-center justify-space-between m-b-16 c-pointer" onClick={() => setShow(false)}>
          {title}
          <img alt="sorting" src={arrow} />
        </div>
        {items && items.map((item, index) => (
          <div className={`${styles.item} ${selected?.key === item.key && styles.selected}`} key={index} onClick={() => handleSelection(item)}>
            {item.value}
          </div>
        ))}
      </div>
    </div>
  );
}
function TableDateSearch({
  title, submitDate = function () {},
}) {
  const [show, setShow] = useState(false);
  const [dateSearch, setDateSearch] = useState(null);
  useEffect(() => {
    submitDate(dateSearch);
  }, [dateSearch]);
  return (
    <div className={styles.main}>
      <div className={styles.label} onClick={() => setShow(true)}>
        {title}
        <img alt="sorting" src={search} />
      </div>
      <div className={`${styles.search} ${show && styles.show}`}>
        <img alt="sorting" src={search} />
        <input
          type="date"
          className={styles.searchInput}
          value={dateSearch ?? new Date()}
          onChange={(e) => setDateSearch(e.target.value)}
        />
        <div
          onClick={() => {
            setDateSearch(null);
            setShow(false);
          }}
          className="c-pointer"
        >
          <img alt="sorting" src={close} />
        </div>
      </div>
    </div>
  );
}

export { TableSorting, TableDateSearch };
