import React from 'react';
import styles from './message.module.css';
import alert1 from '../../../assets/images/alerts/alert1.svg';
import alert2 from '../../../assets/images/alerts/alert2.svg';
import alert3 from '../../../assets/images/alerts/alert3.svg';
import alert4 from '../../../assets/images/alerts/alert4.svg';
import close from '../../../assets/images/x.svg';

function Message({
  messageType, title, text, toggle = function () {}, formStyle,
}) {
  function iconSelector() {
    switch (messageType) {
      case 'success':
        return alert1;
      case 'question':
        return alert2;
      case 'info':
        return alert3;
      case 'error':
        return alert4;
      default:
        return alert1;
    }
  }
  function containerSelector() {
    switch (messageType) {
      case 'success':
        return styles.success;
      case 'question':
        return styles.question;
      case 'info':
        return styles.info;
      case 'error':
        return styles.error;
      default:
        return styles.success;
    }
  }
  return (
    <div className={`${styles.container} ${formStyle} ${containerSelector()}`}>
      <img alt="alert" src={iconSelector()} className={styles.icon} />
      <div className="d-flex f-column f1 m-l-16 m-r-16">
        <div className={styles.t1}>{title}</div>
        <div className={styles.t2}>{text}</div>
      </div>
      <div onClick={() => toggle()} className="c-pointer">
        <img alt="close" src={close} />
      </div>
    </div>
  );
}
export default Message;
