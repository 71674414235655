import {
  ERROR_MESSAGE_ON, INFO_MESSAGE_ON, QUESTION_MESSAGE_ON, SUCCESS_MESSAGE_ON,
} from '../types';

export const showError = ({
  title = 'Warning!',
  text = 'An error occurred.',
  buttons,
}) => ({
  type: ERROR_MESSAGE_ON,
  title,
  text,
  buttons,
});

export const showInfo = ({
  title = 'Info!',
  text = 'Done.',
  buttons,
}) => ({
  type: INFO_MESSAGE_ON,
  title,
  text,
  buttons,
});
export const showSuccess = ({
  title = 'Info!',
  text = 'Done.',
  buttons,
}) => ({
  type: SUCCESS_MESSAGE_ON,
  title,
  text,
  buttons,
});

export const showQuestion = ({
  title = 'Info!',
  text = 'Done.',
  buttons,
}) => ({
  type: QUESTION_MESSAGE_ON,
  title,
  text,
  buttons,
});
