import { storageKeys } from './constant';

const localStr = () => {
  const l = localStorage.getItem(storageKeys.langCode);
  if (l === 'en') {
    return enLang;
  }
  if (l === 'tr') {
    return trLang;
  }
  const userLang = navigator.language || navigator.userLanguage;
  if (String(userLang).toLowerCase().includes('tr')) {
    localStorage.setItem(storageKeys.langCode, 'tr');
    return trLang;
  }
  localStorage.setItem(storageKeys.langCode, 'en');
  return enLang;
};

const enLang = {
  sideMenu: {
    t1: 'Name Surname',
    t2: 'Congratulations',
    t3: 'Company Name',
    t4: 'Email Address',
    t5: 'Phone Number',
    t6: 'EVC WinOLS Account Number',
    t7: 'Save Changes',
    t8: 'Warning!',
    t9: 'The passwords you entered do not match!',
    t11: 'Billing Type',
    t12: 'ID Number',
    t13: 'Tax Office',
    t14: 'Tax ID',
    t15: 'Country',
    t16: 'Province',
    t17: 'District',
    t18: 'Post Code',
    t19: 'Billing Address',
    t20: 'Old Password',
    t21: 'New Password - 6 Characters',
    t22: 'Confirm New Password - 6 Characters',
    t23: 'Update Password',
    t24: 'Terms of Service',
    t26: 'Log Out',
    t27: 'Are you sure you want to log out?',
    t28: 'Yes',
    t29: 'Cancel',
    t30: 'Welcome,',
    t31: 'My Profile',
    t32: 'Update account details',
    t33: 'Billing Information',
    t34: 'Update billing details',
    t35: 'Password',
    t36: 'Change your password',
    t37: 'Settings',
    t38: 'Change portal settings',
    t39: 'Log Out',
    t40: 'Logout the portal',
    t41: 'Notification with',
    t42: '“email”',
    t43: 'Preferred Currency',
    t44: 'Information Details',
    t45: 'Billing Information',
    t46: 'Update Passwords',
    t47: 'Settings',
    t48: 'Language',
  },
  header: {
    t1: 'New Update',
    t2: 'Go to File',
    t3: 'Cancel',
    t4: 'Today',
    t5: 'File service :',
    t6: 'ONLINE',
    t7: 'OFFLINE',
    t8: 'Welcome,',
    t9: 'until',
  },
  navbar: {
    t1: 'Dashboard',
    t2: 'Files',
    t3: 'Upload',
    t4: 'Applicability',
    t5: 'Vehicle Data',
    t6: 'Invoices',
    t7: 'DTC Search',
    t8: 'Bosch Search',
    t9: 'FILE SERVICE',
    t10: 'TOOLS',
  },
  main: {
    t1: 'Today',
    t2: ' / Files',
    t3: 'This Week',
    t4: 'This Month',
    t5: 'View Car Applicability',
    t6: 'See our applicability for different tuning solutions and options.',
    t7: 'View applicability',
    t8: 'Bosch ECU Search',
    t9: 'Search Bosch part number to get ECU type.',
    t10: 'Search ECU Type',
    t11: 'DTC Search',
    t12: 'Search our database for the meanings of P,U,B,C & DF codes.',
    t13: 'Search DTCs to find their descriptions',
    t14: 'CODE',
    t15: 'DESCRIPTION',
    t16: 'Could not find any result.',
    t17: 'Showing',
    t18: 'of',
  },
  files: {
    t1: 'ALL',
    t2: 'Files',
    t3: 'You can view your files in this area and search as you wish.',
    t4: 'Search ID, Vehicle or Plate',
    t5: 'ID',
    t6: 'DATE',
    t7: 'VEHICLE',
    t8: 'PLATE',
    t9: 'INVOICE',
    t10: 'STATUS',
    t11: 'ACTION',
    t12: 'You do not have a file that you have processed before.',
    t13: 'Showing',
    t14: 'of',
    t15: 'Waiting',
  },
  fileDetail: {
    t1: 'Get Support About Your File',
    t2: 'You can get support for your file and ask any questions you may have here.',
    t3: 'Remove',
    t4: 'Drag a file here or',
    t5: 'browse',
    t6: 'a file to upload',
    t8: 'Enter your support request here.',
    t9: 'Status:',
    t10: 'Invoice:',
    t11: 'Brand:',
    t12: 'Model:',
    t13: 'Year:',
    t14: 'Engine Type:',
    t15: 'Plate:',
    t16: 'Transmission:',
    t17: 'Tool Type:',
    t18: 'File Type:',
    t19: 'Tool Used:',
    t20: 'ECU:',
    t21: 'Note:',
    t22: 'Options:',
    t23: 'Total Price',
    t24: 'Original File',
    t25: 'Mod File',
    t26: 'Support History',
    t27: 'You can see your support history in this area.',
    t28: 'Download',
    t29: 'Send',
    t30: 'invoice still waiting to be uploaded.',
  },
  uploadDetail: {
    t1: 'Vehicle Detail & File',
    t2: 'Select Stage & Options',
    t3: 'Billing Information',
    t4: 'Upload File',
    t5: 'Remove',
    t6: 'Drag a file here or',
    t7: 'browse',
    t8: 'a file to upload',
    t9: 'File Type',
    t10: 'ECU File',
    t11: 'TCU File',
    t12: 'Select Vehicle',
    t13: 'Select Vehicle Type',
    t14: 'Select Manufacturer',
    t15: 'Select Model',
    t16: 'Select Build Year',
    t17: 'Select Engine',
    t18: 'Enter Plate',
    t19: 'Please enter the license plate correctly to avoid confusion in your files.',
    t20: 'Vehicle Info',
    t21: 'Enter ECU Type',
    t22: 'Enter Your Tool',
    t23: 'Transmission:',
    t24: 'Tool Type:',
    t25: 'Note',
    t26: 'I hereby declare that I’m a professional.',
    t27: 'Next Step',
    t28: 'ECU\'s:',
    t29: 'Tools:',
  },
  uploadOptions: {
    t1: 'Vehicle Detail & File',
    t2: 'Select Stage & Options',
    t3: 'Billing Information',
    t4: 'Solution',
    t5: 'Select Free Options',
    t6: 'Optional',
    t7: 'Select Paid Options',
    t8: 'Price',
    t9: 'Total Price',
    t10: 'You don’t have any selections yet.',
    t11: 'Coupon Code',
    t12: 'Previous',
    t13: 'Next Step',
    t14: 'Enter your coupon code',
  },
  billingInformation: {
    t1: 'Vehicle Detail & File',
    t2: 'Select Stage & Options',
    t3: 'Billing Information',
    t4: 'Billing Type',
    t5: 'Name Surname',
    t6: 'ID Number',
    t7: 'Company Name',
    t8: 'Tax Office',
    t9: 'Tax ID',
    t10: 'Email Address',
    t11: 'Phone Number',
    t12: 'Country',
    t13: 'Province',
    t14: 'District',
    t15: 'Post Code',
    t16: 'Billing Address',
    t17: 'Price',
    t18: 'Total Price',
    t19: 'You don’t have any selections yet.',
    t20: 'Previous',
    t21: 'Save & Order',
    t22: 'Payment Success',
    t23: 'Your file sent successfully, you can track from \'Files\' page.',
  },
  pricing: {
    t1: 'File Pricing',
    t2: 'Select vehicle & process type and see all our pricing.',
    t3: 'Select Vehicle Type',
    t4: 'Select Manufacturer',
    t5: 'Select Model',
    t6: 'Select Build Year',
    t7: 'Select Engine',
    t8: 'Select Free Options',
    t9: 'Optional',
    t10: 'Select Paid Options',
    t11: 'Price',
    t12: 'Total Price',
    t13: 'You don’t have any selections yet.',
  },
  vehicleData: {
    t1: 'Vehicle Performance Lookup',
    t2: 'Search our database for estimated gains.',
    t3: 'Average Increase',
    t4: 'Power:',
    t5: 'Torque:',
    t6: 'Known Read Methods',
    t7: 'Check your tool’s compatibility:',
    t8: 'Search Again',
    t9: 'Vehicle Performance Lookup',
    t10: 'Search our database for estimated gains.',
    t11: 'Vehicle Search',
    t12: 'Select Vehicle Type',
    t13: 'Select Manufacturer',
    t14: 'Select Model',
    t15: 'Select Build Year',
    t16: 'Select Engine',
    t17: 'View Tuning Options',
    t18: 'Options',
  },
  invoices: {
    t1: 'Invoices',
    t2: 'See and download your invoices.',
    t3: 'Search ID, Vehicle or Plate',
    t4: 'ID',
    t5: 'DATE',
    t6: 'TOTAL',
    t7: 'VEHICLE',
    t8: 'PLATE',
    t9: 'INVOICE STATUS',
    t10: 'You do not have a invoice yet.',
    t11: 'Waiting',
    t12: 'Showing',
    t13: 'of',
  },
  dtc: {
    t1: 'DTC Search',
    t2: 'Search our database for the meanings of P,U,B,C & DF codes.',
    t3: 'Search DTCs to find their descriptions',
    t4: 'CODE',
    t5: 'DESCRIPTION',
    t6: 'Could not find any result.',
    t7: 'Showing',
    t8: 'of',
  },
  bosch: {
    t1: 'How to Find Your ECU?',
    t2: 'Close',
    t3: 'Bosch Search',
    t4: 'Search Bosch ECUs to find the type from the manufacturer number.',
    t5: 'How to Find Your ECU?',
    t6: 'Enter 3 or more character for search',
    t7: 'TYPE',
    t8: 'MANUFACTURER NUMBER',
    t9: 'CAR MANUFACTURER',
    t10: 'UPLOAD NEW FILE',
    t11: 'Could not find any result.',
    t12: 'UPLOAD FILE',
    t13: 'Showing',
    t14: 'of',
  },
  login: {
    t1: 'Sign Up',
    t2: 'Login to your Account',
    t3: 'Please enter your informations',
    t4: 'Email Address',
    t5: 'Password',
    t6: 'Remember Me',
    t7: 'Forgot Password?',
    t8: 'Login',
  },
  register: {
    t1: 'Login',
    t2: 'Join Our Family',
    t3: 'Please enter your informations',
    t4: 'Name Surname',
    t5: 'Email Address',
    t6: 'Company Name',
    t7: 'Phone Number',
    t8: 'Password',
    t9: 'Re-Password',
    t10: 'I accepted',
    t11: 'Terms of Service',
    t12: 'applied.',
    t13: 'Sign Up',
  },
  registerSubmit: {
    t1: 'Verify Your Email Address',
    t2: 'Your single-use password required for login will be sent to your registered e-mail address',
    t3: 'Resend Verification Code',
    t4: 'Continue',
  },
  forgotPassword: {
    t1: 'Forgot Password?',
    t2: 'To reset your password, please enter your e-mail address registered in the system.',
    t3: 'Email Address',
    t4: 'Send Reset Link',
    t5: 'Terms of Service',
    t6: 'applied.',
  },
  checkMail: {
    t1: 'Check Your Mail Box',
    t2: 'We sent a link to the address',
    t3: 'registered in the system. You can reset your password using the link.',
    t4: 'Terms of Service',
    t5: 'applied.',
  },
  resetPassword: {
    t1: 'Login',
    t2: 'Reset Password',
    t3: 'Please enter your new password.',
    t4: 'Password',
    t5: 'Re Password',
    t6: 'Save Password',
    t7: 'Terms of Service',
    t8: 'applied.',
  },
  passwordSaved: {
    t1: 'Congratulations!',
    t2: 'Congratulations, your password has been updated successfully. You can login using your new password.',
    t3: 'Login',
    t4: 'Terms of Service',
    t5: 'applied.',
  },
  helper: {
    t1: 'Text copied successfully',
    t2: 'Failed to copy: ',
  },
  connectionManager: {
    t1: 'An error occurred.',
    t2: 'Token expired.',
  },
  payment: {
    t1: 'Payment Failed',
    t2: 'A problem occurred during the payment phase. Please try again.',
    t3: 'Payment Success',
    t4: 'Your file sent successfully, you can track from \'Files\' page.',
  },
};
const trLang = {
  sideMenu: {
    t1: 'Ad Soyad',
    t2: 'Tebrikler',
    t3: 'Firma İsmi',
    t4: 'E-Posta Adresi',
    t5: 'Telefon',
    t6: 'EVC WinOLS Numarası',
    t7: 'Değişiklikleri Kaydet',
    t8: 'Uyarı!',
    t9: 'Girdiğiniz şifreler eşleşmiyor!',
    t11: 'Fatura Tipi',
    t12: 'Kimlik numarası',
    t13: 'Vergi Dairesi',
    t14: 'Vergi Numarası',
    t15: 'Ülke',
    t16: 'Şehir',
    t17: 'İlçe',
    t18: 'Posta Kodu',
    t19: 'Fatura Adresi',
    t20: 'Eski şifre',
    t21: 'Yeni Şifre - 6 Karakter',
    t22: 'Yeni Şifre Tekrar - 6 Karakter',
    t23: 'Şifreyi Güncelle',
    t24: 'Kullanım Şartları',
    t26: 'Çıkış Yap',
    t27: 'Çıkış yapmak istediğinize emin misiniz?',
    t28: 'Evet',
    t29: 'Vazgeç',
    t30: 'Hoşgeldin,',
    t31: 'Hesabım',
    t32: 'Profil bilgilerini güncelle',
    t33: 'Fatura bilgileri',
    t34: 'Fatura bilgilerini güncelle',
    t35: 'Şifre',
    t36: 'Şifre değiştir',
    t37: 'Ayarlar',
    t38: 'Panel ayarlarını güncelle',
    t39: 'Çıkış Yap',
    t40: 'Panelden çıkış yap',
    t41: 'Bildirim açık',
    t42: '“e-posta”',
    t43: 'Döviz Kuru',
    t44: 'Bilgilendirme Detayı',
    t45: 'Fatura Bilgileri',
    t46: 'Şifre Güncelle',
    t47: 'Ayarlar',
    t48: 'Dil',
  },
  header: {
    t1: 'Yeni Güncelleme',
    t2: 'Dosyaya Git',
    t3: 'Vazgeç',
    t4: 'Bugün',
    t5: 'Dosya Servisi :',
    t6: 'AÇIK',
    t7: 'KAPALI',
    t8: 'Hoşgeldin,',
    t9: 'şu zamana kadar',
  },
  navbar: {
    t1: 'Anasayfa',
    t2: 'Dosyalar',
    t3: 'Dosya Yükleme',
    t4: 'Uygulanabilirlik',
    t5: 'Araç Verileri',
    t6: 'Faturalar',
    t7: 'DTC Arama',
    t8: 'Bosch Arama',
    t9: 'DOSYA SERVİSİ',
    t10: 'ARAÇLAR',
  },
  main: {
    t1: 'Bugün',
    t2: ' / Dosya',
    t3: 'Bu Hafta',
    t4: 'Bu Ay',
    t5: 'Uygulanabilirliği Görüntüleyin',
    t6: 'Farklı tuning çözüm ve seçenekleri için uygulanabilirliği görüntüleyin.',
    t7: 'Seçenekleri Gör',
    t8: 'Bosch ECU Arama',
    t9: 'ECU tipini öğrenmek için Bosch parça numarasını arayın.',
    t10: 'ECU Tipini Arayın',
    t11: 'DTC Arama',
    t12: 'P,U,B,C & DF kodlarının anlamlarını veritabanımızda arayın.',
    t13: 'Açıklamalarını bulmak için DTC\'leri arayın',
    t14: 'KOD',
    t15: 'AÇIKLAMA',
    t16: 'Herhangi bir sonuç bulunamadı.',
    t17: 'Gösterilen',
    t18: 'Toplam',
  },
  files: {
    t1: 'TÜMÜ',
    t2: 'Dosyalar',
    t3: 'Bu alanda dosyalarınızı görüntüleyebilir ve dilediğiniz gibi arama yapabilirsiniz.',
    t4: 'ID, Araç veya Plaka Ara',
    t5: 'ID',
    t6: 'TARİH',
    t7: 'ARAÇ',
    t8: 'PLAKA',
    t9: 'FATURA',
    t10: 'DURUM',
    t11: 'DETAY',
    t12: 'Henüz bir dosya işleminiz bulunmuyor.',
    t13: 'Gösterilen',
    t14: 'Toplam',
    t15: 'Beklemede',
  },
  fileDetail: {
    t1: 'Dosyanız Hakkında Destek Alın',
    t2: 'Dosyanız için destek alabilir, aklınıza takılan soruları buradan sorabilirsiniz.',
    t3: 'Sil',
    t4: 'Dosyayı buraya sürükle veya',
    t5: 'buradan',
    t6: 'dosya yükle',
    t8: 'Destek talebinizi buraya girin.',
    t9: 'Durum:',
    t10: 'Fatura:',
    t11: 'Marka:',
    t12: 'Model:',
    t13: 'Yıl:',
    t14: 'Motor Tipi:',
    t15: 'Plaka:',
    t16: 'Vites:',
    t17: 'Cihaz Tipi:',
    t18: 'Dosya Tipi:',
    t19: 'Kullanılan Cihaz:',
    t20: 'ECU:',
    t21: 'Not:',
    t22: 'Seçenekler:',
    t23: 'Toplam Tutar',
    t24: 'Orjinal Dosya',
    t25: 'Mod Dosya',
    t26: 'Destek Geçmişi',
    t27: 'Destek geçmişinizi bu alanda görebilirsiniz.',
    t28: 'İndir',
    t29: 'Gönder',
    t30: 'Fatura bekleniyor.',
  },
  uploadDetail: {
    t1: 'Araç Detayı & Dosya',
    t2: 'Stage & Seçenekler',
    t3: 'Fatura Bilgileri',
    t4: 'Dosya Yükle',
    t5: 'Sil',
    t6: 'Dosyayı buraya sürükle veya',
    t7: 'buradan',
    t8: 'dosya yükle',
    t9: 'Dosya Tipi',
    t10: 'ECU Dosyası',
    t11: 'TCU Dosyası',
    t12: 'Araç Seçimi',
    t13: 'Araç Tipi',
    t14: 'Marka',
    t15: 'Model',
    t16: 'Yıl',
    t17: 'Motor',
    t18: 'Plaka',
    t19: 'Dosyalarınızda karışıklığı önlemek için lütfen plakayı doğru girin.',
    t20: 'Araç Bilgisi',
    t21: 'ECU Tipi',
    t22: 'Okuma Cihazı',
    t23: 'Vites:',
    t24: 'Cihaz Tipi:',
    t25: 'Not',
    t26: 'Profesyonel olduğumu beyan ederim.',
    t27: 'İleri',
    t28: 'ECU:',
    t29: 'Cihaz:',
  },
  uploadOptions: {
    t1: 'Araç Detayı & Dosya',
    t2: 'Stage & Seçenekler',
    t3: 'Fatura Bilgileri',
    t4: 'Çözüm',
    t5: 'Ücretsiz Seçenekler',
    t6: 'Opsiyonel',
    t7: 'Ücretli Seçenekler',
    t8: 'Tutar',
    t9: 'Toplam Tutar',
    t10: 'Henüz bir seçim yapmadınız.',
    t11: 'Kupon Kodu',
    t12: 'Geri',
    t13: 'İleri',
    t14: 'Kupon kodunu giriniz',
  },
  billingInformation: {
    t1: 'Araç Detayı & Dosya',
    t2: 'Stage & Seçenekler',
    t3: 'Fatura Bilgileri',
    t4: 'Fatura Tipi',
    t5: 'Ad Soyad',
    t6: 'Kimlik Numarası',
    t7: 'Firma ismi',
    t8: 'Vergi Dairesi',
    t9: 'Vergi Numarası',
    t10: 'E-Posta Adresi',
    t11: 'Telefon',
    t12: 'Ülke',
    t13: 'Şehir',
    t14: 'İlçe',
    t15: 'Posta Kodu',
    t16: 'Fatura Adresi',
    t17: 'Tutar',
    t18: 'Toplam Tutar',
    t19: 'Henüz bir seçim yapmadınız.',
    t20: 'Geri',
    t21: 'Kaydet & Öde',
    t22: 'Ödeme Başarılı',
    t23: 'Talebiniz başarıyla iletildi, dosyanızın durumunu \'Dosyalar\' sayfasından takip edebilirsiniz.',
  },
  pricing: {
    t1: 'Dosya Fiyatlandırması',
    t2: 'Araç ve çözüm tipini seçin ve tüm fiyatlandırmalarımızı görün.',
    t3: 'Araç Tipi',
    t4: 'Marka',
    t5: 'Model',
    t6: 'Yıl',
    t7: 'Motor',
    t8: 'Ücretsiz Seçenekler',
    t9: 'Opsiyonel',
    t10: 'Ücretli Seçenekler',
    t11: 'Tutar',
    t12: 'Toplam Tutar',
    t13: 'Henüz bir seçim yapmadınız.',
  },
  vehicleData: {
    t1: 'Araç Performansı Görüntüleme',
    t2: 'Tahmini kazançlar için arama yapın.',
    t3: 'Ortalama Artış',
    t4: 'Güç:',
    t5: 'Tork:',
    t6: 'Bilinen Okuma Yöntemleri',
    t7: 'Cihazınızın uyumluluğunu kontrol edin:',
    t8: 'Yeniden Ara',
    t9: 'Araç Performansı Görüntüleme',
    t10: 'Tahmini kazançlar için arama yapın.',
    t11: 'Araç Arama',
    t12: 'Araç Tipi',
    t13: 'Marka',
    t14: 'Model',
    t15: 'Yıl',
    t16: 'Motor',
    t17: 'Tuning Seçeneklerini Gör',
    t18: 'Seçenekler',
  },
  invoices: {
    t1: 'Faturalar',
    t2: 'Faturalarınızı görüntüleyin ve indirin.',
    t3: 'ID, Araç veya Plaka Ara',
    t4: 'ID',
    t5: 'TARİH',
    t6: 'TOPLAM',
    t7: 'ARAÇ',
    t8: 'PLAKA',
    t9: 'FATURA DURUMU',
    t10: 'Henüz herhangi bir faturanız bulunmuyor.',
    t11: 'Beklemede',
    t12: 'Gösterilen',
    t13: 'Toplam',
  },
  dtc: {
    t1: 'DTC Arama',
    t2: 'P,U,B,C & DF kodlarının anlamlarını veritabanımızda arayın.',
    t3: 'Açıklamalarını bulmak için DTC\'leri arayın',
    t4: 'KOD',
    t5: 'AÇIKLAMA',
    t6: 'Herhangi bir sonuç bulunamadı.',
    t7: 'Gösterilen',
    t8: 'Toplam',
  },
  bosch: {
    t1: 'ECU\'nuzu Nasıl Bulabilirsiniz?',
    t2: 'Kapat',
    t3: 'Bosch Arama',
    t4: 'Üretici numarasından türü bulmak için Bosch ECU\'larını arayın.',
    t5: 'ECU\'nuzu Nasıl Bulabilirsiniz?',
    t6: 'Arama için 3 veya daha fazla karakter girin',
    t7: 'TİP',
    t8: 'ÜRETİCİ NUMARASI',
    t9: 'ARAÇ NUMARASI',
    t10: 'DOSYA YÜKLE',
    t11: 'Herhangi bir sonuç bulunamadı.',
    t12: 'DOSYA YÜKLE',
    t13: 'Gösterilen',
    t14: 'Toplam',
  },
  login: {
    t1: 'Kayıt Ol',
    t2: 'Hesabına giriş yap',
    t3: 'Oturum açma bilgilerinizi girin',
    t4: 'E-Posta Adresi',
    t5: 'Şifre',
    t6: 'Beni Hatırla',
    t7: 'Şifremi Unuttum?',
    t8: 'Oturum Aç',
  },
  register: {
    t1: 'Hesabım Var',
    t2: 'Bize Katılın',
    t3: 'Lütfen üyelik bilgilerinizi girin',
    t4: 'Ad Soyad',
    t5: 'E-Posta Adresi',
    t6: 'Firma İsmi',
    t7: 'Telefon',
    t8: 'Şifre',
    t9: 'Şifre Tekrar',
    t10: '',
    t11: 'Kullanım Sözleşmesini',
    t12: 'kabul ediyorum.',
    t13: 'Kayıt Ol',
  },
  registerSubmit: {
    t1: 'E-Posta Adresinizi Doğrulayın',
    t2: 'Giriş için gerekli olan tek kullanımlık şifreniz kayıtlı e-posta adresinize gönderilecektir',
    t3: 'Kodu Tekrar Gönder',
    t4: 'Devam Et',
  },
  forgotPassword: {
    t1: 'Şifrenizi mi unuttunuz?',
    t2: 'Şifrenizi sıfırlamak için lütfen sistemde kayıtlı e-posta adresinizi giriniz.',
    t3: 'E-Posta Adresi',
    t4: 'Sıfırlama Linki Gönder',
    t5: 'Kullanım Sözleşmesini',
    t6: 'kabul ediyorum.',
  },
  checkMail: {
    t1: 'Posta Kutunuzu Kontrol Edin',
    t2: 'Sistemde kayıtlı olan',
    t3: 'adresine şifre sıfırlama linki gönderdik. Gönderdiğimiz linki kullanarak sıfırlama yapabilirsiniz.',
    t4: 'Kullanım Sözleşmesini',
    t5: 'kabul ediyorum.',
  },
  resetPassword: {
    t1: 'Oturum Aç',
    t2: 'Şifre Sıfırla',
    t3: 'Lütfen yeni şifrenizi girin',
    t4: 'Şifre',
    t5: 'Şifre Tekrar',
    t6: 'Şifreyi Kaydet',
    t7: 'Kullanım Sözleşmesini',
    t8: 'kabul ediyorum.',
  },
  passwordSaved: {
    t1: 'Tebrikler!',
    t2: 'Tebrikler, şifreniz başarıyla değiştirildi. Yeni şifrenizi kullanarak oturum açabilirsiniz.',
    t3: 'Oturum Aç',
    t4: 'Kullanım Sözleşmesini',
    t5: 'kabul ediyorum.',
  },
  helper: {
    t1: 'Kopyalandı!',
    t2: 'Kopyalanamadı : ',
  },
  connectionManager: {
    t1: 'Bir hata oluştu.',
    t2: 'Zaman aşımı.',
  },
  payment: {
    t1: 'Ödeme Başarısız',
    t2: 'Ödeme sırasında bir sorun yaşandı, lütfen tekrar deneyin.',
    t3: 'Ödeme Başarılı',
    t4: 'Talebiniz başarıyla iletildi, dosyanızın durumunu \'Dosyalar\' sayfasından takip edebilirsiniz.',
  },
};

export { localStr };
