import React from 'react';
import styles from './status.module.css';
import icCompleted from '../../../assets/images/status/completed.svg';
import icInProgress from '../../../assets/images/status/inProgress.svg';
import icCanceled from '../../../assets/images/status/canceled.svg';
import icProcessing from '../../../assets/images/status/processing.svg';
import { storageKeys } from '../../../helpers/constant';

function Status({ type, formStyle, label }) {
  function getClass() {
    switch (type) {
      case 'completed':
        return styles.completed;
      case 'inProgress':
        return styles.inProgress;
      case 'processing':
        return styles.processing;
      case 'canceled':
        return styles.canceled;
      default:
        return styles.completed;
    }
  }
  function getText() {
    const l = localStorage.getItem(storageKeys.langCode);
    if (l === 'tr') {
      switch (type) {
        case 'completed':
          return 'Tamamlandı';
        case 'inProgress':
          return 'Beklemede';
        case 'processing':
          return 'Düzenleniyor';
        case 'canceled':
          return 'İptal';
        default:
          return 'Completed';
      }
    }
    switch (type) {
      case 'completed':
        return 'Completed';
      case 'inProgress':
        return 'In Progress';
      case 'processing':
        return 'Processing';
      case 'canceled':
        return 'Canceled';
      default:
        return 'Completed';
    }
  }
  function getIcon() {
    switch (type) {
      case 'completed':
        return icCompleted;
      case 'inProgress':
        return icInProgress;
      case 'processing':
        return icProcessing;
      case 'canceled':
        return icCanceled;
      default:
        return icCompleted;
    }
  }
  return (
    <div className={`${getClass()} ${formStyle}`}>
      <img alt="message" src={getIcon()} className="m-r-2" />
      {label || getText()}
    </div>
  );
}

export default Status;
