import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './billingInformation.module.css';
import { AlternateButton, PrimaryButton } from '../../../views/buttons/buttons';
import icUpload from '../../../../assets/images/upload/billing.svg';
import {
  GeneralDropdown, GeneralInput, GeneralTextArea,
} from '../../../views/inputs/inputs';
import { apiEndpoints, billingTypeEnums, billingTypes } from '../../../../helpers/constant';
import { onlyNumberFormat } from '../../../../utils/formatter';
import { ServiceRequest } from '../../../../helpers/connectionManager';
import countries from '../../../../assets/region/country.json';
import states from '../../../../assets/region/state.json';
import cities from '../../../../assets/region/city.json';
import { getPrices } from '../../../../utils/optionFuncs';
import PaymentFrame from '../../../views/paymentFrame/paymentFrame';
import { localStr } from '../../../../helpers/language';
import { showSuccess } from '../../../../context/actions/popUpActions';
import { MainContext } from '../../../../context/store';
import { showAmount } from '../../../../core/config';

function BillingInformation({ data, navigatePrevious = function () {} }) {
  const { dispatch } = useContext(MainContext);
  if (data === null) {
    return <div />;
  }
  // billing information
  const [billingType, setBillingType] = useState(billingTypes()[0]);
  //------------------
  const [billingFullName, setBillingFullName] = useState('');
  const [billingId, setBillingId] = useState('');
  //------------------
  const [billingCompanyName, setBillingCompanyName] = useState('');
  const [billingTaxOffice, setBillingTaxOffice] = useState('');
  const [billingTaxId, setBillingTaxId] = useState('');
  //------------------
  const [billingEmail, setBillingEmail] = useState('');
  const [billingPhone, setBillingPhone] = useState('');
  const [billingCountry, setBillingCountry] = useState(null);
  const [billingProvince, setBillingProvince] = useState(null);
  const [billingDistrict, setBillingDistrict] = useState(null);
  const [billingPostCode, setBillingPostCode] = useState('');
  const [billingAddress, setBillingAddress] = useState('');

  //------------------
  const [payment, setPayment] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    getBillingInformation();
  }, []);
  function getBillingInformation() {
    ServiceRequest({
      endpoint: apiEndpoints.customer.getBillingInformation,
      loading: true,
      errorMessage: false,
    })
      .then((res) => {
        setBillingInformationData(res.data);
      })
      .catch((error) => { setBillingType(billingTypes()[0]); });
  }
  function setBillingInformationData(data) {
    setBillingType(billingTypes().filter((e) => e.key === data?.billingType)[0]);
    setBillingFullName(data?.fullName);
    setBillingId(data?.idNumber);
    setBillingCompanyName(data?.companyName);
    setBillingTaxOffice(data?.taxOffice);
    setBillingTaxId(data?.taxId);
    setBillingEmail(data?.email);
    setBillingPhone(data?.phone);
    setBillingCountry(countries.filter((e) => e.key === data?.country)[0]);
    setBillingProvince(states.filter((e) => e.key === data?.province)[0]);
    setBillingDistrict(cities.filter((e) => e.key === data?.district)[0]);
    setBillingPostCode(data?.postCode);
    setBillingAddress(data?.billingAddress);
  }
  function checkBillingSaveAvailable() {
    if (billingType?.key === billingTypeEnums.individual) {
      if (billingFullName === '' || billingId === '') {
        return true;
      }
    } else if (billingType?.key === billingTypeEnums.company) {
      if (billingCompanyName === '' || billingTaxOffice === '' || billingTaxId === '') {
        return true;
      }
    }
    return billingEmail === '' || billingPhone === '' || billingCountry === null || billingProvince === null || billingDistrict === null || billingPostCode === ''
        || billingAddress === '';
  }
  function updateBillingInformation() {
    const body = {
      billingType: billingType?.key,
      fullName: billingFullName,
      idNumber: billingId,
      companyName: billingCompanyName,
      taxOffice: billingTaxOffice,
      taxId: billingTaxId,
      email: billingEmail,
      phone: billingPhone,
      country: billingCountry?.key,
      province: billingProvince?.key,
      district: billingDistrict?.key,
      postCode: billingPostCode,
      billingAddress,
    };
    ServiceRequest({
      endpoint: apiEndpoints.customer.updateBillingInformation,
      loading: true,
      errorMessage: true,
      method: 'POST',
      body,
    })
      .then((res) => {
        setBillingInformationData(res.data);
        createOrder();
      })
      .catch((error) => {});
  }
  function createOrder() {
    const body = {
      vehicleData: data.vehicleData,
      solutionId: data.solutionId,
      options: data.options,
      plate: data.plate,
      ecuType: data.ecuType,
      tool: data.tool,
      transmission: data.transmission,
      toolType: data.toolType,
      fileType: data.fileType,
      file: data.file,
      coupon: data.coupon,
      brandName: data.brandName,
      modelName: data.modelName,
      generationName: data.generationName,
      engineName: data.engineName,
      orderNote: data.orderNote,
    };
    ServiceRequest({
      endpoint: apiEndpoints.customer.createOrder,
      loading: true,
      method: 'POST',
      body,
    })
      .then((res) => {
        if (res.data?.paymentDisable) {
          dispatch.popUpDispatch(showSuccess({ title: localStr()?.billingInformation?.t22, text: localStr()?.billingInformation?.t23 }));
          navigate('/files');
        } else {
          setPayment({
            url: res.data?.url,
            orderId: res?.data?.orderId,
            paymentProvider: res?.data?.paymentProvider,
            token: res?.data?.token,
          });
        }
      })
      .catch((error) => { });
  }

  return (
    <div className={styles.main}>
      {payment?.url && payment?.orderId && payment.url && (
      <PaymentFrame
        toggle={() => {
          setPayment(null);
        }}
        orderId={payment.orderId}
        url={payment.url}
        token={payment.token}
        paymentProvider={payment.paymentProvider}
      />
      )}
      <div className="d-flex">
        <div className={styles.c1}>
          <div className={styles.titleNumber1}>1</div>
          <div className={styles.title1}>{localStr()?.billingInformation?.t1}</div>
        </div>
        <div className={styles.c11}>
          <div className={styles.titleNumber1}>2</div>
          <div className={styles.title1}>{localStr()?.billingInformation?.t2}</div>
        </div>
        <div className={styles.c2}>
          <div className={styles.titleNumber2}>3</div>
          <div className={styles.title2}>{localStr()?.billingInformation?.t3}</div>
        </div>
      </div>
      <div className={styles.c3}>
        <div className="d-flex align-center">
          <img alt="upload" src={icUpload} />
          <div className={styles.t1}>{localStr()?.billingInformation?.t3}</div>
        </div>
        <div className="d-flex m-t-24">
          <div className={styles.cont1}>
            <div className="d-flex">
              <GeneralDropdown
                title={localStr()?.billingInformation?.t4}
                formStyle="m-r-12 f1"
                required
                data={billingTypes()}
                value={billingType}
                onChange={(e) => setBillingType(e)}
              />
              <div className="f1 m-l-12" />
            </div>
            {billingType.key === billingTypeEnums.individual
                && (
                <div className="d-flex m-t-32">
                  <GeneralInput
                    value={billingFullName}
                    onChange={(e) => setBillingFullName(e)}
                    required
                    title={localStr()?.billingInformation?.t5}
                    formStyle="m-r-12 f1"
                  />
                  <GeneralInput
                    value={billingId}
                    onChange={(e) => setBillingId(e)}
                    required
                    title={localStr()?.billingInformation?.t6}
                    formStyle="f1 m-l-12"
                  />
                </div>
                )}
            {billingType.key === billingTypeEnums.company
                && (
                <div className="d-flex f-column">
                  <div className="d-flex m-t-32">
                    <GeneralInput
                      value={billingCompanyName}
                      onChange={(e) => setBillingCompanyName(e)}
                      required
                      title={localStr()?.billingInformation?.t7}
                      formStyle="m-r-12 f1"
                    />
                    <div className="f1 m-l-12" />
                  </div>
                  <div className="d-flex m-t-32">
                    <GeneralInput
                      value={billingTaxOffice}
                      onChange={(e) => setBillingTaxOffice(e)}
                      required
                      title={localStr()?.billingInformation?.t8}
                      formStyle="m-r-12 f1"
                    />
                    <GeneralInput
                      value={billingTaxId}
                      onChange={(e) => setBillingTaxId(e)}
                      required
                      title={localStr()?.billingInformation?.t9}
                      formStyle="f1 m-l-12"
                    />
                  </div>
                </div>
                )}
            <div className="d-flex m-t-32">
              <GeneralInput
                value={billingEmail}
                onChange={(e) => setBillingEmail(e)}
                required
                title={localStr()?.billingInformation?.t10}
                formStyle="m-r-12 f1"
              />
              <GeneralInput
                value={billingPhone}
                onChange={(e) => setBillingPhone(onlyNumberFormat(e))}
                required
                title={localStr()?.billingInformation?.t11}
                formStyle="f1 m-l-12"
              />
            </div>
            <div className="d-flex m-t-32">
              <GeneralDropdown
                data={countries}
                title={localStr()?.billingInformation?.t12}
                formStyle="m-r-12 f1"
                required
                value={billingCountry}
                onChange={(e) => {
                  setBillingProvince(null);
                  setBillingDistrict(null);
                  setBillingCountry(e);
                }}
              />
              <GeneralDropdown
                data={states.filter((e) => e.country_id === billingCountry?.key)}
                title={localStr()?.billingInformation?.t13}
                formStyle="f1 m-l-12"
                required
                value={billingProvince}
                onChange={(e) => {
                  setBillingDistrict(null);
                  setBillingProvince(e);
                }}
              />
            </div>
            <div className="d-flex m-t-32">
              <GeneralDropdown
                data={cities.filter((e) => e.state_id === billingProvince?.key)}
                title={localStr()?.billingInformation?.t14}
                formStyle="m-r-12 f1"
                required
                value={billingDistrict}
                onChange={(e) => setBillingDistrict(e)}
              />
              <GeneralInput
                value={billingPostCode}
                onChange={(e) => setBillingPostCode(e)}
                required
                title={localStr()?.billingInformation?.t15}
                formStyle="f1 m-l-12"
              />
            </div>
            <GeneralTextArea
              value={billingAddress}
              onChange={(e) => setBillingAddress(e)}
              required
              title={localStr()?.billingInformation?.t16}
              formStyle="m-t-32"
            />
          </div>
          {showAmount && (
          <div className="d-flex f-column f1">
            <div className={styles.priceCont}>
              <div className={styles.priceCont1}>
                {localStr()?.billingInformation?.t17}
              </div>
              {getPrices([data?.selectedSolution], data?.appliedCoupon) ? (
                <div className={styles.priceCont3}>
                  {getPrices([data?.selectedSolution], data?.appliedCoupon).items.map((item, index) => (
                    <div key={index} className={styles.priceRow}>
                      {item.name}
                      <span>
                        {item.cost}
                      </span>
                    </div>
                  ))}
                  <div className={styles.priceTotal}>
                    {localStr()?.billingInformation?.t18}
                    <span>{getPrices([data?.selectedSolution], data?.appliedCoupon).totalPrice}</span>
                  </div>
                </div>
              ) : (
                <div className={styles.priceCont2}>
                  {localStr()?.billingInformation?.t19}
                </div>
              )}
            </div>
          </div>
          )}
        </div>
      </div>
      <div className="d-flex justify-space-between m-r-32 m-l-32 m-b-24">
        <AlternateButton
          buttonTitle={localStr()?.billingInformation?.t20}
          buttonStyle="w-132"
          buttonClick={() => navigatePrevious({ currentPage: 3 })}
        />
        <PrimaryButton
          isGhost={checkBillingSaveAvailable()}
          buttonTitle={localStr()?.billingInformation?.t21}
          buttonStyle="w-132"
          buttonClick={() => updateBillingInformation()}
        />
      </div>
    </div>
  );
}

export default BillingInformation;
