import React, { useContext } from 'react';
import { MainContext } from '../../../context/store';
import styles from './loading.module.css';

function Loading() {
  const { state, dispatch } = useContext(MainContext);
  return state.loadingResponse.loadingStatus ? (
    <div className={styles.back}>
      <div className={styles.loader} />
    </div>
  ) : (
    <>
    </>
  );
}
export default Loading;
