import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from './navbar.module.css';
import home1 from '../../../assets/images/menu/house-1.svg';
import home2 from '../../../assets/images/menu/house-2.svg';
import file1 from '../../../assets/images/menu/files-1.svg';
import file2 from '../../../assets/images/menu/files-2.svg';
import upload1 from '../../../assets/images/menu/upload-1.svg';
import upload2 from '../../../assets/images/menu/upload-2.svg';
import hand1 from '../../../assets/images/menu/hand-coins-1.svg';
import hand2 from '../../../assets/images/menu/hand-coins-2.svg';
import tree1 from '../../../assets/images/menu/vehicle-1.svg';
import tree2 from '../../../assets/images/menu/vehicle-2.svg';
import text1 from '../../../assets/images/menu/file-text-1.svg';
import text2 from '../../../assets/images/menu/file-text-2.svg';
import circles1 from '../../../assets/images/menu/circles-three-1.svg';
import circles2 from '../../../assets/images/menu/circles-three-2.svg';
import cpu1 from '../../../assets/images/menu/cpu-1.svg';
import cpu2 from '../../../assets/images/menu/cpu-2.svg';
import { localStr } from '../../../helpers/language';

function NavBar() {
  const menus = [
    {
      icon1: home1,
      icon2: home2,
      title: localStr()?.navbar?.t1,
      pathNames: ['/'],
      path: '/',
    },
    {
      icon1: file1,
      icon2: file2,
      title: localStr()?.navbar?.t2,
      pathNames: ['/files', '/filedetail'],
      path: '/files',
    },
    {
      icon1: upload1,
      icon2: upload2,
      title: localStr()?.navbar?.t3,
      pathNames: ['/upload'],
      path: '/upload',
    },
    {
      icon1: hand1,
      icon2: hand2,
      title: localStr()?.navbar?.t4,
      pathNames: ['/pricing'],
      path: '/pricing',
    },
    {
      icon1: tree1,
      icon2: tree2,
      title: localStr()?.navbar?.t5,
      pathNames: ['/vehicle'],
      path: '/vehicle',
    },
    {
      icon1: text1,
      icon2: text2,
      title: localStr()?.navbar?.t6,
      pathNames: ['/invoices'],
      path: '/invoices',
    },
    {
      icon1: circles1,
      icon2: circles2,
      title: localStr()?.navbar?.t7,
      pathNames: ['/dtcsearch'],
      path: '/dtcsearch',
    },
    {
      icon1: cpu1,
      icon2: cpu2,
      title: localStr()?.navbar?.t8,
      pathNames: ['/bsearch'],
      path: '/bsearch',
    },
  ];

  return (
    <div className={styles.navbar}>
      {menus.map((item, index) => (
        <div key={item.title}>
          {index === 1 && <div className={styles.t1}>{localStr()?.navbar?.t9}</div>}
          {index === 4 && <div className={styles.t1}>{localStr()?.navbar?.t10}</div>}
          <NavBarItem {...item} />
        </div>
      ))}
    </div>
  );
}
function NavBarItem({
  icon1, icon2, title, path, pathNames,
}) {
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;
  function navigatePage(path) {
    navigate(path);
  }
  return (
    <div
      className={`${styles.menuItem} ${pathNames.includes(pathname) && styles.selected}`}
      onClick={() => navigatePage(path)}
    >
      <img alt="menu" src={pathNames.includes(pathname) ? icon2 : icon1} />
      <div className="m-l-24">{title}</div>
    </div>
  );
}
export default NavBar;
