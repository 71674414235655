import { priceFormat } from './formatter';
import { currencyText } from '../core/config';

function sliceOptions(options, side) {
  const halfLength = Math.ceil(options.length / 2);
  if (options.length < 3 && side === 1) {
    return options;
  }
  if (options.length < 3 && side === 2) {
    return [];
  }
  if (side === 1) {
    return options.slice(0, halfLength);
  }

  return options.slice(halfLength, options.length);
}
function getPrices(solutionData, coupon) {
  if (!solutionData) {
    return null;
  }
  let totalPrice = 0;
  const items = [];
  const i = solutionData.findIndex((e) => e.selected === true);
  if (i > -1) {
    const selectedSolution = solutionData[i];
    items.push({ name: selectedSolution.displayName, cost: `${priceFormat(selectedSolution.cost)} ${currencyText}` });
    totalPrice += selectedSolution.cost;
    const paidOptions = selectedSolution.paidOptions.filter((e) => e.selected === true);
    const freeOptions = selectedSolution.freeOptions.filter((e) => e.selected === true);
    freeOptions.forEach((e, i) => {
      items.push({ name: e.optionName, cost: `0 ${currencyText}` });
      totalPrice += e.cost;
    });
    paidOptions.forEach((e, i) => {
      items.push({ name: e.optionName, cost: `${priceFormat(e.cost)} ${currencyText}` });
      totalPrice += e.cost;
    });
    if (coupon) {
      totalPrice = Math.round(totalPrice * (1 - (coupon.rate / 100)));
      items.push({ name: 'Discount', cost: `${coupon.rate} %` });
    }
    return { items, totalPrice: `${priceFormat(totalPrice)} ${currencyText}` };
  }
  return null;
}

export {
  sliceOptions, getPrices,
};
