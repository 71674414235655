import React, { useEffect, useState } from 'react';
import styles from './pricing.module.css';
import BaseView from '../../views/baseView/baseView';
import { CheckBox, GeneralDropdown, RadioButton } from '../../views/inputs/inputs';
import icArrow1 from '../../../assets/images/collapsibleArrow1.svg';
import icArrow2 from '../../../assets/images/collapsibleArrow2.svg';
import { getPrices, sliceOptions } from '../../../utils/optionFuncs';
import { ServiceRequest, VehicleServiceRequest } from '../../../helpers/connectionManager';
import { apiEndpoints, optionTypeEnums } from '../../../helpers/constant';
import { priceFormat } from '../../../utils/formatter';
import { localStr } from '../../../helpers/language';
import { currencyText, showAmount } from '../../../core/config';

function Pricing() {
  const [vehicleTypes, setVehicleTypes] = useState([]);
  const [selectedVehicleType, setSelectedVehicleType] = useState(null);
  const [manufacturerList, setManufacturerList] = useState([]);
  const [selectedManufacturer, setSelectedManufacturer] = useState(null);
  const [modelList, setModelList] = useState([]);
  const [selectedModel, setSelectedModel] = useState(null);
  const [yearList, setYearList] = useState([]);
  const [selectedYear, setSelectedYear] = useState(null);
  const [engineList, setEngineList] = useState([]);
  const [selectedEngine, setSelectedEngine] = useState(null);
  const [selectedVehicleData, setSelectedVehicleData] = useState(null);
  const [freeOptionsOpen, setFreeOptionsOpen] = useState(false);
  const [paidOptionsOpen, setPaidOptionsOpen] = useState(false);
  const [solutions, setSolutions] = useState([]);
  useEffect(() => {
    getVehicleTypes();
  }, []);
  useEffect(() => {
    if (selectedVehicleData !== null) {
      getVehicleSolutions();
    }
  }, [selectedVehicleData]);
  function getVehicleSolutions() {
    const body = {
      engineId: selectedVehicleData?.parent?.id,
    };
    ServiceRequest({
      endpoint: apiEndpoints.customer.getVehicleSolutions,
      loading: true,
      method: 'POST',
      body,
    })
      .then((res) => {
        const allData = res?.data.map((solution) => {
          const paidOptions = solution?.tuningOptions?.filter((e) => e.type === optionTypeEnums.paid && e[String(selectedVehicleData?.parent?.fuel).toLowerCase()]);
          const freeOptions = solution?.tuningOptions?.filter((e) => e.type === optionTypeEnums.free && e[String(selectedVehicleData?.parent?.fuel).toLowerCase()]);
          return { ...solution, paidOptions, freeOptions };
        });
        setSolutions(allData);
      })
      .catch((error) => { });
  }
  function getVehicleTypes() {
    VehicleServiceRequest({
      endpoint: apiEndpoints.vehicle.getVehicleTypes,
      loading: true,
    })
      .then((res) => {
        const typeList = res?.data?.map((e) => ({ key: e.type, value: e.label }));
        setVehicleTypes(typeList);
      })
      .catch((err) => {
      });
  }
  function getManufacturerList(type) {
    setSelectedVehicleType(type);
    setSelectedManufacturer(null);
    setSelectedModel(null);
    setSelectedYear(null);
    setSelectedEngine(null);
    setSelectedVehicleData(null);
    setModelList([]);
    setYearList([]);
    setEngineList([]);
    const body = {
      vehicleTypes: [type.key],
    };
    VehicleServiceRequest({
      endpoint: apiEndpoints.vehicle.getBrandsForTypes,
      loading: true,
      body,
      method: 'POST',
    })
      .then((res) => {
        const typeList = res?.data?.map((e) => ({ key: e.id, value: e.name, item: e }));
        setManufacturerList(typeList);
      })
      .catch((err) => {
      });
  }
  function getModelList(manufecturer) {
    setSelectedManufacturer(manufecturer);
    setSelectedModel(null);
    setSelectedYear(null);
    setSelectedEngine(null);
    setSelectedVehicleData(null);
    setYearList([]);
    setEngineList([]);
    const body = {
      brandIds: [String(manufecturer.key)],
    };
    VehicleServiceRequest({
      endpoint: apiEndpoints.vehicle.getModelsForBrands,
      loading: true,
      body,
      method: 'POST',
    })
      .then((res) => {
        const list = res?.data?.map((e) => ({ key: e.id, value: e.name, item: e }));
        setModelList(list);
      })
      .catch((err) => {
      });
  }
  function getYearList(model) {
    setSelectedModel(model);
    setSelectedYear(null);
    setSelectedEngine(null);
    setSelectedVehicleData(null);
    setEngineList([]);
    const body = {
      modelIds: [String(model.key)],
    };
    VehicleServiceRequest({
      endpoint: apiEndpoints.vehicle.getGenerationsForModels,
      loading: true,
      body,
      method: 'POST',
    })
      .then((res) => {
        const list = res?.data?.map((e) => ({ key: e.id, value: e.name, item: e }));
        setYearList(list);
      })
      .catch((err) => {
      });
  }
  function getEngineList(year) {
    setSelectedYear(year);
    setSelectedEngine(null);
    setSelectedVehicleData(null);
    const body = {
      generationIds: [String(year.key)],
    };
    VehicleServiceRequest({
      endpoint: apiEndpoints.vehicle.getEnginesForGenerations,
      loading: true,
      body,
      method: 'POST',
    })
      .then((res) => {
        const list = res?.data?.map((e) => ({ key: e.id, value: e.name, item: e }));
        setEngineList(list);
      })
      .catch((err) => {
      });
  }
  function getVehicleData(engine) {
    setSelectedEngine(engine);
    const body = {
      enginIds: [String(engine.key)],
    };
    VehicleServiceRequest({
      endpoint: apiEndpoints.vehicle.getVehicleDatasForEngines,
      loading: true,
      body,
      method: 'POST',
    })
      .then((res) => {
        setSelectedVehicleData(res.data);
      })
      .catch((err) => {
      });
  }
  function handleOptionSelect(option, solutionId) {
    const allData = [...solutions];
    switch (option?.type) {
      case optionTypeEnums.paid: {
        const index = allData.findIndex((e) => e.id === solutionId);
        if (index > -1) {
          const solution = allData[index];
          solution.paidOptions = solution.paidOptions.map((e) => ({ ...e, selected: e.id === option.id ? !e.selected : e.selected }));
          allData[index] = solution;
          setSolutions(allData);
        }
      }
        break;
      case optionTypeEnums.free: {
        const index = allData.findIndex((e) => e.id === solutionId);
        if (index > -1) {
          const solution = allData[index];
          solution.freeOptions = solution.freeOptions.map((e) => ({ ...e, selected: e.id === option.id ? !e.selected : e.selected }));
          allData[index] = solution;
          setSolutions(allData);
        }
      }
        break;
      default:
        break;
    }
  }
  function handleSolutionSelect(id) {
    setFreeOptionsOpen(false);
    setPaidOptionsOpen(false);
    const arr = solutions.map((e) => ({ ...e, selected: e.id === id }));
    setSolutions(arr);
  }
  return (
    <BaseView path="/ Dashboard / Pricing">
      <div className={styles.main}>
        <div className={styles.cont1}>
          <div className={styles.titleCont}>
            <div className={styles.t1}>{localStr()?.pricing?.t1}</div>
            <div className={styles.t2}>
              {localStr()?.pricing?.t2}
            </div>
          </div>
          <div className={styles.overArea}>
            <div className="d-flex f-column m-t-24">
              <GeneralDropdown
                title={localStr()?.pricing?.t3}
                required
                data={vehicleTypes}
                value={selectedVehicleType}
                onChange={(e) => getManufacturerList(e)}
              />
              <GeneralDropdown
                title={localStr()?.pricing?.t4}
                required
                formStyle="m-t-16"
                data={manufacturerList}
                value={selectedManufacturer}
                onChange={(e) => getModelList(e)}
              />
              <GeneralDropdown
                title={localStr()?.pricing?.t5}
                required
                formStyle="m-t-16"
                data={modelList}
                value={selectedModel}
                onChange={(e) => getYearList(e)}
              />
              <GeneralDropdown
                title={localStr()?.pricing?.t6}
                required
                formStyle="m-t-16"
                data={yearList}
                value={selectedYear}
                onChange={(e) => getEngineList(e)}
              />
              <GeneralDropdown
                title={localStr()?.pricing?.t7}
                required
                formStyle="m-t-16"
                data={engineList}
                value={selectedEngine}
                onChange={(e) => getVehicleData(e)}
              />
            </div>
            <div className={styles.cont10}>
              {solutions.map((item, index) => (
                <div key={index} className={`${`${styles.cont11} ${item.selected && styles.selected}`} ${index % 2 === 0 && styles.dark}`}>
                  <div className="d-flex align-center">
                    <RadioButton checked={item.selected} toggle={() => handleSolutionSelect(item.id)} />
                    <div className={`${styles.cont11Title} ${!item.selected && styles.passive} ${item.selected && styles.selected}`}>{item.displayName}</div>
                    <div className={`${styles.cont11Price} ${!item.selected && styles.passive} ${item.selected && styles.selected}`}>
                      {priceFormat(item.cost)}
                      {' '}
                      {currencyText}
                    </div>
                  </div>
                  {item.selected && (
                    <div className="d-flex m-t-18 f-column">
                      {item.freeOptions.length > 0 && (
                      <div className={`${styles.optionsCont} ${index % 2 === 0 && styles.light}`}>
                        <div className={styles.optionsContTitle} onClick={() => setFreeOptionsOpen(!freeOptionsOpen)}>
                          <div>
                            {localStr()?.pricing?.t8}
                            {' '}
                            <span>
                              {' '}
                              (
                              {localStr()?.pricing?.t9}
                              )
                            </span>
                          </div>
                          <img alt="options" src={freeOptionsOpen ? icArrow1 : icArrow2} />
                        </div>
                        {freeOptionsOpen && (
                        <div className={styles.optionsBody}>
                          <div className={styles.optionsBody1}>
                            {sliceOptions(item.freeOptions, 1).map((e, i) => (
                              <div key={i} className={styles.optionsRow}>
                                <CheckBox checked={e.selected} toggle={() => handleOptionSelect(e, item.id)} />
                                <div
                                  className={`${styles.optionsTitle}`}
                                >
                                  {e.optionName}
                                </div>
                                <div
                                  className={`${styles.optionsPrice}`}
                                >
                                  {priceFormat(e.cost)}
                                  {' '}
                                  {currencyText}
                                </div>
                              </div>
                            ))}
                          </div>
                          <div className="d-flex f-column f1">
                            {sliceOptions(item.freeOptions, 2).map((e, i) => (
                              <div key={i} className={styles.optionsRow}>
                                <CheckBox checked={e.selected} toggle={() => handleOptionSelect(e, item.id)} />
                                <div
                                  className={`${styles.optionsTitle}`}
                                >
                                  {e.optionName}
                                </div>
                                <div
                                  className={`${styles.optionsPrice}`}
                                >
                                  {priceFormat(e.cost)}
                                  {' '}
                                  {currencyText}
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                        )}
                      </div>
                      )}
                      {item.paidOptions.length > 0 && (
                      <div className={`${styles.optionsCont} m-t-18 ${index % 2 === 0 && styles.light}`}>
                        <div className={styles.optionsContTitle} onClick={() => setPaidOptionsOpen(!paidOptionsOpen)}>
                          <div>
                            {localStr()?.pricing?.t10}
                            {' '}
                            <span>
                              {' '}
                              (
                              {localStr()?.pricing?.t9}
                              )
                            </span>
                          </div>
                          <img alt="options" src={paidOptionsOpen ? icArrow1 : icArrow2} />
                        </div>
                        {paidOptionsOpen && (
                        <div className={styles.optionsBody}>
                          <div className={styles.optionsBody1}>
                            {sliceOptions(item.paidOptions, 1).map((e, i) => (
                              <div key={i} className={styles.optionsRow}>
                                <CheckBox checked={e.selected} toggle={() => handleOptionSelect(e, item.id)} />
                                <div
                                  className={`${styles.optionsTitle}`}
                                >
                                  {e.optionName}
                                </div>
                                <div
                                  className={`${styles.optionsPrice}`}
                                >
                                  {priceFormat(e.cost)}
                                  {' '}
                                  {currencyText}
                                </div>
                              </div>
                            ))}
                          </div>
                          <div className="d-flex f-column f1">
                            {sliceOptions(item.paidOptions, 2).map((e, i) => (
                              <div key={i} className={styles.optionsRow}>
                                <CheckBox checked={e.selected} toggle={() => handleOptionSelect(e, item.id)} />
                                <div
                                  className={`${styles.optionsTitle}`}
                                >
                                  {e.optionName}
                                </div>
                                <div
                                  className={`${styles.optionsPrice}`}
                                >
                                  {priceFormat(e.cost)}
                                  {' '}
                                  {currencyText}
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                        )}
                      </div>
                      )}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
        {showAmount && (
        <div className={styles.priceCont}>
          <div className={styles.priceCont1}>
            {localStr()?.pricing?.t11}
          </div>
          {getPrices(solutions, null) ? (
            <div className={styles.priceCont3}>
              {getPrices(solutions, null).items.map((item, index) => (
                <div key={index} className={styles.priceRow}>
                  {item.name}
                  <span>
                    {item.cost}
                  </span>
                </div>
              ))}
              <div className={styles.priceTotal}>
                {localStr()?.pricing?.t12}
                <span>{getPrices(solutions, null).totalPrice}</span>
              </div>
            </div>
          ) : (
            <div className={styles.priceCont2}>
              {localStr()?.pricing?.t13}
            </div>
          )}
        </div>
        )}
      </div>
    </BaseView>
  );
}

export default Pricing;
