import React, { useEffect } from 'react';
import styles from './paymentFrame.module.css';
import { paymentProviderEnums } from '../../../helpers/constant';
import close from '../../../assets/images/menu-close.svg';

function PaymentFrame({
  url, orderId, paymentProvider, token, toggle,
}) {
  if (!url) {
    return <div />;
  }

  useEffect(() => {
    const script = document.createElement('script');
    if (paymentProvider === paymentProviderEnums.payTr) {
      script.src = 'https://www.paytr.com/js/iframeResizer.min.js';
      script.async = true;
      document.body.appendChild(script);
    }
    checkAvailability();
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  function checkAvailability() {
    if (paymentProvider === paymentProviderEnums.payTr) {
      if (window.iFrameResize) {
        window.iFrameResize({}, '#paytriframe');
      } else {
        setTimeout(() => {
          checkAvailability();
        }, 200);
      }
    }
  }
  if (paymentProvider === paymentProviderEnums.payTr) {
    return (
      <div className={styles.main}>
        <div className={styles.iframeBack}>
          <div className="c-pointer f-right m-r-12 m-t-12" onClick={() => toggle()}>
            <img alt="close" src={close} />
          </div>
        </div>
        <div className={styles.iframe}>
          <iframe
            title="Payment"
            src={url}
            id="paytriframe"
            frameBorder="0"
            scrolling="no"
            style={{ width: '100%' }}
          />
        </div>
      </div>
    );
  }
}

export default PaymentFrame;
