const storageKeys = {
  token: 'e_f_u_t',
  refreshToken: 'e_f_u_r_t',
  userId: 'e_f_u_u_i',
  user: 'e_f_u_u',
  credentials: 'e_f_u_c',
  langCode: 'e_f_u_l',
};
const weekDay = () => {
  const l = localStorage.getItem(storageKeys.langCode);
  if (l === 'tr') {
    return [
      'Pazar',
      'Pazartesi',
      'Salı',
      'Çarşamba',
      'Parşembe',
      'Cuma',
      'Cumartesi',
    ];
  }
  return [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
};
const workingDays = [
  'sundayWork',
  'mondayWork',
  'tuesdayWork',
  'wednesdayWork',
  'thursdayWork',
  'fridayWork',
  'saturdayWork',
];
const workingDaysOpen = [
  'sundayOpen',
  'mondayOpen',
  'tuesdayOpen',
  'wednesdayOpen',
  'thursdayOpen',
  'fridayOpen',
  'saturdayOpen',
];
const workingDaysClose = [
  'sundayClose',
  'mondayClose',
  'tuesdayClose',
  'wednesdayClose',
  'thursdayClose',
  'fridayClose',
  'saturdayClose',
];
const billingTypeEnums = {
  company: 'COMPANY',
  individual: 'INDIVIDUAL',
};
const billingTypes = () => {
  const l = localStorage.getItem(storageKeys.langCode);
  if (l === 'tr') {
    return [
      {
        key: 'INDIVIDUAL',
        value: 'Bireysel',
      },
      {
        key: 'COMPANY',
        value: 'Kurumsal',
      },
    ];
  }
  return [
    {
      key: 'INDIVIDUAL',
      value: 'Individual',
    },
    {
      key: 'COMPANY',
      value: 'Company',
    },
  ];
};
const orderStatusEnums = {
  CANCELED: 'canceled', INPROGRESS: 'inProgress', PROCESSING: 'processing', COMPLETED: 'completed',
};
const currencyTypes = {
  TRY: 'TL (₺)',
  EUR: 'Euro (€)',
  USD: 'Usd ($)',
};
const optionTypeEnums = {
  paid: 'PAID',
  free: 'FREE',
};
const paymentStatusEnums = {
  waiting: 'WAITING',
  paid: 'PAID',
  refund: 'REFUND',
  decline: 'DECLINE',
};
const paymentProviderEnums = {
  payTr: 'PAYTR',
};

const orderStatusList = () => {
  const l = localStorage.getItem(storageKeys.langCode);
  if (l === 'tr') {
    return [
      {
        key: 'all',
        value: 'TÜMÜ',
      },
      {
        key: 'COMPLETED',
        value: 'TAMAMLANDI',
      },
      {
        key: 'INPROGRESS',
        value: 'BEKLENİYOR',
      },
      {
        key: 'PROCESSING',
        value: 'DÜZENLENİYOR',
      },
      {
        key: 'CANCELED',
        value: 'İPTAL',
      },
    ];
  }

  return [
    {
      key: 'all',
      value: 'ALL',
    },
    {
      key: 'COMPLETED',
      value: 'COMPLETED',
    },
    {
      key: 'INPROGRESS',
      value: 'IN PROGRESS',
    },
    {
      key: 'PROCESSING',
      value: 'PROCESSING',
    },
    {
      key: 'CANCELED',
      value: 'CANCELED',
    },
  ];
};
const languageTypes = [
  {
    key: 'en',
    value: 'English',
  },
  {
    key: 'tr',
    value: 'Türkçe',
  },
];
const messageSenderEnums = {
  admin: 'ADMIN',
  customer: 'CUSTOMER',
};
const toolCheckLinks = {
  AlienTech: 'https://www.alientech-tools.com/vehicles/',
  AutoTuner: 'https://www.autotuner-tool.com/en/compatibility',
  CMDFlash: 'https://www.flashtec.ch/index.php/support',
  MagicMotorsport: 'https://www.magicmotorsport.com/flex/vehicle-and-services-list/full-vehicle-list-for-flex',
  DimSport: 'https://www.dimsport.it/en/applications-list/unique/',
  BFlash: 'https://bflash.eu',

};
const apiEndpoints = {
  merchant: {
    getMerchantCredentials: '/api/merchants/getMerchantCredentials',
  },
  auth: {
    login: '/api/auth/login',
    refresh: '/api/auth/refresh',
    sendOtpForRegister: '/api/auth/sendOtpForRegister',
    register: '/api/auth/register',
    forgotPassword: '/api/auth/forgotPassword',
    resetPassword: '/api/auth/resetPassword',
  },
  customer: {
    getCustomer: '/api/customer/getCustomer',
    getWorkingHours: '/api/customer/getWorkingHours',
    getMessages: '/api/customer/getMessages',
    updatePassword: '/api/customer/updatePassword',
    updateCustomer: '/api/customer/updateCustomer',
    getBillingInformation: '/api/customer/getBillingInformation',
    updateBillingInformation: '/api/customer/updateBillingInformation',
    getSettings: '/api/customer/getSettings',
    updateSettings: '/api/customer/updateSettings',
    uploadEcuFile: '/api/customer/uploadEcuFile',
    uploadOriginalFile: '/api/customer/uploadOriginalFile',
    getVehicleSolutions: '/api/customer/getVehicleSolutions',
    checkCoupon: '/api/customer/checkCoupon',
    createOrder: '/api/customer/createOrder',
    checkOrderPaymentStatus: '/api/customer/checkOrderPaymentStatus',
    getFiles: '/api/customer/getFiles',
    downloadOriginalFile: '/api/customer/downloadOriginalFile',
    downloadInvoice: '/api/customer/downloadInvoice',
    getOrderDetailForCustomer: '/api/customer/getOrderDetailForCustomer',
    downloadEcuFile: '/api/customer/downloadEcuFile',
    getOrderChatMessages: '/api/customer/getOrderChatMessages',
    downloadOrderChatFile: '/api/customer/downloadOrderChatFile',
    uploadChatFile: '/api/customer/uploadChatFile',
    sendOrderChatMessage: '/api/customer/sendOrderChatMessage',
    getDashboardData: '/api/customer/getDashboardData',
  },
  vehicle: {
    getVehicleTypes: localStorage.getItem(storageKeys.langCode) === 'tr' ? '/api/vehicle/getVehicleTypesTr' : '/api/vehicle/getVehicleTypes',
    getBrandsForTypes: '/api/vehicle/getBrandsForTypes',
    getModelsForBrands: '/api/vehicle/getModelsForBrands',
    getGenerationsForModels: '/api/vehicle/getGenerationsForModels',
    getEnginesForGenerations: '/api/vehicle/getEnginesForGenerations',
    getVehicleDatasForEngines: '/api/vehicle/getVehicleDatasForEngines',
  },
  ecuData: {
    getAllDtc: '/api/ecuData/getAllDtc',
    getAllBosch: '/api/ecuData/getAllBosch',
  },
};
export {
  messageSenderEnums,
  storageKeys,
  apiEndpoints,
  workingDays,
  workingDaysOpen,
  workingDaysClose,
  weekDay,
  billingTypes,
  currencyTypes,
  toolCheckLinks,
  billingTypeEnums,
  optionTypeEnums,
  paymentStatusEnums,
  orderStatusEnums,
  orderStatusList,
  paymentProviderEnums,
  languageTypes,
};
