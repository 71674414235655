import React, { useContext, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styles from './paymentSuccess.module.css';
import { ServiceRequest } from '../../../helpers/connectionManager';
import { apiEndpoints, paymentStatusEnums } from '../../../helpers/constant';
import { showError, showSuccess } from '../../../context/actions/popUpActions';
import { MainContext } from '../../../context/store';
import { localStr } from '../../../helpers/language';

function PaymentSuccess() {
  const { dispatch } = useContext(MainContext);
  const navigate = useNavigate();
  const { orderId } = useParams();
  if (!orderId) {
    dispatch.popUpDispatch(showError({ title: localStr()?.payment?.t1, text: localStr()?.payment?.t2 }));
    navigate('/');
  }
  useEffect(() => {
    checkPaymentStatus();
  }, []);
  const checkCount = useRef(0);
  function checkPaymentStatus() {
    ServiceRequest({
      endpoint: `${apiEndpoints.customer.checkOrderPaymentStatus}/${orderId}`,
      loading: true,
    })
      .then((res) => {
        if (res?.data === paymentStatusEnums.paid) {
          dispatch.popUpDispatch(showSuccess({ title: localStr()?.payment?.t3, text: localStr()?.payment?.t4 }));
          navigate('/files');
        } else if (res?.data === paymentStatusEnums.waiting) {
          if (checkCount.current < 10) {
            setTimeout(() => {
              checkCount.current += 1;
              checkPaymentStatus();
            }, 5000);
          } else {
            dispatch.popUpDispatch(showError({ title: localStr()?.payment?.t1, text: localStr()?.payment?.t2 }));
            navigate('/');
          }
        } else {
          dispatch.popUpDispatch(showError({ title: localStr()?.payment?.t1, text: localStr()?.payment?.t2 }));
          navigate('/');
        }
      })
      .catch((error) => { });
  }
  return (
    <div className={styles.back}>
      <div className={styles.loader} />
    </div>
  );
}

export default PaymentSuccess;
