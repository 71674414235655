import { LOGIN_TRUE, LOGIN_FALSE, SET_MERCHANT_NAME } from '../types';
import { debugModeOn } from '../../core/config';
import authState from '../initialStates/authState';

export default (state = authState, action) => {
  if (debugModeOn) {
    console.group('ContextReducer: ', state, action);
    console.groupEnd();
  }
  switch (action.type) {
    case LOGIN_TRUE:
      return {
        ...state,
        loginStatus: true,
      };
    case LOGIN_FALSE:
      return {
        ...state,
        loginStatus: false,
      };
    case SET_MERCHANT_NAME:
      return {
        ...state,
        merchantName: action.merchantName,
      };
    default:
      return state;
  }
};
