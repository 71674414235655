import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import { CategoryScale } from 'chart.js';
import { useNavigate } from 'react-router-dom';
import BaseView from '../../views/baseView/baseView';
import styles from './main.module.css';
import icPricingBack from '../../../assets/images/pricingImgBack.svg';
import icPricing from '../../../assets/images/pricingIcon.svg';
import arrow from '../../../assets/images/arrow1.svg';
import search from '../../../assets/images/search.svg';
import icCircles from '../../../assets/images/circles-three.svg';
import icBosch from '../../../assets/images/boschLogo.svg';
import { GhostButton, SecondaryButton } from '../../views/buttons/buttons';
import { SimpleInput } from '../../views/inputs/inputs';
import Pagination from '../../views/pagination/pagination';
import { ServiceRequest, VehicleServiceRequest } from '../../../helpers/connectionManager';
import { apiEndpoints } from '../../../helpers/constant';
import { localStr } from '../../../helpers/language';

Chart.register(CategoryScale);
function Main() {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedPage, setSelectedPage] = useState(1);
  const pageItemCount = 500;
  const todayData = {
    labels: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
    datasets: [
      {
        label: 'Days',
        data: [12, 4, 23, 20, 17, 8, 27],
        borderColor: '#F79A4E',
        backgroundColor: '#FCD3B2',
        fill: true,
        borderWidth: -5,
      },
    ],
  };
  const thisWeekData = {
    labels: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'],
    datasets: [
      {
        label: 'Days',
        data: [12, 4, 23, 20, 17, 8, 27],
        borderColor: '#604A7B',
        backgroundColor: '#BDB3C8',
        fill: true,
        borderWidth: -5,
      },
    ],
  };
  const thisMonthData = {
    labels: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'],
    datasets: [
      {
        label: 'Days',
        data: [3, 4, 2, 10, 7, 8, 7],
        borderColor: '#4F81BC',
        backgroundColor: '#B5CAE3',
        fill: true,
        borderWidth: -5,
      },
    ],
  };
  const [dashboardData, setDashboardData] = useState({
    todayCount: 0,
    thisWeekCount: 0,
    thisMonthCount: 0,
  });
  useEffect(() => {
    getDtcData();
    getDashboardData();
  }, []);

  function getDashboardData() {
    ServiceRequest({
      endpoint: apiEndpoints.customer.getDashboardData,
      loading: true,
    })
      .then((res) => {
        setDashboardData(res.data);
      })
      .catch((error) => { });
  }
  function getDtcData() {
    VehicleServiceRequest({
      endpoint: apiEndpoints.ecuData.getAllDtc,
      errorMessage: false,
      loading: true,
    })
      .then((res) => {
        setData(res.data);
        setFilteredData(res.data);
      })
      .catch((err) => {
      });
  }
  useEffect(() => {
    setSelectedPage(1);
    if (searchTerm.length < 3) {
      setFilteredData(data);
    } else {
      let allData = [...data];
      allData = allData.filter((e) => String(e.code).toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
          || String(e.description).toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1);
      setFilteredData(allData);
    }
  }, [searchTerm]);
  return (
    <BaseView path="/ Dashboard">
      <div className={styles.main}>
        <div className="d-flex justify-space-between">
          <div className={styles.cartContainer}>
            <div className={styles.cartLabel}>
              {localStr()?.main?.t1}
              <div className={styles.t1}>
                {dashboardData?.todayCount}
                <span className={styles.t2}>{localStr()?.main?.t2}</span>
              </div>
            </div>
            <Line
              className={styles.cart}
              data={todayData}
              options={options}
            />
          </div>
          <div className={styles.cartContainer}>
            <div className={styles.cartLabel}>
              {localStr()?.main?.t3}
              <div className={styles.t1}>
                {dashboardData?.thisWeekCount}
                <span className={styles.t2}>{localStr()?.main?.t2}</span>
              </div>
            </div>
            <Line
              className={styles.cart}
              data={thisWeekData}
              options={options}
            />
          </div>
          <div className={styles.cartContainer}>
            <div className={styles.cartLabel}>
              {localStr()?.main?.t4}
              <div className={styles.t1}>
                {dashboardData?.thisMonthCount}
                <span className={styles.t2}>{localStr()?.main?.t2}</span>
              </div>
            </div>
            <Line
              className={styles.cart}
              data={thisMonthData}
              options={options}
            />
          </div>
        </div>
        <div className="d-flex m-t-16">
          <div className={styles.pricingCont}>
            <img alt="pricing" src={icPricing} className={styles.icPricing} />
            <div className={styles.t3}>
              {localStr()?.main?.t5}
            </div>
            <div className={styles.t4}>
              {localStr()?.main?.t6}
            </div>
            <SecondaryButton
              buttonClick={() => navigate('/pricing')}
              buttonTitle={localStr()?.main?.t7}
              buttonStyle={styles.pricingButton}
              leftIcon={arrow}
              leftIconStyle={styles.pArrow}
            />
            <img alt="pricing" src={icPricingBack} className={styles.pricingBackImg} />
          </div>
          <div className={styles.boschCont}>
            <img alt="bosch" src={icBosch} className={styles.icBosch} />
            <div className={styles.t5}>
              {localStr()?.main?.t8}
            </div>
            <div className={styles.t6}>
              {localStr()?.main?.t9}
            </div>
            <GhostButton
              buttonClick={() => navigate('/bsearch')}
              buttonTitle={localStr()?.main?.t10}
              buttonStyle={styles.boschButton}
              leftIcon={search}
            />
          </div>
        </div>
        <div className={styles.searchCont}>
          <div className={styles.searchTitleCont}>
            <div className="d-flex f-column f1">
              <div className={styles.t7}>{localStr()?.main?.t11}</div>
              <div className={styles.t8}>{localStr()?.main?.t12}</div>
            </div>
            <img alt="circle" src={icCircles} />
          </div>
          <SimpleInput
            formStyle={styles.search}
            onChange={(e) => setSearchTerm(e)}
            value={searchTerm}
            leftIcon={search}
            placeHolder={localStr()?.main?.t13}
          />
          <table className={styles.userTable}>
            <thead>
              <tr>
                <th className="w-132">{localStr()?.main?.t14}</th>
                <th>{localStr()?.main?.t15}</th>
              </tr>
            </thead>
            <tbody>
              {filteredData.length === 0 && (
              <tr>
                <td colSpan={7} className={styles.tableEmpty}>
                  {localStr()?.main?.t16}
                </td>
              </tr>
              )}
              {filteredData.slice((selectedPage - 1) * pageItemCount, selectedPage * pageItemCount).map((item, index) => (
                <tr key={index} className={index % 2 === 1 ? styles.dark : ''}>
                  <td className="w-132">{item.code}</td>
                  <td>{item.description}</td>
                </tr>
              ))}
            </tbody>
          </table>
          {filteredData.length !== 0 && (
          <div className="d-flex m-t-24 align-center">
            <Pagination
              formStyle={styles.pagination}
              selectedPage={selectedPage}
              totalPage={parseInt(filteredData.length / pageItemCount, 10) + 1}
              onChange={(e) => setSelectedPage(e)}
            />
            {searchTerm === '' && (
            <div className={styles.t31}>
              {localStr()?.main?.t17}
              {' '}
              {(selectedPage - 1) * pageItemCount}
              {' '}
              -
              {(parseInt(filteredData.length / pageItemCount, 10) + 1) === selectedPage ? filteredData.length : selectedPage * pageItemCount}
              {' '}
              {localStr()?.main?.t18}
              {' '}
              {filteredData.length}
            </div>
            )}
          </div>
          )}
        </div>
      </div>
    </BaseView>
  );
}

const options = {
  responsive: true,
  aspectRatio: 7,
  plugins: {
    tooltip: { enabled: false },
    legend: {
      display: false,
    },
  },
  scales: {
    x: {
      display: false,
    },
    y: {
      display: false,
    },
  },
};
export default Main;
