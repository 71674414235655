import React, { useEffect, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import styles from './registerSubmit.module.css';
import close from '../../../assets/images/x-light.svg';
import poster from '../../../assets/images/loginBackPoster.png';
import otpBack from '../../../assets/images/otpImage.svg';
import back from '../../../assets/login.mp4';
import { OtpInput } from '../../views/inputs/inputs';
import { PrimaryButton } from '../../views/buttons/buttons';
import TimeCounter from '../../views/timeCounter/timeCounter';
import { ServiceRequest } from '../../../helpers/connectionManager';
import { apiEndpoints, storageKeys } from '../../../helpers/constant';
import { getSessionValue, loginUser } from '../../../helpers/sessionManager';
import { localStr } from '../../../helpers/language';

function RegisterSubmit() {
  const { state } = useLocation();
  const { bodyRegister } = state || {};
  if (!bodyRegister) {
    return <Navigate to="/login" />;
  }
  const navigate = useNavigate();
  const [merchantCredentials, setMerchantCredentials] = useState(null);
  const [otp, setOtp] = useState('');
  const [otpError, setOtpError] = useState(null);
  const [otpTime, setOtpTime] = useState(120);
  useEffect(() => {
    getCredentials();
  }, []);
  function getCredentials() {
    const credentials = getSessionValue({ key: storageKeys.credentials });
    setMerchantCredentials(credentials);
  }
  function navigateLink(link) {
    window.open(link);
  }
  function reSendOtp() {
    if (otpTime === 0) {
      const body = { email: bodyRegister.userName };
      ServiceRequest({
        endpoint: apiEndpoints.auth.sendOtpForRegister,
        loading: true,
        method: 'POST',
        successMessage: true,
        errorMessage: true,
        body,
      })
        .then((res) => {
          setOtpTime(120);
        })
        .catch(() => {});
    }
  }
  function registerUser() {
    setOtpError(null);
    const body = { ...bodyRegister, otpCode: otp };
    ServiceRequest({
      endpoint: apiEndpoints.auth.register,
      loading: true,
      method: 'POST',
      successMessage: true,
      errorMessage: false,
      body,
    })
      .then((res) => {
        loginUser({
          userId: res.data.userId,
          refreshToken: res.data.refreshToken,
          token: res.data.accessToken,
        });
      })
      .catch((error) => { setOtpError(error.message); });
  }
  return (
    <div className={styles.main}>
      <div className={styles.cont1}>
        <video autoPlay loop muted className={styles.back} poster={poster}>
          <source src={back} type="video/mp4" />
        </video>
        {merchantCredentials && (
        <div className={styles.videoLayer}>
          <img alt="ecufile" src={`data:image/png;base64,${merchantCredentials?.logoBlack}`} className={styles.logo} />
          <div className="d-flex align-center m-b-72 justify-center">
            {merchantCredentials?.infoMail && (
            <div onClick={() => navigateLink(`mailto:${merchantCredentials?.infoMail}?subject=Information`)} className={styles.footerItem}>{merchantCredentials?.infoMail}</div>
            )}
            {merchantCredentials?.facebook && (
            <div onClick={() => navigateLink(merchantCredentials?.facebook)} className={styles.footerItem}>facebook</div>
            )}
            {merchantCredentials?.instagram && (
            <div onClick={() => navigateLink(merchantCredentials?.instagram)} className={styles.footerItem}>instagram</div>
            )}
            {merchantCredentials?.linkedin && (
            <div onClick={() => navigateLink(merchantCredentials?.linkedin)} className={styles.footerItem}>linkedin</div>
            )}
            {merchantCredentials?.youtube && (
            <div onClick={() => navigateLink(merchantCredentials?.youtube)} className={styles.footerItem}>youtube</div>
            )}
            {merchantCredentials?.whatsapp && (
            <div onClick={() => navigateLink(merchantCredentials?.whatsapp)} className={styles.footerItem}>whatsapp</div>
            )}
            {merchantCredentials?.website && (
            <div onClick={() => navigateLink(merchantCredentials?.website)} className={styles.footerItem}>{merchantCredentials?.website}</div>
            )}
          </div>
        </div>
        )}
      </div>
      <div className={styles.cont2}>
        <div className={styles.close} onClick={() => navigate(-1)}>
          <img alt="close" src={close} />
        </div>
        <div className="d-flex f-column align-center">
          <img alt="otp" src={otpBack} />
          <div className={styles.t1}>{localStr()?.registerSubmit?.t1}</div>
          <div className={styles.t2}>
            {localStr()?.registerSubmit?.t2}
          </div>
          <OtpInput
            value={otp}
            onChange={(e) => setOtp(e)}
            formStyle="m-t-40"
            errorText={otpError}
          />
          <div onClick={() => reSendOtp()} className={`${styles.resend} ${otpTime === 0 && styles.active}`}>{localStr()?.registerSubmit?.t3}</div>
          {otpTime > 0 && <TimeCounter seconds={otpTime} totalSeconds={10} formStyle="m-t-32" timeUp={() => setOtpTime(0)} />}
          <PrimaryButton buttonClick={() => registerUser()} buttonTitle={localStr()?.registerSubmit?.t4} buttonStyle="w100 m-t-32" isGhost={otp.length < 6} />
        </div>
      </div>
    </div>
  );
}

export default RegisterSubmit;
