import React, { useEffect, useState } from 'react';
import styles from './timeCounter.module.css';

function TimeCounter({
  seconds, timeUp = function () {}, totalSeconds, formStyle,
}) {
  const [timeLeft, setTimeLeft] = useState(seconds);
  useEffect(() => {
    if (!timeLeft) {
      timeUp();
      return;
    }
    const intervalid = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);
    // eslint-disable-next-line consistent-return
    return () => clearInterval(intervalid);
  }, [timeLeft]);
  return (
    <div className={`${styles.circleMain} ${formStyle}`}>
      <div className={styles.circleProgress}>
        <svg viewBox="0 0 36 36">
          <path
            className={styles.circleBg}
            d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
          />
          <path
            className={styles.circle}
            strokeDasharray={`${100 - (timeLeft / totalSeconds) * 100}, 100`}
            d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
          />
        </svg>
      </div>
      <div className={styles.percentage}>{timeLeft}</div>
    </div>
  );
}

export default TimeCounter;
