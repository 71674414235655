import React from 'react';
import styles from './pagination.module.css';
import icBack1 from '../../../assets/images/arLeft1.svg';
import icBack2 from '../../../assets/images/arLeft2.svg';
import icForward1 from '../../../assets/images/arRight1.svg';
import icForward2 from '../../../assets/images/arRight2.svg';

function Pagination({
  totalPage, selectedPage, onChange = function () {}, formStyle,
}) {
  return (
    <div className={`${styles.main} ${formStyle}`}>
      <div
        className={`${styles.leftIcon} ${selectedPage === 1 && styles.passive}`}
        onClick={() => {
          if (selectedPage !== 1) {
            onChange(selectedPage - 1);
          }
        }}
      >
        <img alt="back" src={selectedPage === 1 ? icBack1 : icBack2} />
      </div>
      {new Array(totalPage).fill('*').map((item, index) => (
        <div
          onClick={() => onChange(index + 1)}
          className={`${styles.item} ${index + 1 === selectedPage && styles.selected}`}
          key={index}
        >
          {index + 1}
        </div>
      ))}
      <div
        className={`${styles.rightIcon} ${selectedPage === totalPage && styles.passive}`}
        onClick={() => {
          if (selectedPage !== totalPage) {
            onChange(selectedPage + 1);
          }
        }}
      >
        <img alt="back" src={selectedPage === totalPage ? icForward1 : icForward2} />
      </div>
    </div>
  );
}

export default Pagination;
