import React from 'react';
import styles from './baseView.module.css';
import Header from '../header/header';
import NavBar from '../navBar/navBar';
import BreadCrumb from '../breadCrump/breadCrumb';

function BaseView({ children, path }) {
  return (
    <div className={styles.baseView}>
      <Header />
      <div className="d-flex f1">
        <NavBar />
        <div className={styles.cont}>
          <BreadCrumb path={path} />
          <div className={styles.pageView}>{children}</div>
        </div>
      </div>
    </div>
  );
}
export default BaseView;
