import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styles from './resetPassword.module.css';
import poster from '../../../assets/images/loginBackPoster.png';
import back from '../../../assets/login.mp4';
import { PrimaryButton, SecondaryButton } from '../../views/buttons/buttons';
import { GeneralInput } from '../../views/inputs/inputs';
import { ServiceRequest } from '../../../helpers/connectionManager';
import { apiEndpoints, storageKeys } from '../../../helpers/constant';
import { MainContext } from '../../../context/store';
import { showToastError } from '../../../context/actions/toastActions';
import { getSessionValue } from '../../../helpers/sessionManager';
import { localStr } from '../../../helpers/language';

function ResetPassword() {
  const { state, dispatch } = useContext(MainContext);
  const [merchantCredentials, setMerchantCredentials] = useState(null);
  const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const [rePassword, setRePassword] = useState('');
  const { id } = useParams();
  if (!id) {
    navigate('/login');
  }
  function navigateLink(link) {
    window.open(link);
  }
  useEffect(() => {
    getCredentials();
  }, []);
  function getCredentials() {
    const credentials = getSessionValue({ key: storageKeys.credentials });
    setMerchantCredentials(credentials);
  }
  function resetPassword() {
    if (password !== rePassword) {
      dispatch.toastDispatch(showToastError({ text: 'The passwords you enter do not match.' }));
      return;
    }
    const body = {
      token: id,
      password,
    };
    ServiceRequest({
      endpoint: apiEndpoints.auth.resetPassword,
      loading: true,
      method: 'POST',
      body,
      successMessage: true,
    })
      .then((res) => {
        navigate('/passwordsaved');
      })
      .catch((error) => { });
  }

  return (
    <div className={styles.main}>
      <div className={styles.cont1}>
        <video autoPlay loop muted className={styles.back} poster={poster}>
          <source src={back} type="video/mp4" />
        </video>
        {merchantCredentials && (
        <div className={styles.videoLayer}>
          <img alt="ecufile" src={`data:image/png;base64,${merchantCredentials?.logoBlack}`} className={styles.logo} />
          <div className="d-flex align-center m-b-72 justify-center">
            {merchantCredentials?.infoMail && (
            <div onClick={() => navigateLink(`mailto:${merchantCredentials?.infoMail}?subject=Information`)} className={styles.footerItem}>{merchantCredentials?.infoMail}</div>
            )}
            {merchantCredentials?.facebook && (
            <div onClick={() => navigateLink(merchantCredentials?.facebook)} className={styles.footerItem}>facebook</div>
            )}
            {merchantCredentials?.instagram && (
            <div onClick={() => navigateLink(merchantCredentials?.instagram)} className={styles.footerItem}>instagram</div>
            )}
            {merchantCredentials?.linkedin && (
            <div onClick={() => navigateLink(merchantCredentials?.linkedin)} className={styles.footerItem}>linkedin</div>
            )}
            {merchantCredentials?.youtube && (
            <div onClick={() => navigateLink(merchantCredentials?.youtube)} className={styles.footerItem}>youtube</div>
            )}
            {merchantCredentials?.whatsapp && (
            <div onClick={() => navigateLink(merchantCredentials?.whatsapp)} className={styles.footerItem}>whatsapp</div>
            )}
            {merchantCredentials?.website && (
            <div onClick={() => navigateLink(merchantCredentials?.website)} className={styles.footerItem}>{merchantCredentials?.website}</div>
            )}
          </div>
        </div>
        )}
      </div>
      <div className={styles.cont2}>
        <div className="d-flex f-column align-end">
          <SecondaryButton buttonTitle={localStr()?.resetPassword?.t1} buttonStyle="w-100" buttonClick={() => navigate('/login')} />
        </div>
        <div>
          <div className={styles.title}>{localStr()?.resetPassword?.t2}</div>
          <div className={`${styles.t3} m-t-16`}>{localStr()?.resetPassword?.t3}</div>
          <GeneralInput
            value={password}
            onChange={(e) => setPassword(e)}
            title={localStr()?.resetPassword?.t4}
            formStyle="m-t-48"
            required
            maxLength={6}
            inputType="password"
          />
          <GeneralInput
            value={rePassword}
            onChange={(e) => setRePassword(e)}
            title={localStr()?.resetPassword?.t5}
            formStyle="m-t-24"
            required
            maxLength={6}
            inputType="password"
          />
          <PrimaryButton
            isGhost={password.length < 6 || rePassword.length < 6}
            buttonTitle={localStr()?.resetPassword?.t6}
            buttonStyle="m-t-48"
            buttonClick={() => resetPassword()}
          />
        </div>
        <div>
          <div className={`${styles.t3} m-t-16`}>
            <span onClick={() => { window.open('/agreement'); }}>{localStr()?.resetPassword?.t7}</span>
            {' '}
            {localStr()?.resetPassword?.t8}
          </div>
        </div>
      </div>
    </div>
  );
}
export default ResetPassword;
