/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import styles from './agreement.module.css';

function Agreement() {
  return (
    <div className={styles.agreementBody}>
      <p className="p1">Terms Of Service</p>
      <p className="p2">&nbsp;</p>
      <p className="p1">
        THE PRODUCTS OFFERED ON THIS WEBSITE ARE INTENDED FOR SALE AND USE BY PROFESSIONAL VEHICLE
        INSTRUMENTERS ONLY AND ARE NOT SUITABLE FOR NON-PROFESSIONAL PERSONS
      </p>
      <p className="p1">1. General.</p>
      <p className="p1">
        1.1. The legal relationship and each contract between EcuFileTr and the customer is governed
        exclusively by these terms and conditions, unless otherwise agreed in a written contract.
      </p>
      <p className="p1">
        1.2. The terms and conditions apply to every offer of EcuFileTr and to every distance
        contract concluded between EcuFileTr and the customer who uses or purchases tuning software/tuning files
        released exclusively for professional purposes ('Products').
      </p>
      <p className="p1">
        1.3. The terms and conditions stated on the website at the time of ordering apply to that
        order and are deemed valid.
      </p>
      <p className="p1">
        1.4. By ordering or purchasing EcuFileTr products using the website, the customer confirms
        that he has read and accepted the general terms and conditions. By purchasing and downloading, configuring
        or using the Products and their documentation, the Customer expressly accepts, in particular, the terms of
        the License Agreement, which forms a full part of these terms and conditions, and agrees to be bound by
        this License Agreement. In the event of any inconsistency between the terms and conditions and the License
        Agreement, the terms of the License Agreement will prevail.
      </p>
      <p className="p1">1.5. EcuFileTr reserves the right to change the terms and conditions at any time.</p>
      <p className="p1">
        1.6. No variation or derogation of these terms and conditions shall apply unless agreed to
        in writing by EcuFileTr. Derogations in one or more clauses of the general terms and conditions will not
        affect the applicability of other clauses of the same general terms and conditions.
      </p>
      <p className="p2">&nbsp;</p>
      <p className="p1">2. Professional customer</p>
      <p className="p1">
        2.1. Given the highly specific nature of the products, EcuFileTr only sells its B2B Products
        to professional vehicle tuners who are sufficiently educated, trained and skilled in tuning a vehicle and
        can tune a vehicle without extra knowledge, training or assistance from any third party.
      </p>
      <p className="p1">
        2.2. By ordering or purchasing EcuFileTr Products, the Customer confirms that he is a
        professional car tuner with extensive knowledge and training in tuning a vehicle.
      </p>
      <p className="p2">&nbsp;</p>
      <p className="p1">3. Information, orders, prices and availability</p>
      <p className="p1">
        3.1. Information made publicly available by EcuFileTr (e.g. through the website) is general
        in nature and subject to corrections and/or changes. This information itself is not principally adapted to
        personal or specific circumstances. EcuFileTr reserves the right to change the specifications of the
        Products at any time as it deems appropriate or necessary or as required by any authority.
      </p>
      <p className="p1">3.2. All offers and Products are subject to availability.</p>
      <p className="p1">
        3.3. All prices stated on the website are based on the current prices applied by
        EcuFileTr.
      </p>
      <p className="p1">
        3.4. All prices are in EcuFileTr's designated currency and are exclusive of VAT and other
        applicable charges.
      </p>
      <p className="p1">
        3.5. Prices may be subject to fluctuations and may be changed by EcuFileTr at any time on
        its website.
      </p>
      <p className="p1">
        3.6. The price valid at the time the order is confirmed will be valid for that order and
        will be credited to the purchasing account of the customer purchasing the Product.
      </p>
      <p className="p1">
        3.7. EcuFileTr processes orders subject to availability and within the limitations provided
        in these general terms and conditions. EcuFileTr reserves the right to refuse orders at any time for
        legitimate reasons, including but not limited to cases where EcuFileTr has a serious suspicion of abuse of
        law or abuse by the customer.
      </p>
      <p className="p1">
        3.8. Purchasing a product does not include updates made by EcuFileTr to the product. If the
        customer wishes to receive an update to their software, a new order must be placed and payment made on the
        website.
      </p>
      <p className="p2">&nbsp;</p>
      <p className="p1">4. Product Order process and delivery</p>
      <p className="p1">
        4.1. Before ordering a Product, each customer must create an account. The customer account
        will be created by completing the registration process and providing EcuFileTr with current, complete and
        accurate information as requested in the application registration form. Providing incorrect information
        may result in EcuFileTr being unable to provide support on a particular project.
      </p>
      <p className="p1">
        4.2. Once the customer account is created, the customer can purchase Products available in
        the web store.
      </p>
      <p className="p1">4.3. The purchase process is completed by completing the payment step.</p>
      <p className="p1">4.4. The payment made at the time of purchase is for the labor and time spent.</p>
      <p className="p1">
        4.5. Since the labor and time purchased do not have any material value, refunds cannot be
        made or offered.
      </p>
      <p className="p1">
        4.6. The purchased service cannot be canceled and is not refundable. Any request for a
        refund will result in immediate removal from the website, services and Products.
      </p>
      <p className="p2">&nbsp;</p>
      <p className="p1">5. Invoice and payment</p>
      <p className="p1">
        5.1. The invoice will be delivered to the customer after confirmation of the order and will
        only be sent through the system.
      </p>
      <p className="p1">
        Customers are obliged to pay for the Products using their credit card's online payment
        provider, unless expressly agreed otherwise with EcuFileTr.
      </p>
      <p className="p2">&nbsp;</p>
      <p className="p1">
        5.2. EcuFileTr may offer other payment methods in the future. If EcuFileTr decides to use
        other payment methods, it will be announced through this website.
      </p>
      <p className="p1">
        5.3. To ensure the security of online payments made by the customer, all transactions are
        encrypted with SSL technology.
      </p>
      <p className="p2">&nbsp;</p>
      <p className="p1">6. Delivery of Products</p>
      <p className="p1">
        6.1. Downloading a Product requires (i) providing EcuFileTr with complete and accurate
        information about the vehicle to be tuned as requested by the relevant form and (ii) uploading the file
        containing information read from the engine control unit of the relevant vehicle.
      </p>
      <p className="p1">
        6.2. After the collection and analysis of the information submitted in accordance with
        clause 6.1, the relevant tuning software/settings file will be available for the customer to download.
      </p>
      <p className="p1">
        6.3. The warranty given to the products will be subject to the customer providing accurate
        and complete information about the vehicle to be adjusted. In case of any false, inaccurate or incomplete
        information, EcuFileTr disclaims all warranties and conditions relating to the Products, including any
        warranties of suitability for tuning a particular vehicle.
      </p>
      <p className="p1">
        6.4. The customer is entirely responsible for the correct installation of the tuning
        software/settings file on the vehicle and EcuFileTr accepts no liability if the Installation cannot be
        carried out for any reason other than a defect in the Product itself.
      </p>
      <p className="p1">
        6.5. Once downloaded, the customer cannot return any tuning software/settings files and/or
        request a refund of the Product price. This is without prejudice to the provisions of clause 8 regarding
        warranty.
      </p>
      <p className="p2">&nbsp;</p>
      <p className="p1">7. Control and acceptance</p>
      <p className="p1">
        7.1. Customer may be excluded if the Products provided by EcuFileTr do not comply with
        applicable industry standards or practices or do not comply with the specific order and/or 6.1. It is not
        suitable for the adjustment of the vehicle specified on the delivery note sent to EcuFileTr in accordance
        with the article.
      </p>
      <p className="p1">
        7.2. Customers or, where relevant, the person to whom the delivery is made must inspect the
        Products upon delivery. If products are claimed to be damaged or defective on delivery or do not conform
        to order, a description of the alleged damage, defect, non-conformity and/or any other complaint must be
        made in writing within three days. The day starts from the delivery of the products.
      </p>
      <p className="p1">
        7.3. If the written complaint does not reach EcuFileTr within three days after the products
        are received from the customer, the products are deemed to have been accepted by the customer.
      </p>
      <p className="p2">&nbsp;</p>
      <p className="p1">8. Liability and Limitation of Liability</p>
      <p className="p1">
        8.1. EcuFileTr can only be held liable in case of personal injuries or gross negligence
        and/or willful misconduct.
      </p>
      <p className="p1">
        8.2. Customer acknowledges and accepts the full cost of damages resulting from information
        contained in or collected by the software.
      </p>
      <p className="p1">
        8.3. The customer acknowledges and agrees that EcuFileTr cannot be held liable for any
        indirect, malfunction, consequential or similar damages or losses suffered by the customer, such as
        financial and commercial damage, loss of profits, loss of sales, increased costs and overheads. Any loss
        of client or image that may arise from the use or inability to use the products, report or disruption in
        the planning of the client's activity, loss of data, files or any computer programs, any claim by a third
        party against the Client is considered as consequential damage and therefore does not give rise to any
        compensation.
      </p>
      <p className="p2">&nbsp;</p>
      <p className="p1">
        8.4. Without prejudice to the mandatory legal provisions, EcuFileTr's liability will always
        be limited to the price of the relevant software purchased from the server. In no case can EcuFileTr be
        held liable for any damage caused by the owner of a vehicle adjusted by the customer.
      </p>
      <p className="p2">&nbsp;</p>
      <p className="p1">8.5. Additionally, EcuFileTr cannot be held responsible for:</p>
      <p className="p1">
        Damages caused by incompetent use of the products or use for a purpose that does not comply
        with objective standards;
      </p>
      <p className="p1">
        Damages resulting from materials or services provided by third parties without the prior
        written consent of EcuFileTr;
      </p>
      <p className="p1">
        Any (indirect) direct damage and/or costs, including but not limited to, resulting from
        improper actions, carelessness, negligence, abuse and/or improper use and/or maintenance of the products
        by the customer;
      </p>
      <p className="p1">Normal wear and tear of products;</p>
      <p className="p1">
        Any claims from the client's customers as a result of failure to comply with the
        pre-contractual information obligation for which the client is responsible;
      </p>
      <p className="p1">Damages caused by the Force Majeure event defined in Article 9.</p>
      <p className="p2">&nbsp;</p>
      <p className="p1">
        8.6. The Customer indemnifies EcuFileTr against all liabilities, costs, expenses, damages
        and losses suffered or incurred as a result of or in connection with any claim made by any third party
        against any of them. the supply of Products to the extent that they are attributable to the acts or
        omissions of any of them or any of their employees, agents or sub-contractors.
      </p>
      <p className="p1">9. Force majeure</p>
      <p className="p1">
        EcuFileTr has no liability to customers for any failure or delay in performance of any
        obligation to the extent that performance is prevented due to Force Majeure. For the purposes of this
        contract, 'Force Majeure' means: any situation that EcuFileTr cannot take into account at the time of
        closing of the contract and therefore the normal execution of the contract cannot reasonably be expected
        from EcuFileTr, war, threat of war, regardless of whether Turkey directly or indirectly such as full or
        partial mobilization, state of emergency, riot, sabotage, earthquake, flood, fire or other destruction and
        damage in factories or warehouses, dismissals, company lockouts, worker strikes. , breakage of machines or
        tools or other malfunctions at EcuFileTr or in companies where EcuFileTr uses products, goods or other
        devices or where EcuFileTr serves, public authority restrictions or public measures of any kind,
        liabilities arising from adverse weather conditions, shortage of means of transportation, products ,
        restriction or obstruction of the production and/or supply of goods or auxiliaries, fuels and/or energy
        shortages.
      </p>
      <p className="p1">10. Intellectual property rights</p>
      <p className="p1">
        10.1. Products, services, content and all parts of the EcuFileTr website (including all
        trademarks, logos, designs, drawings, data, product and/or company names, texts, images, software, etc.)
        are protected by intellectual property rights (i.e. copyright and trademark trademark law) belongs to
        EcuFileTr or third parties authorized by EcuFileTr. The Customer has no right to modify, copy, distribute,
        post, resubmit, reproduce, publish, transfer under license, transfer or sell any information or content to
        create derivative works from the previously mentioned elements, for any reason. The provision of products
        or services by EcuFileTr does not constitute any transfer or provision of intellectual property rights. It
        is prohibited to establish an automatic connection between the EcuFileTr website and another website for
        any reason or to establish any automatic link back to the EcuFileTr website without the permission of
        EcuFileTr.
      </p>
      <p className="p2">&nbsp;</p>
      <p className="p1">
        10.2. In particular, the software offered by EcuFileTr will remain the exclusive property of
        EcuFileTr and the use of the software will be subject to the conditions set out in the License Agreement,
        which is an integral part of these terms and conditions. on the period
      </p>
      <p className="p1">
        Upon use of the software, the customer will destroy all software applications along with any
        associated documentation and manuals. The customer undertakes to keep the software confidential.
      </p>
      <p className="p1">
        10.3. Violation of EcuFileTr's intellectual property rights may lead to civil and criminal
        prosecution in accordance with applicable Turkish legislation.
      </p>
      <p className="p2">&nbsp;</p>
      <p className="p2">&nbsp;</p>
      <p className="p2">&nbsp;</p>
      <p className="p1">11. Obligation to inform</p>
      <p className="p1">
        11.1. It is the responsibility of the owner or holder of the vehicle to ensure that the
        vehicle complies with applicable road legislation at any time and is permitted to be driven on public
        roads.
      </p>
      <p className="p1">
        11.2. As a professional dealer, the customer is obliged to properly inform his customers of
        his legal obligations when adjusting his vehicle. By accepting these general terms and conditions, the
        customer undertakes to comply with the pre-contractual information obligation towards its customers.
      </p>
      <p className="p2">&nbsp;</p>
      <p className="p1">
        11.3. EcuFileTr makes no representations or warranties and makes no commitments regarding
        the approval of a tuned vehicle and/or the authorization of any end customer (the owner of a tuned
        vehicle) to drive on public roads.
      </p>
      <p className="p2">&nbsp;</p>
      <p className="p1">12. Privacy</p>
      <p className="p1">
        12.1 Each Party may become aware of the other Party's confidential information
        (&ldquo;Confidential Information&rdquo;). Confidential Information means any information obtained,
        exchanged, communicated orally or in writing by one Party to the other Party, directly or indirectly, in
        connection with the performance of the contract subject to these terms and conditions. The nature of the
        medium of this information, without the need for the term 'confidential' or any other similar term to be
        expressly stated in writing. This Agreement is also considered confidential.
      </p>
      <p className="p1">
        12.2. Confidential Information of a Party does not include information that: (i) is publicly
        available and is not attributable to the other Party; (ii) that the other Party legitimately knew prior to
        its disclosure, without directly or indirectly receiving information from the disclosing Party; (iii) are
        lawfully disclosed to the other Party by a third party that is not subject to a duty of confidentiality;
        or (iv) independently developed by the other Party without use of or reference to the other Party's
        Confidential Information.
      </p>
      <p className="p1">
        12.3. Furthermore, this clause shall not be construed to prohibit the disclosure of
        Confidential Information to the extent that disclosure is required by law or regulation or an enforceable
        judicial decision, provided that the Party obliged to disclose the Confidential Information has previously
        notified it. the other Party and shall use reasonable efforts to take a protective measure that prohibits
        the use of Confidential Information so disclosed for purposes other than those required by judicial or
        administrative decision.
      </p>
      <p className="p1">
        12.4. Each party is bound by the strictest confidentiality with respect to Confidential
        Information collected by it in connection with the performance of a contract subject to these terms and
        conditions. Accordingly, unless legally required, the Parties agree (i) not to disclose the other Party's
        Confidential Information to third parties in any way, (ii) to limit access to Confidential Information to
        those employees who need it to perform their professional duties. or contractual obligations, by accepting
        in writing an obligation of confidentiality that provides for a level of protection and restrictions
        substantially similar to those provided for in these terms and conditions, and (iii) not to use them for
        purposes other than the execution of this Agreement.
      </p>
      <p className="p1">
        12.5 Each Party undertakes to take all reasonable steps to prevent the disclosure or
        distribution of Confidential Information by its employees or representatives in breach of these terms and
        conditions. In all cases, each Party agrees to protect Confidential Information with at least the same
        degree of care as it applies to the protection of its own confidential information.
      </p>
      <p className="p1">
        12.6 Each party undertakes to implement these provisions by its personnel and any
        representatives or third parties who may take part in the performance of the contract in any capacity,
        subject to the terms and conditions.
      </p>
      <p className="p1">
        12.7 Each Party acknowledges and agrees that, given the very private nature of Confidential
        Information, damages for breach of this clause may not be sufficient to cause irreparable harm to the
        Party to which it belongs. The latter shall therefore be entitled to seek immediate injunctive relief
        without waiving any other remedies provided by law and/or these terms and conditions.
      </p>
      <p className="p1">
        12.8. This obligation of confidentiality shall remain in force for the duration of any
        contract subject to these terms and conditions and for five (5) years after the termination or termination
        of the contractual relationship for any reason.
      </p>
      <p className="p2">&nbsp;</p>
      <p className="p1">13. Relationship of parties</p>
      <p className="p1">
        13.1. Nothing in these terms and conditions shall be construed as creating or implying a
        partnership or joint venture between the parties and nothing in these terms and conditions shall be
        construed as representing either party as an agent of the other.
      </p>
      <p className="p2">&nbsp;</p>
      <p className="p1">14. Disclaimer</p>
      <p className="p1">
        14.1. EcuFileTr's failure at any time or for any period to enforce any one or more of these
        terms and conditions will not constitute a waiver of them or the right to enforce all terms and conditions
        at any later time.
      </p>
      <p className="p2">&nbsp;</p>
      <p className="p1">15. Divisibility</p>
      <p className="p1">
        15.1. If any term or provision of these terms and conditions is deemed invalid, illegal or
        unenforceable for any reason by any court or tribunal of competent jurisdiction, such provision shall be
        severed and the remainder of the provisions herein shall continue in full force and effect.
      </p>
      <p className="p2">&nbsp;</p>
      <p className="p1">16. Applicable law and jurisdiction</p>
      <p className="p1">
        16.1. This agreement will be governed by and interpreted in accordance with the laws of
        T&uuml;rkiye.
      </p>
      <p className="p1">
        16.2. Any dispute arising from or relating to these general terms and conditions shall be
        submitted exclusively to the competent courts in Istanbul, Turkey, unless an amicable solution can be
        reached.
      </p>
    </div>
  );
}

export default Agreement;
