import React, { useEffect, useState } from 'react';
import BaseView from '../../views/baseView/baseView';
import styles from './invoices.module.css';
import icDownload from '../../../assets/images/download-blue.svg';
import icClock from '../../../assets/images/clock-blue.svg';
import { SimpleInput } from '../../views/inputs/inputs';
import search from '../../../assets/images/search.svg';
import Pagination from '../../views/pagination/pagination';
import { decodeString, priceFormat } from '../../../utils/formatter';
import { ServiceRequest } from '../../../helpers/connectionManager';
import { apiEndpoints } from '../../../helpers/constant';
import { localStr } from '../../../helpers/language';
import { currencyText } from '../../../core/config';

function Invoices() {
  const [searchTerm, setSearchTerm] = useState('');
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedPage, setSelectedPage] = useState(1);
  const pageItemCount = 50;
  function downloadInvoice(id) {
    ServiceRequest({
      endpoint: `${apiEndpoints.customer.downloadInvoice}/${id}`,
      loading: true,
      downloadFile: 'invoice',
    })
      .then((res) => {})
      .catch((error) => { });
  }
  useEffect(() => {
    getFiles();
  }, []);
  function getFiles() {
    ServiceRequest({
      endpoint: apiEndpoints.customer.getFiles,
      loading: true,
    })
      .then((res) => {
        setData(res.data);
        setFilteredData(res.data);
      })
      .catch((error) => { });
  }
  useEffect(() => {
    setSelectedPage(1);
    if (searchTerm.length < 3) {
      setFilteredData(data);
    } else {
      let allData = [...data];
      if (searchTerm.length >= 3) {
        allData = allData.filter((e) => String(e.orderId).toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
            || String(`${e?.brandName} / ${e?.modelName} / ${e?.generationName} / ${e?.engineName}`).toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
            || String(e.plate).toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1);
      }

      setFilteredData(allData);
    }
  }, [searchTerm]);
  return (
    <BaseView path="/ Invoices">
      <div className={styles.main}>
        <div className={styles.TitleCont}>
          <div className="d-flex f-column f1">
            <div className={styles.t1}>{localStr()?.invoices?.t1}</div>
            <div className={styles.t2}>{localStr()?.invoices?.t2}</div>
          </div>
        </div>
        <SimpleInput
          formStyle={styles.search}
          onChange={(e) => setSearchTerm(e)}
          value={searchTerm}
          leftIcon={search}
          placeHolder={localStr()?.invoices?.t3}
        />
        <table className={styles.userTable}>
          <thead>
            <tr>
              <th className="text-center w-90">{localStr()?.invoices?.t4}</th>
              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
              <th className="w-80">{localStr()?.invoices?.t5}</th>
              <th className="w-100">{localStr()?.invoices?.t6}</th>
              <th>{localStr()?.invoices?.t7}</th>
              <th className="text-center w-90">{localStr()?.invoices?.t8}</th>
              <th className="text-center w-110">{localStr()?.invoices?.t9}</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.length === 0 && (
            <tr>
              <td colSpan={7} className={styles.tableEmpty}>
                {localStr()?.invoices?.t10}
              </td>
            </tr>
            )}
            {filteredData.slice((selectedPage - 1) * pageItemCount, selectedPage * pageItemCount).map((item, index) => (
              <tr key={index} className={index % 2 === 1 ? styles.dark : ''}>
                <td className="text-center w-90">{item?.orderId}</td>
                <td className="w-80">{new Date(item?.createdDate).toLocaleDateString()}</td>
                <td className={`${styles.tPrice} w-100`}>
                  {priceFormat(item?.amount)}
                  {' '}
                  {currencyText}
                </td>
                <td>{decodeString(`${item?.brandName} / ${item?.modelName} / ${item?.generationName} / ${item?.engineName}`)}</td>
                <td className="w-90 text-center">
                  {item?.plate}
                </td>
                <td className={`${styles.tPdf} w-110`}>
                  {item?.invoice ? (
                    <div className="d-flex align-center justify-center c-pointer" onClick={() => downloadInvoice(item.id)}>
                      <img alt="pdf" src={icDownload} className="m-r-4" />
                      PDF
                    </div>
                  ) : (
                    <div className="d-flex align-center justify-center">
                      <img alt="pdf" src={icClock} className="m-r-4" />
                      {localStr()?.invoices?.t11}
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {filteredData.length !== 0 && (
        <div className="d-flex m-t-24 align-center">
          <Pagination
            formStyle={styles.pagination}
            selectedPage={selectedPage}
            totalPage={parseInt(filteredData.length / pageItemCount, 10) + 1}
            onChange={(e) => setSelectedPage(e)}
          />
          {searchTerm === '' && (
          <div className={styles.t3}>
            {localStr()?.invoices?.t12}
            {' '}
            {(selectedPage - 1) * pageItemCount}
            {' '}
            -
            {(parseInt(filteredData.length / pageItemCount, 10) + 1) === selectedPage ? filteredData.length : selectedPage * pageItemCount}
            {' '}
            {localStr()?.invoices?.t13}
            {' '}
            {filteredData.length}
          </div>
          )}
        </div>
        )}
      </div>
    </BaseView>
  );
}

export default Invoices;
