export const LOADING_TRUE = 'loading_true';
export const LOADING_FALSE = 'loading_false';
export const LOGIN_TRUE = 'login_true';
export const LOGIN_FALSE = 'login_false';
export const SET_MERCHANT_NAME = 'set_merchant_name';
export const ERROR_MESSAGE_ON = 'error_message_on';
export const ERROR_MESSAGE_OFF = 'error_message_off';
export const INFO_MESSAGE_ON = 'info_message_on';
export const INFO_MESSAGE_OFF = 'info_message_off';
export const SUCCESS_MESSAGE_ON = 'success_message_on';
export const SUCCESS_MESSAGE_OFF = 'success_message_off';
export const QUESTION_MESSAGE_ON = 'question_message_on';
export const QUESTION_MESSAGE_OFF = 'question_message_off';
export const ERROR_TOAST_ON = 'error_toast_on';
export const ERROR_TOAST_OFF = 'error_toast_off';
export const INFO_TOAST_ON = 'info_toast_on';
export const INFO_TOAST_OFF = 'info_toast_off';
export const QUESTION_TOAST_ON = 'question_toast_on';
export const QUESTION_TOAST_OFF = 'question_toast_off';
export const SUCCESS_TOAST_ON = 'success_toast_on';
export const SUCCESS_TOAST_OFF = 'success_toast_off';
