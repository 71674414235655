import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './files.module.css';
import BaseView from '../../views/baseView/baseView';
import search from '../../../assets/images/search.svg';
import eye from '../../../assets/images/eye.svg';
import { SimpleInput } from '../../views/inputs/inputs';
import Pagination from '../../views/pagination/pagination';
import Status from '../../views/status/status';
import { TableSorting } from '../../views/tableComponents/tableComponents';
import icClockBlue from '../../../assets/images/clock-blue.svg';
import icDownload from '../../../assets/images/download-blue.svg';
import { ServiceRequest } from '../../../helpers/connectionManager';
import { apiEndpoints, orderStatusEnums, orderStatusList } from '../../../helpers/constant';
import { decodeString } from '../../../utils/formatter';
import { localStr } from '../../../helpers/language';

function Files() {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedPage, setSelectedPage] = useState(1);
  const pageItemCount = 50;
  const [statusSelected, setStatusSelected] = useState({
    key: 'all',
    value: localStr()?.files?.t1,
  });
  useEffect(() => {
    getFiles();
  }, []);
  function getFiles() {
    ServiceRequest({
      endpoint: apiEndpoints.customer.getFiles,
      loading: true,
    })
      .then((res) => {
        setData(res.data);
        setFilteredData(res.data);
      })
      .catch((error) => { });
  }
  useEffect(() => {
    setSelectedPage(1);
    if (searchTerm.length < 3 && statusSelected.key === 'all') {
      setFilteredData(data);
    } else {
      let allData = [...data];
      if (searchTerm.length >= 3) {
        allData = allData.filter((e) => String(e.orderId).toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
          || String(`${e?.brandName} / ${e?.modelName} / ${e?.generationName} / ${e?.engineName}`).toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
          || String(e.plate).toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1);
      }
      if (statusSelected.key !== 'all') {
        allData = allData.filter((e) => e.orderStatus === statusSelected.key);
      }
      setFilteredData(allData);
    }
  }, [searchTerm, statusSelected]);
  function downloadInvoice(id) {
    ServiceRequest({
      endpoint: `${apiEndpoints.customer.downloadInvoice}/${id}`,
      loading: true,
      downloadFile: 'invoice',
    })
      .then((res) => {})
      .catch((error) => { });
  }
  return (
    <BaseView path="/ Files">
      <div className={styles.main}>
        <div className={styles.TitleCont}>
          <div className="d-flex f-column f1">
            <div className={styles.t1}>{localStr()?.files?.t2}</div>
            <div className={styles.t2}>{localStr()?.files?.t3}</div>
          </div>
        </div>
        <SimpleInput
          formStyle={styles.search}
          onChange={(e) => setSearchTerm(e)}
          value={searchTerm}
          leftIcon={search}
          placeHolder={localStr()?.files?.t4}
        />
        <table className={styles.userTable}>
          <thead>
            <tr>
              <th className="text-center w-90">{localStr()?.files?.t5}</th>
              <th className="w-80">
                {localStr()?.files?.t6}
              </th>
              <th>{localStr()?.files?.t7}</th>
              <th className="text-center w-90">{localStr()?.files?.t8}</th>
              <th className="text-center w-80">{localStr()?.files?.t9}</th>
              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
              <th className="text-center w-110">
                <TableSorting
                  title={`${localStr()?.files?.t10} (${statusSelected.value})`}
                  items={orderStatusList()}
                  selected={statusSelected}
                  onClick={(e) => setStatusSelected(e)}
                />
              </th>
              <th className="text-center w-50">{localStr()?.files?.t11}</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.length === 0 && (
            <tr>
              <td colSpan={7} className={styles.tableEmpty}>
                {localStr()?.files?.t12}
              </td>
            </tr>
            )}
            {filteredData.slice((selectedPage - 1) * pageItemCount, selectedPage * pageItemCount).map((item, index) => (
              <tr key={index} className={index % 2 === 1 ? styles.dark : ''}>
                <td className="text-center w-90 c-text">{item?.orderId}</td>
                <td className="w-80">{new Date(item?.createdDate).toLocaleDateString()}</td>
                <td>{decodeString(`${item?.brandName} / ${item?.modelName} / ${item?.generationName} / ${item?.engineName}`)}</td>
                <td className="text-center w-90 c-text">{item?.plate}</td>
                <td className="text-center w-80">
                  {item?.invoice ? (
                    <div className={`${styles.t41} c-pointer`} onClick={() => downloadInvoice(item.id)}>
                      <img alt="pdf" src={icDownload} className="m-r-4" />
                      PDF
                    </div>
                  ) : (
                    <div className={styles.t41}>
                      <img alt="pdf" src={icClockBlue} className="m-r-4" />
                      {localStr()?.files?.t15}
                    </div>
                  )}
                </td>
                {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                <td className="w-110">
                  <div className="d-flex f-column w100 align-center">
                    <Status type={orderStatusEnums[item.orderStatus]} />
                  </div>
                </td>
                <td className="text-center c-pointer w-50">
                  <div
                    onClick={() => navigate('/filedetail', { state: { file: item } })}
                  >
                    <img alt="show" src={eye} />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {filteredData.length !== 0 && (
        <div className="d-flex m-t-24 align-center">
          <Pagination
            formStyle={styles.pagination}
            selectedPage={selectedPage}
            totalPage={parseInt(filteredData.length / pageItemCount, 10) + 1}
            onChange={(e) => setSelectedPage(e)}
          />
          {searchTerm === '' && statusSelected.key === 'all' && (
          <div className={styles.t3}>
            {localStr()?.files?.t13}
            {' '}
            {(selectedPage - 1) * pageItemCount}
            {' '}
            -
            {(parseInt(filteredData.length / pageItemCount, 10) + 1) === selectedPage ? filteredData.length : selectedPage * pageItemCount}
            {' '}
            {localStr()?.files?.t14}
            {' '}
            {filteredData.length}
          </div>
          )}
        </div>
        )}
      </div>
    </BaseView>
  );
}

export default Files;
