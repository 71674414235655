import {
  ERROR_MESSAGE_ON,
  ERROR_MESSAGE_OFF,
  INFO_MESSAGE_ON,
  INFO_MESSAGE_OFF,
  SUCCESS_MESSAGE_OFF,
  SUCCESS_MESSAGE_ON,
  QUESTION_MESSAGE_OFF,
  QUESTION_MESSAGE_ON,
} from '../types';
import { debugModeOn } from '../../core/config';
import popUpState from '../initialStates/popUpState';

export default (state = popUpState, action) => {
  if (debugModeOn) {
    console.group('ContextReducer: ', state, action);
    console.groupEnd();
  }
  switch (action.type) {
    case ERROR_MESSAGE_ON:
      return {
        ...state,
        errorModalStatus: true,
        infoModalStatus: false,
        successModalStatus: false,
        questionModalStatus: false,
        title: action.title,
        text: action.text,
        buttons: action.buttons,
      };
    case INFO_MESSAGE_ON:
      return {
        ...state,
        errorModalStatus: false,
        infoModalStatus: true,
        successModalStatus: false,
        questionModalStatus: false,
        title: action.title,
        text: action.text,
        buttons: action.buttons,
      };
    case SUCCESS_MESSAGE_ON:
      return {
        ...state,
        errorModalStatus: false,
        infoModalStatus: false,
        successModalStatus: true,
        questionModalStatus: false,
        title: action.title,
        text: action.text,
        buttons: action.buttons,
      };
    case QUESTION_MESSAGE_ON:
      return {
        ...state,
        errorModalStatus: false,
        infoModalStatus: false,
        successModalStatus: false,
        questionModalStatus: true,
        title: action.title,
        text: action.text,
        buttons: action.buttons,
      };
    case ERROR_MESSAGE_OFF:
    case SUCCESS_MESSAGE_OFF:
    case QUESTION_MESSAGE_OFF:
    case INFO_MESSAGE_OFF:
      return {
        ...state,
        errorModalStatus: false,
        infoModalStatus: false,
        questionModalStatus: false,
        successModalStatus: false,
        title: '',
        text: '',
        buttons: [],
      };
    default:
      return state;
  }
};
