import React from 'react';
import styles from './breadCrumb.module.css';
import home2 from '../../../assets/images/menu/house-2.svg';

function BreadCrumb({ path }) {
  return (
    <div className={styles.breadCrumb}>
      <img alt="icon" src={home2} />
      <div className={styles.path}>{path}</div>
    </div>
  );
}
export default BreadCrumb;
