import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './header.module.css';
import clock from '../../../assets/images/clock.svg';
import person from '../../../assets/images/defaultProfileImage.png';
import arrow from '../../../assets/images/right-arrow.svg';
import SideMenu from '../sideMenu/sideMenu';
import { getSessionValue } from '../../../helpers/sessionManager';
import { ServiceRequest } from '../../../helpers/connectionManager';
import {
  apiEndpoints,
  storageKeys,
  weekDay,
  workingDays,
  workingDaysClose,
  workingDaysOpen,
} from '../../../helpers/constant';
import { getTimeFromDateObject, getTimeObject } from '../../../utils/formatter';
import { MainContext } from '../../../context/store';
import { showQuestion } from '../../../context/actions/popUpActions';
import { localStr } from '../../../helpers/language';

function Header() {
  const { state, dispatch } = useContext(MainContext);
  const [userData, setUserData] = useState(null);
  const [sideOpen, setSideOpen] = useState(false);
  const [merchantLogo, setMerchantLogo] = useState(null);
  const [workOn, setWorkOn] = useState(null);
  const [workingHours, setWorkingHours] = useState(null);
  const navigate = useNavigate();
  let interval;
  useEffect(() => {
    getCredentials();
    checkWorkingHours();
    fetchWorkingHours();
    getMessages();
    checkProfile();
    return () => {
      clearInterval(interval);
    };
  }, []);
  function checkProfile() {
    const user = sessionStorage.getItem(storageKeys.user);
    if (user) {
      setUserData(JSON.parse(user));
    } else {
      ServiceRequest({
        endpoint: apiEndpoints.customer.getCustomer,
        loading: false,
      })
        .then((res) => {
          sessionStorage.setItem(storageKeys.user, JSON.stringify(res?.data));
          setUserData(res?.data);
        })
        .catch(() => {});
    }
  }
  function getCredentials() {
    const { logoWhite } = getSessionValue({ key: storageKeys.credentials }) || { logoWhite: null };
    setMerchantLogo(logoWhite);
  }
  function checkWorkingHours() {
    interval = setInterval(() => {
      fetchWorkingHours();
      getMessages();
    }, 60000);
  }
  function getMessages() {
    ServiceRequest({
      endpoint: apiEndpoints.customer.getMessages,
      loading: false,
    })
      .then((res) => {
        if (res?.data) {
          dispatch.popUpDispatch(
            showQuestion({
              title: localStr()?.header?.t1,
              text: res.data.messageText,
              buttons: [
                {
                  buttonTitle: localStr()?.header?.t2,
                  buttonClick: () => {
                    navigate('/filedetail', { state: { file: { id: res.data.fileId } } });
                  },
                },
                {
                  buttonTitle: localStr()?.header?.t3,
                  buttonClick: () => {},
                },
              ],
            }),
          );
        }
      })
      .catch(() => {});
  }
  function fetchWorkingHours() {
    ServiceRequest({
      endpoint: apiEndpoints.customer.getWorkingHours,
      loading: false,
    })
      .then((res) => {
        const d = new Date();
        const day = d.getDay();
        const isOpen = res?.data[workingDays[day]];
        setWorkOn(isOpen);
        setWorkingHours(res.data);
      })
      .catch(() => {});
  }
  function getOpenCloseTime() {
    if (!workingHours) {
      return <div />;
    }
    let openCloseDay = '';
    let openCloseTime = '';
    const allHoursData = { ...workingHours };
    const now = new Date();
    now.setTime(now.getTime() + (now.getTimezoneOffset() - workingHours.timeZone) * 60000);
    const day = now.getDay();
    const doubleDaysWorking = [...workingDays, ...workingDays];
    const doubleDaysOpen = [...workingDaysOpen, ...workingDaysOpen];
    const doubleDaysClose = [...workingDaysClose, ...workingDaysClose];
    const doubleWeekDays = [...weekDay(), ...weekDay()];
    for (let i = day; i < day + 7; i += 1) {
      const nextTime = getTimeObject(allHoursData[workOn ? doubleDaysClose[i] : doubleDaysOpen[i]]);
      if ((i === day) && nextTime > now) {
        openCloseDay = String(localStr()?.header?.t4);
        openCloseTime = getTimeFromDateObject(String(nextTime));
        break;
      } else if ((i !== day) && workOn) {
        openCloseDay = String(doubleWeekDays[i]);
        openCloseTime = '00:00';
        break;
      } else if ((i !== day) && allHoursData[doubleDaysWorking[i]]) {
        openCloseDay = String(doubleWeekDays[i]);
        openCloseTime = getTimeFromDateObject(String(nextTime));
        break;
      }
    }
    const merchantTimeZone = -1 * Math.round(workingHours.timeZone / 60);
    return (
      <>
        <div className={styles.c4}>
          {openCloseDay}
          {' '}
          {openCloseTime}
          {' '}
          - GTM
          {' '}
          {merchantTimeZone < 0 ? merchantTimeZone : `+${merchantTimeZone}`}
        </div>
        <div className={styles.c5}>
          <img alt="clock" src={clock} className="m-r-4" />
          {getTimeFromDateObject(now)}
        </div>
      </>
    );
  }

  return (
    <div className="d-flex f-column">
      <SideMenu
        userData={userData}
        show={sideOpen}
        toggleMenu={(e) => {
          setSideOpen(e);
        }}
        updateApp={() => checkProfile()}
      />
      <div className={styles.header}>
        <div className={styles.logoCont} onClick={() => navigate('/')}>
          {merchantLogo && <img alt="ecuFile" src={`data:image/png;base64,${merchantLogo}`} className={styles.logo} />}
        </div>
        <div className={styles.headerCont}>
          <div className="d-flex align-center f1">
            <div className={styles.c1}>
              {localStr()?.header?.t5}
            </div>
            {workOn !== null && (
            <div className={`${styles.c2} ${workOn && styles.on}`}>
              {workOn ? localStr()?.header?.t6 : localStr()?.header?.t7}
            </div>
            )}
            <div className={styles.c3}>{localStr()?.header?.t9}</div>
            {getOpenCloseTime()}
          </div>
          <div className="d-flex align-center c-pointer" onClick={() => setSideOpen(!sideOpen)}>
            <img
              alt="profile"
              src={userData?.logo ? `data:image/png;base64,${userData?.logo}` : person}
              className={styles.profileImage}
            />
            <div className="m-l-12">
              <div className={styles.t1}>{localStr()?.header?.t8}</div>
              <div className={styles.t2}>
                {userData?.fullName}
                <img alt="menu" src={arrow} className="m-l-8" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
