import React from 'react';
import { createBrowserRouter, Navigate } from 'react-router-dom';
import Main from '../components/pages/main/main';
import Files from '../components/pages/files/files';
import FileDetail from '../components/pages/fileDetail/fileDetail';
import Upload from '../components/pages/upload/upload';
import Pricing from '../components/pages/pricing/pricing';
import VehicleData from '../components/pages/vehicleData/vehicleData';
import Invoices from '../components/pages/invoices/invoices';
import DtcSearch from '../components/pages/dtcSearch/dtcSearch';
import BoschSearch from '../components/pages/boschSearch/boschSearch';
import Login from '../components/pages/login/login';
import Register from '../components/pages/register/register';
import RegisterSubmit from '../components/pages/register/registerSubmit';
import ForgotPassword from '../components/pages/forgotPassword/forgotPassword';
import CheckMail from '../components/pages/forgotPassword/checkMail';
import ResetPassword from '../components/pages/resetPassword/resetPassword';
import PasswordSaved from '../components/pages/resetPassword/passwordSaved';
import TestPayment from '../components/pages/payment/testPaymentPage';
import { storageKeys } from '../helpers/constant';
import PaymentSuccess from '../components/pages/payment/paymentSuccess';
import PaymentFail from '../components/pages/payment/paymentFail';
import Agreement from '../components/pages/agreement/agreement';

const routes = createBrowserRouter([
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/register',
    element: <Register />,
  },
  {
    path: '/registersubmit',
    element: <RegisterSubmit />,
  },
  {
    path: '/forgotpassword',
    element: <ForgotPassword />,
  },
  {
    path: '/checkmail',
    element: <CheckMail />,
  },
  {
    path: '/resetpassword/:id',
    element: <ResetPassword />,
  },
  {
    path: '/passwordsaved',
    element: <PasswordSaved />,
  },
  {
    path: '/agreement',
    element: <Agreement />,
  },
  {
    path: '/',
    element: <Main />,
    loader,
  },
  {
    path: '/files',
    element: <Files />,
    loader,
  },
  {
    path: '/filedetail',
    element: <FileDetail />,
    loader,
  },
  {
    path: '/upload',
    element: <Upload />,
    loader,
  },
  {
    path: '/pricing',
    element: <Pricing />,
    loader,
  },
  {
    path: '/vehicle',
    element: <VehicleData />,
    loader,
  },
  {
    path: '/invoices',
    element: <Invoices />,
    loader,
  },
  {
    path: '/dtcsearch',
    element: <DtcSearch />,
    loader,
  },
  {
    path: '/bsearch',
    element: <BoschSearch />,
    loader,
  },
  {
    path: '/testPaymentPage/:orderId',
    element: <TestPayment />,
  },
  {
    path: '/paymentSuccess/:orderId',
    element: <PaymentSuccess />,
  },
  {
    path: '/paymentFail/:orderId',
    element: <PaymentFail />,
  },
  {
    path: '*',
    element: <Navigate to="/" replace />,
  },
]);
async function loader() {
  const token = localStorage.getItem(storageKeys.token);
  if (!token) {
    window.location.href = '/login';
  }
  return true;
}
export default routes;
