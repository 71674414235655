import React from 'react';
import { PrimaryButton } from '../../views/buttons/buttons';
import styles from './testPaymentPage.module.css';

function TestPaymentPage() {
  return (
    <div className={styles.main}>
      <PrimaryButton
        buttonTitle="Accept"
        buttonClick={() => {
          window.location.href = '/paymentSuccess';
        }}
      />
      <PrimaryButton
        buttonTitle="Reject"
        buttonClick={() => {
          window.location.href = '/paymentFail';
        }}
      />
    </div>
  );
}

export default TestPaymentPage;
